<template>
  <div>
    <loading-flux :value="loading"></loading-flux>
    <div class="d-flex">
      <div class="card-title col-auto me-auto">
        Solicitud de Materiales
      </div>
    </div>
    <h5 class="card-title-desc subtitulo">
      {{ lugarInstalacion.nombre_proyecto }} - COD
      {{ solicitudMaterial.solicitud_correlativo }}
      {{
        `| Tipo de solicitud: ${
          solicitudMaterial.tipo_documento == REQUEST_MATERIAL
            ? "Normal"
            : "Urgente"
        }`
      }}
    </h5>
    <div class="row">
      <div class="col-12">
        <div class="">
          <b-alert
            :show="segundosMensajeActualizacion"
            dismissible
            :variant="mensaje.variant"
            @dismissed="segundosMensajeActualizacion = 0"
            @dismiss-count-down="actualizarSegundosMensajeActualizacion"
          >
            {{ mensaje.texto }}
          </b-alert>
          <div class="row">
            <div class="row mb-3"></div>

            <div class="col-sm-12 col-md-6">
              <div id="tickets-table_length" class="dataTables_length">
                <label class="d-inline-flex align-items-center">
                  Mostrar&nbsp;
                  <b-form-select
                    class="form-select form-select-sm"
                    v-model="perPage"
                    size="sm"
                    :options="pageOptions"
                  ></b-form-select>
                </label>
              </div>
            </div>
            <!-- Search -->
            <div class="col-sm-12 col-md-6">
              <div
                id="tickets-table_filter"
                class="dataTables_filter text-md-end"
              >
                <label class="d-inline-flex align-items-center">
                  Buscar:
                  <b-form-input
                    v-model="filter"
                    type="search"
                    placeholder="Buscar..."
                    class="form-control form-control-sm ms-2"
                  ></b-form-input>
                </label>
              </div>
            </div>
            <!-- End search -->
          </div>
          <!-- Table -->
          <div class="table-responsive mb-0">
            <b-table
              v-if="renderComponent"
              class="datatables tabla-personalizada"
              :items="solicitudMaterialDetalles"
              :fields="fields"
              responsive="sm"
              :per-page="perPage"
              :current-page="currentPage"
              :sort-by.sync="sortBy"
              :sort-desc.sync="sortDesc"
              :filter="filter"
              :filter-included-fields="filterOn"
              select-mode="single"
              selectable
              @filtered="onFiltered"
              :busy="solicitudMaterialDetallesCargando"
            >
              <template #table-busy>
                <div class="text-center text-danger my-2">
                  <b-spinner class="align-middle"></b-spinner>
                </div>
              </template>
              <template #cell(accion)="data" v-if="rolModuloPermiso.escritura">
                <i
                  v-if="
                    data.item.recepcionado == false &&
                      solicitudMaterial.tipo_bodega == 'Central'
                  "
                  class="bx bx-check-shield text-primary icono-botom-tabla"
                  @click="seleccionarDetalleCantidadRecepcionada(data.item)"
                ></i>

                <i
                  v-if="
                    data.item.recepcionado == true &&
                      solicitudMaterial.tipo_bodega == 'Central'
                  "
                  class="bx bx-stats text-primary icono-botom-tabla"
                  @click="seleccionarDetalleCantidadConsumida(data.item)"
                ></i>
              </template>

              <template #cell(codigo_producto)="data">
                <span class="badge font-size-13 bg-primary">
                  {{ data.item.codigo_producto }}
                </span>
              </template>

              <template #cell(cantidad_recepcionada)="data">
                <b-button
                  v-if="
                    rolModuloPermiso.escritura &&
                      data.item.cantidad_recepcionada == null &&
                      solicitudMaterial.tipo_bodega == 'Central'
                  "
                  variant="outline-primary"
                  class="btn-sm"
                  @click="abrirModalRecepcion(data.item)"
                  >Completar</b-button
                >

                <span v-if="data.item.cantidad_recepcionada != null"
                  >{{ data.item.cantidad_recepcionada }}
                  <i
                    v-if="
                      rolModuloPermiso.escritura &&
                        data.item.cantidad_consumida == null &&
                        solicitudMaterial.tipo_bodega == 'Central'
                    "
                    class="bx bx-edit text-primary pointer font-size-16"
                    @click="abrirModalRecepcion(data.item)"
                  ></i
                ></span>
              </template>
              <template #cell(cantidad_consumida)="data">
                <b-button
                  v-if="
                    rolModuloPermiso.escritura &&
                      data.item.cantidad_recepcionada != null &&
                      data.item.cantidad_consumida == null &&
                      solicitudMaterial.tipo_bodega == 'Central'
                  "
                  variant="outline-primary"
                  class="btn-sm"
                  @click="abrirModalConsumido(data.item)"
                  >Completar</b-button
                >
                <span v-if="data.item.cantidad_consumida != null"
                  >{{ data.item.cantidad_consumida }}
                  <i
                    v-if="
                      rolModuloPermiso.escritura &&
                        data.item.cantidad_devuelta == null &&
                        solicitudMaterial.tipo_bodega == 'Central'
                    "
                    class="bx bx-edit text-primary pointer font-size-16"
                    @click="abrirModalConsumido(data.item)"
                  ></i
                ></span>
              </template>
              <template #cell(cantidad_devuelta)="data">
                <b-button
                  v-if="
                    rolModuloPermiso.escritura &&
                      data.item.cantidad_devuelta == null &&
                      data.item.cantidad_consumida != null
                  "
                  variant="outline-primary"
                  class="btn-sm"
                  @click="abrirModalDevuelto(data.item)"
                  >Completar</b-button
                >
                <span
                  v-if="
                    data.item.cantidad_devuelta != null &&
                      solicitudMaterial.tipo_bodega == 'Central'
                  "
                  >{{ data.item.cantidad_devuelta }}
                  <i
                    v-if="
                      rolModuloPermiso.escritura &&
                        solicitudMaterial.vale_consumo != 1 &&
                        data.item.cantidad_devuelta == null &&
                        solicitudMaterial.devolucion != 1
                    "
                    class="bx bx-edit text-primary pointer font-size-16"
                    @click="abrirModalDevuelto(data.item)"
                  ></i
                ></span>
              </template>

              <!-- <template #cell(tipo_solicitud)="data">
                                    <span
                                        class="badge font-size-13"
                                        :class="
                                            colorTipoSolicitud(
                                                data.item.tipo_solicitud
                                            )
                                        "
                                    >
                                        {{ data.item.tipo_solicitud }}
                                    </span>
                                </template> -->
            </b-table>
          </div>
          <div class="row">
            <div class="col">
              <div class="dataTables_paginate paging_simple_numbers float-end">
                <ul class="pagination pagination-rounded mb-0">
                  <!-- pagination -->
                  <b-pagination
                    v-model="currentPage"
                    :total-rows="totalRows"
                    :per-page="perPage"
                  ></b-pagination>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="text-end mt-3">
        <b-button
          type="button"
          variant="success"
          class="ms-1"
          @click="cerrarDetalle()"
          >Cerrar</b-button
        >
      </div>
    </div>

    <b-modal v-model="mostrarModalRecepcion" hide-footer hide-header size="md">
      <loading-flux :value="loadingModalRecepcion"></loading-flux>
      <div class="d-flex">
        <div class="card-title modal-producto-detalle-titulo">
          Recepción Producto
        </div>
      </div>
      <h5 class="card-title-desc modal-producto-detalle-subtitulo">
        {{ lugarInstalacion.nombre_proyecto }}
      </h5>

      <div class="modal-producto-detalle-contenedor">
        <div class="row modal-producto-detalle-fila">
          <div class="col-5 modal-producto-detalle-descripcion">
            Código Producto
          </div>
          <div class="col-7">
            {{ solicitudSeleccionada.codigo_producto }}
          </div>
        </div>
        <div class="row modal-producto-detalle-fila">
          <div class="col-5 modal-producto-detalle-descripcion">
            Nombre Producto
          </div>
          <div class="col-7">
            {{ solicitudSeleccionada.nombre_producto }}
          </div>
        </div>
        <div class="row modal-producto-detalle-fila">
          <div class="col-5 modal-producto-detalle-descripcion">
            Cantidad Solicitada
          </div>
          <div class="col-7">
            {{ solicitudSeleccionada.cantidad_solicitada }}
          </div>
        </div>
        <div class="row modal-producto-detalle-fila">
          <div class="col-5 modal-producto-detalle-descripcion">
            Cantidad Recepcionada
          </div>
          <div class="col-7">
            <input
              id="cantidadRecepcionada"
              v-model="$v.formRecepcion.cantidadRecepcionada.$model"
              type="number"
              class="form-control form-control-sm"
              placeholder=""
              :class="{
                'is-invalid': submitted && cantidadRecepcionadaError,
              }"
            />
            <div
              v-if="
                submitted && !$v.formRecepcion.cantidadRecepcionada.required
              "
              class="invalid-feedback"
            >
              El campo es obligatorio y la cantidad no puede ser menor ni igual
              a cero
            </div>

            <div
              v-if="submitted && cantidadRecepcionadaMayor"
              class="invalid-feedback"
            >
              La cantidad no puede se mayor a la solicitada
            </div>
          </div>
        </div>
        <div class="row modal-producto-detalle-fila">
          <div class="col-5 modal-producto-detalle-descripcion">
            Comentario Adicional
          </div>
          <div class="col-7">
            <textarea
              id="comentarioRecepcionada"
              v-model="$v.formRecepcion.comentarioRecepcionada.$model"
              type="text"
              class="form-control"
              placeholder="Comentario"
              :class="{
                'is-invalid':
                  submitted && $v.formRecepcion.comentarioRecepcionada.$error,
              }"
              maxlength="100"
              rows="3"
            />
            <div
              v-if="
                submitted && !$v.formRecepcion.comentarioRecepcionada.required
              "
              class="invalid-feedback"
            >
              El campo es obligatorio
            </div>
          </div>
        </div>
      </div>
      <div class="text-end">
        <b-button variant="light" @click="mostrarModalRecepcion = false"
          >Cerrar</b-button
        >
        <b-button
          v-if="rolModuloPermiso.escritura"
          type="button"
          variant="success"
          class="ms-1"
          @click="actualizarCantidadRecepcionada()"
          >Guardar</b-button
        >
      </div>
    </b-modal>

    <b-modal v-model="mostrarModalConsumido" hide-footer hide-header size="md">
      <loading-flux :value="loadingModalConsumido"></loading-flux>
      <div class="d-flex">
        <div class="card-title modal-producto-detalle-titulo">
          Vale Consumo
        </div>
      </div>
      <h5 class="card-title-desc modal-producto-detalle-subtitulo">
        {{ lugarInstalacion.nombre_proyecto }}
      </h5>

      <div class="modal-producto-detalle-contenedor">
        <div class="row modal-producto-detalle-fila">
          <div class="col-5 modal-producto-detalle-descripcion">
            Código Producto
          </div>
          <div class="col-7">
            {{ solicitudSeleccionada.codigo_producto }}
          </div>
        </div>
        <div class="row modal-producto-detalle-fila">
          <div class="col-5 modal-producto-detalle-descripcion">
            Nombre Producto
          </div>
          <div class="col-7">
            {{ solicitudSeleccionada.nombre_producto }}
          </div>
        </div>
        <div class="row modal-producto-detalle-fila">
          <div class="col-5 modal-producto-detalle-descripcion">
            Cantidad Solicitada
          </div>
          <div class="col-7">
            {{ solicitudSeleccionada.cantidad_solicitada }}
          </div>
        </div>
        <div class="row modal-producto-detalle-fila">
          <div class="col-5 modal-producto-detalle-descripcion">
            Cantidad Recepcionada
          </div>
          <div class="col-7">
            {{ solicitudSeleccionada.cantidad_recepcionada }}
          </div>
        </div>
        <div class="row modal-producto-detalle-fila">
          <div class="col-5 modal-producto-detalle-descripcion">
            Cantidad Consumida
          </div>
          <div class="col-7">
            <input
              id="cantidadRecepcionada"
              v-model="$v.formConsumida.cantidadConsumida.$model"
              type="number"
              class="form-control form-control-sm"
              placeholder=""
              :class="{
                'is-invalid': submitted && cantidadConsumidaError,
              }"
            />
            <div
              v-if="submitted && !$v.formConsumida.cantidadConsumida.required"
              class="invalid-feedback"
            >
              El campo es obligatorio y la cantidad no puede ser menor ni igual
              a cero
            </div>

            <div
              v-if="submitted && cantidadConsumidaMayor"
              class="invalid-feedback"
            >
              La cantidad no puede se mayor a la recepcionada
            </div>
          </div>
        </div>
        <div class="row modal-producto-detalle-fila">
          <div class="col-5 modal-producto-detalle-descripcion">
            Comentario Adicional
          </div>
          <div class="col-7">
            <textarea
              id="comentarioConsumida"
              v-model="$v.formConsumida.comentarioConsumida.$model"
              type="text"
              class="form-control"
              placeholder="Comentario"
              :class="{
                'is-invalid':
                  submitted && $v.formConsumida.comentarioConsumida.$error,
              }"
              maxlength="100"
              rows="3"
            />
            <div
              v-if="submitted && !$v.formConsumida.comentarioConsumida.required"
              class="invalid-feedback"
            >
              El campo es obligatorio
            </div>
          </div>
        </div>
      </div>
      <div class="text-end">
        <b-button variant="light" @click="mostrarModalConsumido = false"
          >Cerrar</b-button
        >
        <b-button
          v-if="rolModuloPermiso.escritura"
          type="button"
          variant="success"
          class="ms-1"
          @click="actualizarCantidadConsumida()"
          >Guardar</b-button
        >
      </div>
    </b-modal>

    <b-modal v-model="mostrarModalDevuelto" hide-footer hide-header size="md">
      <loading-flux :value="loadingModalRecepcion"></loading-flux>
      <div class="d-flex">
        <div class="card-title modal-producto-detalle-titulo">
          Devolución
        </div>
      </div>
      <h5 class="card-title-desc modal-producto-detalle-subtitulo">
        {{ lugarInstalacion.nombre_proyecto }}
      </h5>

      <div class="modal-producto-detalle-contenedor">
        <div class="row modal-producto-detalle-fila">
          <div class="col-5 modal-producto-detalle-descripcion">
            Código Producto
          </div>
          <div class="col-7">
            {{ solicitudSeleccionada.codigo_producto }}
          </div>
        </div>
        <div class="row modal-producto-detalle-fila">
          <div class="col-5 modal-producto-detalle-descripcion">
            Nombre Producto
          </div>
          <div class="col-7">
            {{ solicitudSeleccionada.nombre_producto }}
          </div>
        </div>
        <div class="row modal-producto-detalle-fila">
          <div class="col-5 modal-producto-detalle-descripcion">
            Cantidad Solicitada
          </div>
          <div class="col-7">
            {{ solicitudSeleccionada.cantidad_solicitada }}
          </div>
        </div>
        <div class="row modal-producto-detalle-fila">
          <div class="col-5 modal-producto-detalle-descripcion">
            Cantidad Recepcionada
          </div>
          <div class="col-7">
            {{ solicitudSeleccionada.cantidad_recepcionada }}
          </div>
        </div>
        <div class="row modal-producto-detalle-fila">
          <div class="col-5 modal-producto-detalle-descripcion">
            Cantidad Consumida
          </div>
          <div class="col-7">
            {{ solicitudSeleccionada.cantidad_consumida }}
          </div>
        </div>
        <div class="row modal-producto-detalle-fila">
          <div class="col-5 modal-producto-detalle-descripcion">
            Cantidad Devuelta
          </div>
          <div class="col-7">
            <input
              id="cantidadDevuelta"
              v-model="$v.formDevuelta.cantidadDevuelta.$model"
              type="number"
              class="form-control form-control-sm"
              placeholder=""
              :class="{
                'is-invalid': submitted && cantidadDevueltaError,
              }"
            />
            <div
              v-if="submitted && !$v.formDevuelta.cantidadDevuelta.required"
              class="invalid-feedback"
            >
              El campo es obligatorio
            </div>

            <div
              v-if="submitted && cantidadDevueltaMayor"
              class="invalid-feedback"
            >
              La cantidad devuelta, no puede se mayor a la diferecia entre
              recepcionada y consumida
            </div>
          </div>
        </div>
        <div class="row modal-producto-detalle-fila">
          <div class="col-5 modal-producto-detalle-descripcion">
            Comentario Adicional
          </div>
          <div class="col-7">
            <textarea
              id="comentarioDevuelta"
              v-model="$v.formDevuelta.comentarioDevuelta.$model"
              type="text"
              class="form-control"
              placeholder="Comentario"
              :class="{
                'is-invalid':
                  submitted && $v.formDevuelta.comentarioDevuelta.$error,
              }"
              maxlength="100"
              rows="3"
            />
            <div
              v-if="submitted && !$v.formDevuelta.comentarioDevuelta.required"
              class="invalid-feedback"
            >
              El campo es obligatorio
            </div>
          </div>
        </div>
      </div>
      <div class="text-end">
        <b-button variant="light" @click="mostrarModalDevuelto = false"
          >Cerrar</b-button
        >
        <b-button
          v-if="rolModuloPermiso.escritura"
          type="button"
          variant="success"
          class="ms-1"
          @click="actualizarCantidadDevuelta()"
          >Guardar</b-button
        >
      </div>
    </b-modal>

    <b-modal
      v-model="mostrarModalEnviarDevueltoErp"
      hide-footer
      hide-header
      size="md"
    >
      <loading-flux :value="loadingEnviarDevueltoErp"></loading-flux>

      <div class="card-title text-center modal-producto-detalle-titulo">
        Esta seguro que desea guardar?
      </div>

      <div class="card-title-desc text-center modal-producto-detalle-subtitulo">
        Al guardar la SM generará un documento en el ERP.
      </div>

      <div
        class="mt-2 card-title-desc text-center modal-producto-detalle-subtitulo"
      >
        Desea agregar un comentario sobre la devolución?
      </div>
      <div class="mt-4 mb-4">
        <textarea
          v-model="comentarioEnviarDevueltoErp"
          name="enviarDevolucionErp"
          id=""
          cols="30"
          rows="5"
          class="form-control"
          placeholder="Comentario Opcional"
        ></textarea>
      </div>
      <div class="text-center">
        <b-button
          v-if="rolModuloPermiso.escritura"
          type="button"
          variant="primary"
          class="ms-1"
          @click="enviarDevolucion"
          >Guardar</b-button
        >
        <b-button variant="light" @click="mostrarModalEnviarDevueltoErp = false"
          >Cerrar</b-button
        >
      </div>
    </b-modal>
  </div>
</template>
<script>
import { required, numeric } from "vuelidate/lib/validators";
import { validationMixin } from "vuelidate";
import { proyectoMethods, proyectoComputed } from "@/state/helpers";
import { productoMethods, productoComputed } from "@/state/helpers";
import {
  solicitudMaterialMethods,
  solicitudMaterialComputed,
} from "@/state/helpers";
import Multiselect from "vue-multiselect";
import Swal from "sweetalert2";
import { authMethods, authUsuarioComputed } from "@/state/helpers";
import { permiso } from "@/helpers/authservice/obtener-permisos";
import { bodegaMethods, bodegaComputed } from "@/state/helpers";
import moment from "moment";
import {
  REQUEST_MATERIAL,
  REQUEST_MATERIAL_URGENT,
} from "@/constants/requestMaterial";
moment.locale("es");
export default {
  mixins: [validationMixin],
  components: {
    // Multiselect,
  },
  props: {
    solicitudMaterial: Object,
    lugarInstalacion: Object,
  },
  data() {
    return {
      renderComponent: true,
      loading: false,
      mensaje: { variant: "", texto: "" },
      segundos: 5,
      segundosMensajeActualizacion: 0,
      proyectos: [],
      totalRows: 1,
      todoTotalRows: 1,
      currentPage: 1,
      todocurrentPage: 1,
      perPage: 10,
      todoperPage: 10,
      pageOptions: [10, 25, 50, 100],
      todoOptions: [10, 50, 100, 150, 200],
      filter: null,
      todoFilter: null,
      filterOn: [],
      todofilterOn: [],
      sortBy: "codigo_producto",
      sortDesc: false,
      fields: [
        // {
        //     key: "Accion",
        //     sortable: false,
        // },
        // {
        //     key: "recepcionado",
        //     sortable: true,
        // },
        {
          key: "codigo_producto",
          sortable: true,
        },
        {
          key: "nombre_producto",
          sortable: true,
        },
        {
          key: "cantidad_solicitada",
          label: "Solicitados",
          sortable: true,
        },
        {
          key: "cantidad_recepcionada",
          label: "Recepcionados",
          sortable: true,
        },
        {
          key: "cantidad_consumida",
          label: "Consumidos",
          sortable: true,
        },
        {
          key: "cantidad_devuelta",
          label: "Devueltos",
          sortable: true,
        },
        // {
        //     key: "unidad",
        //     sortable: true,
        // },
        {
          key: "comentario",
          sortable: true,
        },
      ],
      codigoProyecto: null,
      codigoLugarInstalacion: null,
      guardarCerrar: true,
      solicitudSeleccionada: {},
      // solicitudMaterial: {
      //     codigo_lugar_instalacion: "",
      //     codigo_proyecto: "",
      //     productos: [],
      // },
      solicitudMateriales: [],
      solicitudMaterialesDetalle: [],
      solicitudMaterialDetalles: [],
      solicitudMaterialDetallesCargando: false,
      esEditar: false,
      submitted: false,
      mostrarModal: false,
      productos: [],
      productosCargando: false,
      solicitudMaterialesCargando: false,
      unidades: [
        { value: "un", label: "un" },
        { value: "m", label: "m" },
        { value: "m3", label: "m3" },
        { value: "kg", label: "kg" },
        { value: "ton", label: "ton" },
        { value: "día", label: "día" },
      ],
      tipoSolicitudes: [
        { value: "Solicitud", label: "Solicitud" },
        { value: "Reserva", label: "Reserva" },
        { value: "Recepcion", label: "Recepcion" },
        { value: "Consumo", label: "Consumo" },
        { value: "ValeConsumo", label: "ValeConsumo" },
        { value: "Devolucion", label: "Devolucion" },
      ],
      fechaValidar: null,
      fechaTerminoValidar: null,
      formRecepcion: {
        cantidadRecepcionada: null,
        comentarioRecepcionada: null,
      },
      formConsumida: {
        cantidadConsumida: null,
        comentarioConsumida: null,
      },
      formDevuelta: {
        cantidadDevuelta: null,
        comentarioDevuelta: null,
      },
      mostrarModalDetalle: false,
      tipoSolicitud: null,
      rolModuloPermiso: {},
      bodegas: [],
      bodegasCargando: false,
      cantidadRecepcionada: null,
      cantidadConsumida: null,
      cantidadDevuelta: null,
      mostrarCantidadRecepcionada: false,
      mostrarCantidadConsumida: false,
      mostrarCantidadDevuelta: false,
      mostrarBotonesCantidad: false,
      productoSeleccionado: {},
      mostrarModalRecepcion: false,
      loadingModalRecepcion: false,
      mostrarModalConsumido: false,
      loadingModalConsumido: false,
      loadingModalDevuelto: false,
      mostrarModalDevuelto: false,

      cantidadRecepcionadaMayor: false,
      cantidadConsumidaMayor: false,
      cantidadDevueltaMayor: false,
      cantidadRecepcionadaError: false,
      cantidadConsumidaError: false,
      cantidadDevueltaError: false,

      mostrarModalEnviarDevueltoErp: false,
      loadingEnviarDevueltoErp: false,
      comentarioEnviarDevueltoErp: null,
      enviarDevueltoErp: false,
      REQUEST_MATERIAL,
      REQUEST_MATERIAL_URGENT,
    };
  },
  validations: {
    formRecepcion: {
      cantidadRecepcionada: { required, numeric },
      comentarioRecepcionada: {},
    },
    formConsumida: {
      cantidadConsumida: { required, numeric },
      comentarioConsumida: {},
    },
    formDevuelta: {
      cantidadDevuelta: { required, numeric },
      comentarioDevuelta: {},
    },
  },
  mounted() {
    this.obtenerSolicitudMaterialDetallesProyecto();
    let permisos = permiso.obtenerPermisos(
      this.user,
      this.$router.currentRoute.name
    );
    this.rolModuloPermiso = permisos;
  },
  methods: {
    ...solicitudMaterialMethods,
    ...productoMethods,
    ...proyectoMethods,
    ...bodegaMethods,
    abrirModalRecepcion(item) {
      this.solicitudSeleccionada = item;

      this.$v.formRecepcion.cantidadRecepcionada.$model = this.solicitudSeleccionada.cantidad_recepcionada;
      this.$v.formRecepcion.comentarioRecepcionada.$model = this.solicitudSeleccionada.comentario_recepcionada;
      this.mostrarModalRecepcion = true;
    },
    abrirModalConsumido(item) {
      this.solicitudSeleccionada = item;

      this.$v.formConsumida.cantidadConsumida.$model = this.solicitudSeleccionada.cantidad_consumida;
      this.$v.formConsumida.comentarioConsumida.$model = this.solicitudSeleccionada.comentario_consumida;
      this.mostrarModalConsumido = true;
    },
    abrirModalDevuelto(item) {
      this.solicitudSeleccionada = item;

      this.$v.formDevuelta.cantidadDevuelta.$model = this.solicitudSeleccionada.cantidad_devuelta;
      this.$v.formDevuelta.comentarioDevuelta.$model = this.solicitudSeleccionada.comentario_devuelta;
      this.mostrarModalDevuelto = true;
      this.loadingModalDevuelto = false;
    },
    cerrarDetalle() {
      this.$emit("cerrarDetalle");
    },
    actualizarCantidadRecepcionada() {
      this.loadingModalRecepcion = true;
      this.cantidadRecepcionadaError = false;
      this.cantidadRecepcionadaMayor = false;
      this.submitted = true;
      this.$v.formRecepcion.$touch();

      if (this.$v.formRecepcion.$invalid) {
        this.cantidadRecepcionadaError = true;
        this.loadingModalRecepcion = false;
        return;
      }

      if (
        this.$v.formRecepcion.cantidadRecepcionada.$model >
        this.solicitudSeleccionada.cantidad_solicitada
      ) {
        this.cantidadRecepcionadaError = true;
        this.cantidadRecepcionadaMayor = true;
        this.loadingModalRecepcion = false;
        return;
      }

      this.actualizarSolicitudMaterialProductoCantidadRecepcionada({
        solicitud_material_detalle_id: this.solicitudSeleccionada
          .solicitud_material_detalle_id,
        cantidad_recepcionada: this.$v.formRecepcion.cantidadRecepcionada
          .$model,

        comentario_recepcionada:
          this.$v.formRecepcion.comentarioRecepcionada.$model != null
            ? this.$v.formRecepcion.comentarioRecepcionada.$model
            : null,
      })
        .then((res) => {
          if (res.status == 202) {
            this.mensaje.variant = "danger";
            this.mensaje.texto = "Ha ocurrido un error, intente nuevamente";
          }

          if (res.status == 204) {
            this.mensaje.variant = "success";
            this.mensaje.texto = "Producto actualizado correctamente!!!";
            this.cerrarActualizacionCantidad();
            this.mostrarModalRecepcion = false;
          }
          this.obtenerSolicitudMaterialDetallesProyecto();
          this.segundosMensajeActualizacion = this.segundos;
          this.loadingModalRecepcion = false;
        })
        .catch((error) => {
          this.mensaje.variant = "danger";
          this.mensaje.texto = "Ha ocurrido un error, intente nuevamente";

          this.segundosMensajeActualizacion = this.segundos;
          this.loadingModalRecepcion = false;
        });
      this.submitted = false;
      this.cantidadRecepcionadaError = false;
      this.cantidadRecepcionadaMayor = false;
    },
    actualizarCantidadConsumida() {
      this.loadingModalConsumido = true;
      this.cantidadConsumidaError = false;
      this.cantidadConsumidaMayor = false;
      this.submitted = true;
      this.$v.formConsumida.$touch();

      if (this.$v.formConsumida.$invalid) {
        this.cantidadConsumidaError = true;
        this.loadingModalConsumido = false;
        return;
      }

      if (
        this.$v.formConsumida.cantidadConsumida.$model >
        this.solicitudSeleccionada.cantidad_recepcionada
      ) {
        this.cantidadConsumidaError = true;
        this.cantidadConsumidaMayor = true;
        this.loadingModalConsumido = false;
        return;
      }
      this.actualizarSolicitudMaterialProductoCantidadConsumida({
        solicitud_material_detalle_id: this.solicitudSeleccionada
          .solicitud_material_detalle_id,
        cantidad_consumida: this.$v.formConsumida.cantidadConsumida.$model,
        comentario_consumida:
          this.$v.formConsumida.comentarioConsumida.$model != null
            ? this.$v.formConsumida.comentarioConsumida.$model
            : null,
      })
        .then((res) => {
          if (res.status == 202) {
            this.mensaje.variant = "danger";
            this.mensaje.texto = "Ha ocurrido un error, intente nuevamente";
          }

          if (res.status == 204) {
            this.mensaje.variant = "success";
            this.mensaje.texto = "Producto actualizado correctamente!!!";
            this.cerrarActualizacionCantidad();
          }
          this.obtenerSolicitudMaterialDetallesProyecto();
          this.segundosMensajeActualizacion = this.segundos;
          // this.obtenerUsuariosLocal();
          this.mostrarModalConsumido = false;
          this.loadingModalConsumido = false;
        })
        .catch((error) => {
          this.mensaje.variant = "danger";
          this.mensaje.texto = "Ha ocurrido un error, intente nuevamente";

          this.segundosMensajeActualizacion = this.segundos;
          this.mostrarModalConsumido = false;
          this.loadingModalDevuelto = false;
        });
    },
    actualizarCantidadDevuelta() {
      this.cantidadDevueltaError = false;
      this.cantidadDevueltaMayor = false;
      this.submitted = true;
      this.$v.formDevuelta.$touch();
      if (this.$v.formDevuelta.$invalid) {
        this.cantidadDevueltaError = true;
        this.loadingModalDevuelto = false;
        return;
      }

      let totalDevueltas = this.solicitudMaterialDetalles.filter((item) => {
        return item.cantidad_devuelta >= 0 && item.cantidad_devuelta != null;
      });

      let recepcionada = parseInt(
        this.solicitudSeleccionada.cantidad_recepcionada
      );
      let consumida = parseInt(this.solicitudSeleccionada.cantidad_consumida);
      let cantidadMaximaDevolver = recepcionada - consumida;

      if (
        this.$v.formDevuelta.cantidadDevuelta.$model > cantidadMaximaDevolver
      ) {
        this.cantidadDevueltaError = true;
        this.cantidadDevueltaMayor = true;
        this.loadingModalDevuelto = false;
        return;
      }
      if (
        totalDevueltas.length + 1 >=
        parseInt(this.solicitudMaterialDetalles.length)
      ) {
        this.mostrarModalEnviarDevueltoErp = true;
        this.enviarDevueltoErp = true;
        return;
      }

      this.enviarDevolucion();
    },
    enviarDevolucion() {
      this.loadingModalDevuelto = true;
      this.loadingEnviarDevueltoErp = true;

      let devolucion_comentario =
        this.comentarioEnviarDevueltoErp != null
          ? this.comentarioEnviarDevueltoErp
          : null;

      this.actualizarSolicitudMaterialProductoCantidadDevuelta({
        solicitud_material_detalle_id: this.solicitudSeleccionada
          .solicitud_material_detalle_id,
        cantidad_devuelta: this.$v.formDevuelta.cantidadDevuelta.$model,
        comentario_devuelta:
          this.$v.formDevuelta.comentarioDevuelta.$model != null
            ? this.$v.formDevuelta.comentarioDevuelta.$model
            : null,
        devolucion_comentario,
      })
        .then((res) => {
          if (res.status == 202) {
            this.mensaje.variant = "danger";
            this.mensaje.texto = "Ha ocurrido un error, intente nuevamente";
          }

          if (res.status == 204) {
            this.mensaje.variant = "success";
            this.mensaje.texto = "Producto actualizado correctamente!!!";
            if (this.enviarDevueltoErp) {
              this.actualizarSolicitudMaterialDevolucion({
                solicitud_material_id: this.solicitudSeleccionada
                  .solicitud_material_id,
                devolucion_comentario: devolucion_comentario,
              })
                .then((res) => {
                  if (res.status == 202) {
                    this.mensaje.variant = "danger";
                    this.mensaje.texto =
                      "Ha ocurrido un error, intente nuevamente";
                  }

                  if (res.status == 204) {
                    this.mensaje.variant = "success";
                    this.mensaje.texto = "Devolucion generada correctamente!!!";
                    this.mostrarModalEnviarDevueltoErp = false;
                    this.solicitudMaterial.devolucion = 1;
                  }
                  // this.obtenerSolicitudMateriales();
                  // this.segundosMensajeActualizacion = this.segundos;
                  // this.loading = false

                  this.cerrarActualizacionCantidad();

                  this.obtenerSolicitudMaterialDetallesProyecto();
                  this.segundosMensajeActualizacion = this.segundos;

                  this.mostrarModalDevuelto = false;
                  this.loadingModalDevuelto = false;
                  this.loadingEnviarDevueltoErp = false;
                })
                .catch((error) => {
                  this.mensaje.variant = "danger";
                  this.mensaje.texto =
                    "Ha ocurrido un error, intente nuevamente";
                  this.obtenerSolicitudMateriales();
                  this.segundosMensajeActualizacion = this.segundos;
                  this.loadingModalDevuelto = false;
                  this.loadingEnviarDevueltoErp = false;
                });
            } else {
              this.cerrarActualizacionCantidad();
              this.obtenerSolicitudMaterialDetallesProyecto();
              this.segundosMensajeActualizacion = this.segundos;
              this.mostrarModalDevuelto = false;
              this.loadingModalDevuelto = false;
              this.loadingEnviarDevueltoErp = false;
            }
          }
        })
        .catch((error) => {
          this.mensaje.variant = "danger";
          this.mensaje.texto = "Ha ocurrido un error, intente nuevamente";

          this.segundosMensajeActualizacion = this.segundos;
          this.mostrarModalDevuelto = false;
          this.mostrarModalEnviarDevueltoErp = false;
          this.loadingModalDevuelto = false;
        });
    },
    cerrarActualizacionCantidad() {
      this.mostrarCantidadRecepcionada = false;
      this.mostrarCantidadConsumida = false;
      this.mostrarCantidadDevuelta = false;
      this.mostrarBotonesCantidad = false;

      this.$v.formRecepcion.cantidadRecepcionada.$model = null;
      this.$v.formConsumida.cantidadConsumida.$model = null;
      this.$v.formDevuelta.cantidadDevuelta.$model = null;
    },
    seleccionarDetalleCantidadRecepcionada(datos) {
      this.cerrarActualizacionCantidad();
      this.solicitudSeleccionada = datos;
      this.mostrarCantidadRecepcionada = true;
      this.mostrarBotonesCantidad = true;
    },
    seleccionarDetalleCantidadConsumida(datos) {
      this.cerrarActualizacionCantidad();
      this.solicitudSeleccionada = datos;
      this.mostrarCantidadConsumida = true;
      this.mostrarBotonesCantidad = true;
    },
    eliminarProducto(producto) {
      var filtrado = this.solicitudMaterial.productos.filter(
        (c) => c.codigo_producto != producto.codigo_producto
      );
      this.solicitudMaterial.productos = filtrado;
    },
    descripcionProducto(producto) {
      return `${producto.Producto} - (${producto.Stock}) - ${producto.Descripción}`;
    },
    actualizarSegundosMensajeActualizacion(segundosMensajeActualizacion) {
      this.segundosMensajeActualizacion = segundosMensajeActualizacion;
    },
    abrirModalNuevo(tipoSolicitud) {
      this.tipoSolicitud = tipoSolicitud;
      this.resetForm();
      this.esEditar = false;
      this.submitted = false;
      this.mostrarModal = true;
      this.obtenerStockProductosLocal();
      this.obtenerBodegasLocal();
    },

    abrirModalDetalle(item) {
      // this.solicitudSeleccionada = datos;

      this.mostrarModalDetalle = true;
    },
    obtenerBodegasLocal() {
      this.bodegasCargando = true;
      this.obtenerBodegas()
        .then((res) => {
          this.bodegas = res.body;
          // if(this.usuarioSeleccionado.codigo_bodega != undefined){
          //     this.$v.form.bodega.$model = {
          //         codigo_bodega: this.usuarioSeleccionado.codigo_bodega,
          //         nombre_bodega: this.usuarioSeleccionado.nombre_bodega,
          //     };
          // }

          this.bodegasCargando = false;
        })
        .catch((error) => {
          this.bodegasCargando = false;
        });
    },
    obtenerStockProductosLocal() {
      this.productosCargando = true;
      this.obtenerStockProductos()
        .then((res) => {
          this.productos = res.body;
          if (this.esEditar) {
            this.$v.form.producto.$model = {
              producto_id: this.solicitudSeleccionada.producto_id,
              nombre_producto: this.solicitudSeleccionada.nombre_producto,
            };
          }
          this.productosCargando = false;
        })
        .catch((error) => {
          this.productosCargando = false;
        });
    },
    obtenerSolicitudMaterialesProyecto() {
      this.solicitudMaterialesCargando = true;
      this.obtenerSolicitudMaterialesProyectoLugarInstalacion({
        codigoLugarInstalacion: this.codigoLugarInstalacion,
        codigoProyecto: this.codigoProyecto,
      })
        .then((res) => {
          this.solicitudMateriales = res.body;
          this.solicitudMaterialesCargando = false;
        })
        .catch((error) => {
          this.solicitudMaterialesCargando = false;
        });
    },
    obtenerSolicitudMaterialDetallesProyecto() {
      this.solicitudMaterialDetalles = [];
      this.solicitudMaterialDetallesCargando = true;
      this.obtenerSolicitudMaterialDetalles(
        this.solicitudMaterial.solicitud_material_id
      )
        .then((res) => {
          this.solicitudMaterialDetalles = res.body;
          this.totalRows = this.solicitudMaterialDetalles.length;
          this.solicitudMaterialDetallesCargando = false;
        })
        .catch((error) => {
          this.solicitudMaterialDetallesCargando = false;
        });
    },
    productoRecepcionado(item) {
      if (
        item.cantidad_recepcionada == null ||
        item.cantidad_recepcionada <= 0
      ) {
        this.mensaje.variant = "danger";
        this.mensaje.texto = "La cantidad debe ser mayor a 0";
        this.segundosMensajeActualizacion = this.segundos;
        this.loading = false;
        this.forceRerender();
        return;
      }
      this.loading = true;
      this.actualizarSolicitudMaterialProductoRecepcionado({
        solicitud_material_detalle_id: item.solicitud_material_detalle_id,
      })
        .then((res) => {
          if (res.status == 202) {
            this.mensaje.variant = "danger";
            this.mensaje.texto = "Ha ocurrido un error, intente nuevamente";
          }

          if (res.status == 204) {
            this.mensaje.variant = "success";
            this.mensaje.texto = "Producto actualizado correctamente!!!";
          }
          this.obtenerSolicitudMaterialDetallesProyecto();
          this.segundosMensajeActualizacion = this.segundos;
          // this.obtenerUsuariosLocal();
          this.loading = false;
        })
        .catch((error) => {
          this.mensaje.variant = "danger";
          this.mensaje.texto = "Ha ocurrido un error, intente nuevamente";

          this.segundosMensajeActualizacion = this.segundos;
          this.loading = false;
        });
    },
    forceRerender() {
      this.renderComponent = false;
      this.$nextTick(() => {
        this.renderComponent = true;
      });
    },
    resetForm() {
      this.form = {
        producto: null,
        cantidad: null,
        fecha: null,
        comentario: null,
        unidad: null,
        bodega: null,
      };

      this.$nextTick(() => {
        this.$v.$reset();
      });
    },
    resetFormAdd() {
      this.form = {
        producto: null,
        cantidad: null,
        fecha: null,
        comentario: null,
        unidad: null,
      };

      this.$nextTick(() => {
        this.$v.$reset();
      });
    },
    eliminarActividadProyectoLocal(item) {
      const mensajeEliminar = Swal.mixin({
        customClass: {
          title: "font-size-18",
          confirmButton: "btn btn-danger ms-2",
          cancelButton: "btn btn-gris-claro",
        },
        buttonsStyling: false,
      });
      mensajeEliminar
        .fire({
          title: "Esta seguro que desea eliminar el registro?",
          text: "",
          icon: "warning",
          showCancelButton: true,
          confirmButtonText: "Eliminar",
          cancelButtonText: "Cancelar",
          allowOutsideClick: false,
          reverseButtons: true,
        })
        .then((result) => {
          if (result.value) {
            this.eliminarActividadProyecto(item.solicitud_material_id)
              .then((res) => {
                if (res.status == 202) {
                  this.mensaje.variant = "danger";
                  this.mensaje.texto =
                    "Ha ocurrido un error, intente nuevamente";
                }

                if (res.status == 204) {
                  this.mensaje.variant = "success";
                  this.mensaje.texto = "Solicitud eliminada correctamente!!!";
                  if (this.guardarCerrar) {
                    this.mostrarModal = false;
                  }
                }
                this.segundosMensajeActualizacion = this.segundos;
                this.obtenerSolicitudMaterialesProyecto();
              })
              .catch((error) => {
                this.mensaje.variant = "danger";
                this.mensaje.texto =
                  "No se ha eliminado el registro, intente nuevamente";
                this.mostrarModal = false;
                this.segundosMensajeActualizacion = this.segundos;
              });
          }
        });
    },
    colorTipoSolicitud(tipoSolicitud) {
      if (tipoSolicitud == "Solicitud") {
        return "bg-success";
      } else if (tipoSolicitud == "Reserva") {
        return "bg-primary";
      } else if (tipoSolicitud == "Recepcion") {
        return "bg-warning";
      } else if (tipoSolicitud == "Consumo") {
        return "bg-info";
      } else if (tipoSolicitud == "ValeConsumo") {
        return "bg-info";
      } else if (tipoSolicitud == "Devolucion") {
        return "bg-danger";
      }
    },
    irLugarinstalacion(proyecto) {
      this.seleccionarProyecto(proyecto[0]);
      this.$router.push({
        name: "lugaresInstalacion",
        params: { codigoProyecto: proyecto[0].codigo_proyecto },
      });
    },
    formatDate(value) {
      const duration = moment(value).format("DD/MM/yyyy");
      return `${duration}`;
    },
    /**
     * Search the table data with search input
     */
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },

    todoFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.todoTotalRows = filteredItems.length;
      this.todocurrentPage = 1;
    },

    handleInput(value, data) {
      this.editableDataItems[data.index][data.field.key] = value;
    },
  },
  computed: {
    ...proyectoComputed,
    ...authUsuarioComputed,
  },
};
</script>

<style>
.crearActividadesProyecto {
  margin-right: 10px;
}

.table-solicitud-material-detalle {
  height: 300px;
}

.modal-producto-detalle-contenedor {
  padding: 20px;
  /* font-size: 0.9rem; */
}

.modal-producto-detalle-descripcion {
  font-weight: bold;
}

.modal-producto-detalle-fila {
  padding: 8px 0;
}
.modal-producto-detalle-titulo {
  padding: 0 20px;
  font-size: 1.2rem;
}
.modal-producto-detalle-subtitulo {
  padding: 0 20px;
  margin: 0;
}
</style>
