<template>
  <div>
    <loading-flux :value="loading"></loading-flux>
    <!-- <h5 class="card-title-desc subtitulo">
            {{ lugarInstalacion.nombre_proyecto }} - COD
            {{ solicitudMaterial.solicitud_correlativo }}
        </h5> -->
    <div class="row">
      <div class="col-12">
        <div class="">
          <b-alert
            :show="segundosMensajeActualizacion"
            dismissible
            :variant="mensaje.variant"
            @dismissed="segundosMensajeActualizacion = 0"
            @dismiss-count-down="actualizarSegundosMensajeActualizacion"
          >
            {{ mensaje.texto }}
          </b-alert>
          <div class="row">
            <div class="row mb-3"></div>

            <!-- <div class="col-sm-12 col-md-6">
                            <div
                                id="tickets-table_length"
                                class="dataTables_length"
                            >
                                <label class="d-inline-flex align-items-center">
                                    Mostrar&nbsp;
                                    <b-form-select
                                        class="form-select form-select-sm"
                                        v-model="perPage"
                                        size="sm"
                                        :options="pageOptions"
                                    ></b-form-select>
                                </label>
                            </div>
                        </div> -->
            <!-- Search -->
            <div class="">
              <div
                id="tickets-table_filter"
                class="dataTables_filter text-md-end"
              >
                <label class="d-inline-flex align-items-center">
                  Buscar:
                  <b-form-input
                    v-model="filter"
                    type="search"
                    placeholder="Buscar..."
                    class="form-control form-control-sm ms-2"
                  ></b-form-input>
                </label>
              </div>
            </div>
            <!-- End search -->
          </div>
          <!-- Table -->
          <div class="table-responsive mb-0">
            <b-table
              v-if="renderComponent"
              class="datatables tabla-personalizada"
              :items="solicitudMaterialeDespachos"
              :fields="fields"
              responsive="sm"
              :per-page="perPage"
              :current-page="currentPage"
              :sort-by.sync="sortBy"
              :sort-desc.sync="sortDesc"
              :filter="filter"
              :filter-included-fields="filterOn"
              select-mode="single"
              selectable
              @filtered="onFiltered"
              :busy="solicitudMaterialeDespachosCargando"
            >
              <template #table-busy>
                <div class="text-center text-danger my-2">
                  <b-spinner class="align-middle"></b-spinner>
                </div>
              </template>

              <template #cell(numero_solicitud_despacho)="data">
                <span
                  class="badge font-size-13 bg-primary"
                  v-if="data.item.numero_solicitud_despacho != null"
                >
                  {{ data.item.numero_solicitud_despacho }}
                </span>
                <span class="badge font-size-13 bg-primary" v-else>
                  Orden de compras
                </span>
              </template>
            </b-table>
          </div>
          <div class="row">
            <div class="col">
              <div class="dataTables_paginate paging_simple_numbers float-end">
                <ul class="pagination pagination-rounded mb-0">
                  <!-- pagination -->
                  <b-pagination
                    v-model="currentPage"
                    :total-rows="totalRows"
                    :per-page="perPage"
                  ></b-pagination>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="text-end mt-3">
        <b-button
          type="button"
          variant="success"
          class="ms-1"
          @click="cerrarSolicitudDespacho()"
          >Cerrar</b-button
        >
      </div>
    </div>
  </div>
</template>
<script>
import { validationMixin } from "vuelidate";
import { proyectoMethods, proyectoComputed } from "@/state/helpers";
import {
  solicitudMaterialDespachoMethods,
  solicitudMaterialDespachoComputed,
} from "@/state/helpers";
import { authMethods, authUsuarioComputed } from "@/state/helpers";
import { permiso } from "@/helpers/authservice/obtener-permisos";
import moment from "moment";
moment.locale("es");
export default {
  mixins: [validationMixin],
  components: {
    // Multiselect,
  },
  props: {
    solicitudMaterial: Object,
    lugarInstalacion: Object,
  },
  data() {
    return {
      mensaje: { variant: "", texto: "" },
      segundos: 20,
      segundosMensajeActualizacion: 0,
      renderComponent: true,
      loading: false,
      proyectos: [],
      totalRows: 1,
      todoTotalRows: 1,
      currentPage: 1,
      todocurrentPage: 1,
      perPage: 100,
      todoperPage: 10,
      pageOptions: [10, 25, 50, 100],
      todoOptions: [10, 50, 100, 150, 200],
      filter: null,
      todoFilter: null,
      filterOn: [],
      todofilterOn: [],
      sortBy: "codigo_producto",
      sortDesc: false,
      fields: [
        {
          key: "codigo_producto",
          sortable: true,
        },
        {
          key: "nombre_producto",
          sortable: true,
        },
        // {
        //     key: "cantidad_solicitada",
        //     label: "Cantidad",
        //     sortable: true,
        // },
        // {
        //     key: "documento_solitud_despacho",
        //     label: "Documento",
        //     sortable: true,
        // },
        {
          key: "numero_solicitud_despacho",
          label: "Número Solicitud Despacho",
          sortable: true,
        },
        // {
        //     key: "fecha_solicitud_despacho",
        //     label: "Fecha",
        //     sortable: true,
        // },
        // // {
        //     key: "cantidad_solicitud_despacho",
        //     label: "Cantidad",
        //     sortable: true,
        // },
      ],
      codigoProyecto: null,
      codigoLugarInstalacion: null,

      solicitudMaterialeDespachos: [],
      solicitudMaterialeDespachosCargando: false,
      mostrarModalDetalle: false,
    };
  },

  mounted() {
    this.obtenerSolicitudMaterialDespachos();
    let permisos = permiso.obtenerPermisos(
      this.user,
      this.$router.currentRoute.name
    );
    this.rolModuloPermiso = permisos;
  },
  methods: {
    ...solicitudMaterialDespachoMethods,
    ...proyectoMethods,
    actualizarSegundosMensajeActualizacion(segundosMensajeActualizacion) {
      this.segundosMensajeActualizacion = segundosMensajeActualizacion;
    },
    cerrarSolicitudDespacho() {
      this.$emit("cerrarSolicitudDespacho");
    },
    obtenerSolicitudMaterialDespachos() {
      this.solicitudMaterialeDespachosCargando = true;
      this.obtenerSolicitudMaterialDespachosSolicitudNumero(
        this.solicitudMaterial.solicitud_correlativo
      )
        .then((res) => {
          this.solicitudMaterialeDespachos = res.body;
          if (this.solicitudMaterialeDespachos.length == 0) {
            this.mensaje.variant = "danger";
            this.mensaje.texto =
              "No se han generado solicitudes despacho para esta solicitud";

            this.segundosMensajeActualizacion = this.segundos;
          }
          this.solicitudMaterialeDespachosCargando = false;
        })
        .catch((error) => {
          this.solicitudMaterialeDespachosCargando = false;
        });
    },

    irLugarinstalacion(proyecto) {
      this.seleccionarProyecto(proyecto[0]);
      this.$router.push({
        name: "lugaresInstalacion",
        params: { codigoProyecto: proyecto[0].codigo_proyecto },
      });
    },
    formatDate(value) {
      const duration = moment(value).format("DD/MM/yyyy");
      return `${duration}`;
    },
    /**
     * Search the table data with search input
     */
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },

    todoFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.todoTotalRows = filteredItems.length;
      this.todocurrentPage = 1;
    },

    handleInput(value, data) {
      this.editableDataItems[data.index][data.field.key] = value;
    },
  },
  computed: {
    ...proyectoComputed,
    ...authUsuarioComputed,
  },
};
</script>
