<template>
    <v-card>
        <b-overlay :show="loading" variant="white" rounded="sm">
            <div class="card">
                <div class="card-body">
                    
                    <div class="d-flex" v-if="showTitle">
                        <div class="card-title titulo-tarjetas">
                            Imágenes de inspector
                        </div>
                    </div>
                    
                    <div v-if="imagenes.length > 0" :class="maxHeight ? 'scroll-y' : ''"> 
                        <div class="popup-gallery d-flex flex-wrap">
                            <div class="row">
                            <div
                                v-for="(src, index) in imagenes"
                                :key="index"
                                class="col-6 col-sm-4 col-lg-3"
                                @click="() => showImg(index)"
                            >
                                <div
                                    class="square-img"
                                    style="position: relative;"
                                >
                                    <img :src="src" class="mw-100" />
                                </div>
                            </div>
                            <vue-easy-lightbox
                                :visible="visible"
                                :imgs="imagenes"
                                :index="index"
                                @hide="handleHide"
                            ></vue-easy-lightbox>
                            </div>
                        </div>
                    </div>

                    <div v-else-if="loading" class="py-5"><h5 class="text-center">Cargando imágenes</h5></div>
                    <div v-else class="py-5">
                        <v-alert
                        color="red lighten-3"
                        type="info"
                        >
                        <span class="brown--text text--darken-4">No se han ingresado imágenes para este lugar de instalación</span>
                        </v-alert>
                    </div>
                </div>
            </div>
        </b-overlay>
    </v-card>
</template>

<script>
import {
    galeriaImagenesMethods,
    galeriaImagenesComputed,
} from "@/state/helpers";
import { proyectoMethods, proyectoComputed } from "@/state/helpers";
import VueEasyLightbox from "vue-easy-lightbox";

export default {
    name:'LugarGaleria',
    components: { 
        VueEasyLightbox 
    },
    props: { 
        maxHeight:{
            type: Number,
            required: false,
        }, 
        writeable: {
            type: Boolean,
            default: false,
        },
        showTitle: {
            type: Boolean,
            default: true,
        }
    },
    data() {
        return {

            loading: false,
            imagenes: [],
            lugarInstalacion: {
                codigo_lugar_instalacion: this.$route.params
                    .codigoLugarInstalacion,
                codigo_proyecto: this.$route.params.codigoProyecto,
            },
            codigoProyecto: {},
            codigoLugarInstalacion: {},
            visible: false,
            singleImage: false,
            visibleCaption: false,
            index: 0, // default: 0
            proyectoSeleccionado: {},
        };
    },
    mounted() {
        if (this.proyectoUsuario == null) {
            this.obtenerProyectoUsuario();
        } else {
            this.proyectoSeleccionado = this.proyectoUsuario;
        }
        this.codigoLugarInstalacion = this.$route.params.codigoLugarInstalacion;
        this.codigoProyecto = this.$route.params.codigoProyecto;
        this.obtenerImagenesGaleria();
    },
    methods: {
        ...galeriaImagenesMethods,
        ...proyectoMethods,
        obtenerProyectoUsuario() {
            this.obtenerProyecto(this.$route.params.codigoProyecto)
                .then((res) => {
                    this.proyectoSeleccionado = res.body;
                })
                .catch((error) => {});
        },
        obtenerImagenesGaleria() {
            this.loading = true;
            this.obtenerImagenes({
                codigoLugarInstalacion: this.codigoLugarInstalacion,
                codigoProyecto: this.codigoProyecto,
            })
                .then((res) => {
                    this.imagenes = res.body;
                    this.loading = false;
                })
                .catch((error) => {
                    this.loading = false;
                });
        },

        showImg(index) {
            this.index = index;
            this.visible = true;
        },
        handleHide() {
            this.visible = false;
        },
        showCaptiomImg(index) {
            this.index = index;
            this.visibleCaption = true;
        },
        handleCaptionHide() {
            this.visibleCaption = false;
        },
    },
    computed: {
        ...proyectoComputed,
    },
};
</script>

<style scoped>
.scroll-y{
    height: 650px;
    overflow-y: auto;
}
.square-img {
    width: 100%;
    height: 0px;
    padding-bottom: 90%;
}

.square-img img{
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center center;
    position: absolute;
}

.square-img:after {
  content: "";
  display: block;
  padding-bottom: 100%;
}
.gallery_parent >>> .v-carousel__controls{
    height: auto;
}
.imagen-galeria {
    margin-right: 20px;
    margin-top: 10px;
    margin-bottom: 10px;
}
.gallery_parent >>> .v-window__next{
    right: 0px !important; 
}
</style>
