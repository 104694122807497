<template>
    <div>
      <loading-flux :value="buscando"></loading-flux>
      <div class="row px-3">
        <div class="col-12">
          <div class="">
            <div class="card-body p-0">
              <b-alert
                :show="segundosMensajeActualizacion"
                dismissible
                :variant="mensaje.variant"
                @dismissed="segundosMensajeActualizacion = 0"
                @dismiss-count-down="actualizarSegundosMensajeActualizacion"
              >
                {{ mensaje.texto }}
              </b-alert>
  
              <div class="row align-items-center">
                <div class="col-sm-12 col-md-6 pt-0">
                  <v-text-field
                    v-model="filters.filter"
                    @input="getMaterials(1)"
                    append-icon="mdi-magnify"
                    label="Buscar por Código Producto o Descripción"
                    single-line
                    hide-details
                  ></v-text-field>
                </div>
                <div class="col-sm-12 col-md-6 pt-0">
                  <div class="d-flex align-items-center justify-content-end v-application">
                    <v-checkbox
                        v-model="filters.standard_materials"
                        @click="getMaterials(1)"
                        class="mx-5 mt-4"
                      >
                        <template v-slot:label>
                          <h5 class="mt-3 mx-2 mb-0">
                            Materiales Estandar
                          </h5>
                        </template>
                      </v-checkbox>
                    <h5 class="mb-0 mx-3">
                      Seleccionados: {{ selected.length }}
                    </h5>
                  </div>                
                </div>                
              </div>  
              <v-card-title>
                <v-spacer></v-spacer>
              </v-card-title>
              <v-data-table
                v-model="selected"
                :headers="headers"
                :items="wrappedPaginatedMaterials"
                :single-select="singleSelect"
                item-key="Producto"
                show-select
                class="elevation-1 table-list-materiales"
                @input="seleccionarProducto"
                :items-per-page="10"
                :page.sync="filters.page"
                hide-default-footer
                :loading="loadingAllMaterials"
                loading-text="Cargando Materiales... Espere Por favor"
                @page-count="pageCount = $event"
              >
                <template v-slot:item.favorite="{ item }">
                  <section class="v-application">
                    <v-icon small color="yellow darken-2" v-if="item.favorite">
                      mdi-star
                    </v-icon>
                    <span v-else>
                      -
                    </span>
                  </section>
                </template>
                <template v-slot:item.Descripción="{ item }">
                  <div class="text-left">
                    {{ item.Descripción }}
                  </div>
                </template>
                <template v-slot:item.Costo="{ item }">
                  <span>
                    {{
                      item.Costo
                        ? `$ ${new Intl.NumberFormat('de-DE').format(parseInt(item.Costo))}`
                        : '$0'
                    }}
                  </span>
                </template>
              </v-data-table>
              <div class="text-center pt-2">
                <v-pagination
                  v-model="filters.page"
                  class="v-application"
                  :length="paginatedMaterials.pages ? paginatedMaterials.pages : 1"
                  :total-visible="8"
                ></v-pagination>
              </div>
              <div class="v-application">
                <v-spacer></v-spacer>
                <v-btn
                  :disabled="!checkedLength"
                  type="submit"
                  color="primary"
                  class="ms-1 mt-4"
                  small
                  @click="agregarProductosSeleccionados"
                  >Agregar Seleccionados</v-btn
                >
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </template>
  <script>
  import { validationMixin } from 'vuelidate'
  import { proyectoMethods, proyectoComputed } from '@/state/helpers'
  import { productoMethods, productoComputed } from '@/state/helpers'
  import { lugarInstalacionMethods, lugarInstalacionComputed } from '@/state/helpers'
  import { solicitudMaterialMethods, solicitudMaterialComputed } from '@/state/helpers'
  import Swal from 'sweetalert2'
  import { authMethods, authUsuarioComputed } from '@/state/helpers'
  import { permiso } from '@/helpers/authservice/obtener-permisos'
  import { bodegaMethods, bodegaComputed } from '@/state/helpers'
  import moment from 'moment'
  moment.locale('es')
  import { mapActions, mapGetters } from 'vuex'
  export default {
    mixins: [validationMixin],
    props: {
      solicitudMaterial: Object
    },
    data() {
      const now = new Date()
      const today = new Date(now.getFullYear(), now.getMonth(), now.getDate())
      const minDate = new Date(today)
      return {
        //Get Paginated Materials DATA
        debounce: null,
        debounceTime: 500,
        filters: {
          page: 1,
          filter: '',
          standard_materials: true,
        },
  
        checkedLength: false,
        renderBuscando: true,
        bloquearControles: false,
        renderProductos: true,
        renderComponent: true,
        buscando: false,
        loading: false,
        loadingSolicitud: false,
        mensaje: { variant: '', texto: '' },
        segundos: 10,
        segundosMensajeActualizacion: 0,
        segundosMensajeActualizacionModal: 0,
        lugarInstalacion: {},
        proyectos: [],
        totalRows: 1,
        todoTotalRows: 1,
        currentPage: 1,
        todocurrentPage: 1,
        perPage: 10,
        todoperPage: 10,
        pageOptions: [10, 25, 50, 100],
        todoOptions: [10, 50, 100, 150, 200],
        filter: null,
        todoFilter: null,
        filterOn: [],
        todofilterOn: [],
        sortBy: 'Proyecto',
        sortDesc: false,
  
        singleSelect: false,
        selected: [],
        search: '',
        itemsPerPage: 10,
        footerProps: {
          'items-per-page-options': [10, 15, 30, 50, 100],
          'items-per-page-text': 'Productos por página',
          'show-first-last-page': true
        },
        pagination: {
          rowsPerPage: 30
        },
        headers: [
          {
            text: 'STD',
            align: 'center',
            width: '5%',
            sortable: false,
            value: 'favorite'
          },
          {
            text: 'Stock',
            value: 'Stock',
            width: '10%',
            sortable: true
          },
          {
            text: 'Código',
            value: 'Producto',
            width: '10%',
            sortable: true
          },
          {
            text: 'Descripción',
            width: '50%',
            value: 'Descripción',
            sortable: true
          },
          {
            text: 'Unidad',
            width: '10%',
            value: 'Unidad',
            sortable: true
          },
          {
            text: 'CU',
            width: '10%',
            align: 'start',
            sortable: true,
            value: 'Costo'
          }
        ],
        codigoProyecto: null,
        codigoLugarInstalacion: null,
        guardarCerrar: true,
        solicitudSeleccionada: {},
        solicitudMateriales: [],
        solicitudMaterialesDetalle: [],
        solicitudMaterialDetalles: [],
        solicitudMaterialDetallesCargando: false,
        esEditar: false,
        submitted: false,
        mostrarModal: false,
        mostrarModalProductos: false,
        productos: [],
        productosCargando: false,
        solicitudMaterialesCargando: false,
        unidades: [
          { value: 'un', label: 'un' },
          { value: 'm', label: 'm' },
          { value: 'm3', label: 'm3' },
          { value: 'kg', label: 'kg' },
          { value: 'ton', label: 'ton' },
          { value: 'día', label: 'día' }
        ],
        tipoSolicitudes: [
          { value: 'Solicitud', label: 'Solicitud' },
          { value: 'Reserva', label: 'Reserva' },
          { value: 'Recepcion', label: 'Recepcion' },
          { value: 'Consumo', label: 'Consumo' },
          { value: 'ValeConsumo', label: 'ValeConsumo' },
          { value: 'Devolucion', label: 'Devolucion' }
        ],
        fechaValidar: null,
        fechaTerminoValidar: null,
        formProducto: {
          codigoProducto: null,
          nombreProducto: null
        },
        mostrarModalDetalle: false,
        tipoSolicitud: null,
        rolModuloPermiso: {},
        bodegas: [],
        bodegasCargando: false,
        tipoBodegas: [
          { value: 'Central', label: 'Central' },
          { value: 'Movil', label: 'Movil' }
        ],
        mostrarBodegaMovil: false,
        mostrarProductos: false,
        productosFiltrados: [],
        cargandoProductos: false,
        maneraCrearSolicitud: null,
        min: minDate,
        proyectoSeleccionado: {},
        nombreProducto: null,
        productosSeleccionados: [],
        noAgregar: false,
        cantidadMaximaProductos: 200
      }
    },
    validations: {
      formProducto: {
        codigoProducto: {},
        nombreProducto: {}
      }
    },
    async mounted() {
      if (this.proyectoUsuario == null) {
        this.obtenerProyectoUsuario()
      } else {
        this.proyectoSeleccionado = this.proyectoUsuario
      }
      this.codigoProyecto = this.$route.params.codigoProyecto
      this.codigoLugarInstalacion = this.$route.params.codigoLugarInstalacion
      this.lugarInstalacion = {
        codigo_lugar_instalacion: this.codigoLugarInstalacion,
        codigo_proyecto: this.codigoProyecto
      }
  
      let permisos = permiso.obtenerPermisos(this.user, this.$router.currentRoute.name)
      this.rolModuloPermiso = permisos
      this.obtenerLugarInstalacionLocal()
  
      // this.obtenerStockProductosLocal()
  
      await this.getMaterials(1)
      this.mostrarProductos = false
      this.$nextTick(() => {
        this.mostrarProductos = true
      })
  
      if (this.esEditar) {
        this.$v.form.producto.$model = {
          producto_id: this.solicitudSeleccionada.producto_id,
          nombre_producto: this.solicitudSeleccionada.nombre_producto
        }
      }
    },
    methods: {
      ...solicitudMaterialMethods,
      ...productoMethods,
      ...proyectoMethods,
      ...bodegaMethods,
      ...lugarInstalacionMethods,
      ...mapActions({
        getAllMaterials: 'cubage/getAllMaterials',
        getAllMaterialsFromWarehouse: 'cubage/getAllMaterialsFromWarehouse'
      }),
      async getMaterials(page = 1) {
        clearTimeout(this.debounce)
        let self = this
        this.debounce = setTimeout(async function() {
          self.filters.page = page
          if (self.solicitudMaterial.tipo_bodega === 'Central') {
            await self.getAllMaterials({ ...self.filters })
          } else {
            await self.getAllMaterialsFromWarehouse({
              ...self.filters,
              warehouse: self.solicitudMaterial.codigo_bodega,
              filter: undefined
            })
          }
        }, self.debounceTime)
      },
      agregarProductosSeleccionados() {
        if (this.productosSeleccionados.length > this.cantidadMaximaProductos) {
          this.mensaje.variant = 'danger'
          this.mensaje.texto = `Solo puede seleccionar hasta ${this.cantidadMaximaProductos} productos`
          this.segundosMensajeActualizacion = this.segundos
          return
        }
        this.$emit('agregarProductosSeleccionados', this.productosSeleccionados)
      },
      seleccionarProducto(producto) {
        if (this.selected.length > this.cantidadMaximaProductos) {
          this.mensaje.variant = 'danger'
          this.mensaje.texto = `Solo puede seleccionar hasta ${this.cantidadMaximaProductos} productos`
          this.segundosMensajeActualizacion = this.segundos
        }
  
        this.selected.forEach((element) => {
          element.cantidad = null
          element.comentario = null
        })
        this.productosSeleccionados = this.selected
        if (this.selected.length > 0) {
          this.checkedLength = true
        } else {
          this.checkedLength = false
        }
      },
      buscar() {
        this.buscando = true
  
        this.filter = this.nombreProducto
      },
      abrirModalProductos() {
        this.mostrarModalProductos = true
      },
  
      obtenerProyectoUsuario() {
        this.obtenerProyecto(this.$route.params.codigoProyecto)
          .then((res) => {
            this.proyectoSeleccionado = res.body
          })
          .catch((error) => {})
      },
      obtenerLugarInstalacionLocal() {
        this.obtenerLugarInstalacion({
          codigoProyecto: this.codigoProyecto,
          codigoLugarInstalacion: this.codigoLugarInstalacion
        })
          .then((res) => {
            this.lugarInstalacion = res.body
          })
          .catch((error) => {})
      },
      descripcionProducto(producto) {
        return `${producto.Producto} - (${producto.Stock}) - ${producto.Descripción}`
      },
      actualizarSegundosMensajeActualizacion(segundosMensajeActualizacion) {
        this.segundosMensajeActualizacion = segundosMensajeActualizacion
      },
      actualizarSegundosMensajeActualizacionModal(segundosMensajeActualizacionModal) {
        this.segundosMensajeActualizacionModal = segundosMensajeActualizacionModal
      },
      abrirModalNuevo(tipoSolicitud, maneraCrearSolicitud) {
        this.bloquearControles = false
        this.solicitudMaterial.productos = []
        this.tipoSolicitud = tipoSolicitud
        this.resetForm()
        this.esEditar = false
        this.submitted = false
        this.mostrarModal = true
        this.fechaValidar = null
        this.maneraCrearSolicitud = maneraCrearSolicitud
  
        this.mostrarBodegaMovil = false
        this.mostrarProductos = false
      },
      abrirModalDetalle(item) {
        this.solicitudSeleccionada = item
  
        this.mostrarModalDetalle = true
      },
      obtenerStockProductosLocal() {
        this.productosCargando = true
        this.obtenerStockProductos()
          .then((res) => {
            this.productos = res.body
            let i = 0
            this.productos.forEach((element) => {
              // element.selected = false;
              element.id = i++
            })
            this.productosFiltrados = this.productos
            let filtro = this.solicitudMaterial.codigo_bodega
            if (this.solicitudMaterial.tipo_bodega == 'Central') {
              this.productosFiltrados = this.productos.filter((c) => c.Proyecto == 'Bodega')
            } else {
              this.productosFiltrados = this.productos.filter(
                (c) => c.Bodega.toLowerCase() == filtro.toLowerCase()
              )
            }
  
            this.mostrarProductos = false
            this.$nextTick(() => {
              this.mostrarProductos = true
            })
  
            if (this.esEditar) {
              this.$v.form.producto.$model = {
                producto_id: this.solicitudSeleccionada.producto_id,
                nombre_producto: this.solicitudSeleccionada.nombre_producto
              }
            }
            this.productosCargando = false
          })
          .catch((error) => {
            this.productosCargando = false
          })
      },
      obtenerSolicitudMaterialesProyecto() {
        this.solicitudMaterialesCargando = true
        this.obtenerSolicitudMaterialesProyectoLugarInstalacion({
          codigoLugarInstalacion: this.codigoLugarInstalacion,
          codigoProyecto: this.codigoProyecto
        })
          .then((res) => {
            this.solicitudMateriales = res.body
            this.solicitudMaterialesCargando = false
          })
          .catch((error) => {
            this.solicitudMaterialesCargando = false
          })
      },
  
      forceRerender() {
        this.renderComponent = false
        this.$nextTick(() => {
          this.renderComponent = true
        })
      },
  
      resetForm() {
        this.form = {
          fechaEntrega: null,
          comentario: null,
          bodega: null,
          tipoBodega: null
        }
  
        this.$nextTick(() => {
          this.$v.$reset()
        })
      },
      resetFormProducto() {
        this.formProducto = {
          producto: null,
          cantidadSolicitada: null,
          comentario: null,
          unidad: null
        }
  
        this.$nextTick(() => {
          this.$v.$reset()
        })
      },
      resetFormAdd() {
        this.form = {
          producto: null,
          cantidadSolicitada: null,
          comentario: null,
          unidad: null
        }
      },
      resetFormProductoAdd() {
        this.formProductoAdd = {
          producto: null,
          cantidadSolicitada: null,
          comentario: null,
          unidad: null
        }
  
        this.$nextTick(() => {
          this.$v.$reset()
        })
      },
      eliminarActividadProyectoLocal(item) {
        const mensajeEliminar = Swal.mixin({
          customClass: {
            title: 'font-size-18',
            confirmButton: 'btn btn-success me-2',
            cancelButton: 'btn btn-danger'
          },
          buttonsStyling: false
        })
        mensajeEliminar
          .fire({
            title: 'Esta seguro que desea eliminar el registro?',
            text: '',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Si, Eliminar',
            cancelButtonText: 'Cancelar',
            allowOutsideClick: false
          })
          .then((result) => {
            if (result.value) {
              this.eliminarActividadProyecto(item.solicitud_material_id)
                .then((res) => {
                  if (res.status == 202) {
                    this.mensaje.variant = 'danger'
                    this.mensaje.texto = 'Ha ocurrido un error, intente nuevamente'
                  }
  
                  if (res.status == 204) {
                    this.mensaje.variant = 'success'
                    this.mensaje.texto = 'Solicitud eliminada correctamente!!!'
                    if (this.guardarCerrar) {
                      this.mostrarModal = false
                    }
                  }
                  this.segundosMensajeActualizacion = this.segundos
                  this.obtenerSolicitudMaterialesProyecto()
                })
                .catch((error) => {
                  this.mensaje.variant = 'danger'
                  this.mensaje.texto = 'No se ha eliminado el registro, intente nuevamente'
                  this.mostrarModal = false
                  this.segundosMensajeActualizacion = this.segundos
                })
            }
          })
      },
      colorTipoSolicitud(item) {
        if (item == 'Solicitud') {
          return 'bg-success'
        } else if (item == 'Reserva') {
          return 'bg-primary'
        } else if (item == 'Recepcionada') {
          return 'bg-warning'
        } else if (item == 'Consumida') {
          return 'bg-info'
        } else if (item == 'ValeConsumo') {
          return 'bg-info'
        } else if (item == 'Devolucion') {
          return 'bg-danger'
        } else if (item == 'Central') {
          return 'bg-success'
        } else if (item == 'Movil') {
          return 'bg-primary'
        } else if (item == 'Abierta') {
          return 'bg-success'
        } else if (item == 'Cerrada') {
          return 'bg-danger'
        }
      },
      irLugarinstalacion(proyecto) {
        this.seleccionarProyecto(proyecto[0])
        this.$router.push({
          name: 'lugaresInstalacion',
          params: { codigoProyecto: proyecto[0].codigo_proyecto }
        })
      },
      formatDate(value) {
        const duration = moment(value).format('DD/MM/yyyy')
        return `${duration}`
      },
      onFiltered(filteredItems) {
        this.buscando = false
        this.totalRows = filteredItems.length
        this.currentPage = 1
      },
  
      todoFiltered(filteredItems) {
        this.todoTotalRows = filteredItems.length
        this.todocurrentPage = 1
      },
  
      handleInput(value, data) {
        this.editableDataItems[data.index][data.field.key] = value
      },
  
      tbodyRowClass(item) {
        /* Style the row as needed */
        if (item.selected) {
          return ['b-table-row-selected', 'table-primary', 'cursor-pointer']
        } else {
          return ['cursor-pointer']
        }
      },
      rowClicked(item) {
        if (item.selected) {
          this.$set(item, 'selected', false)
        } else {
          this.$set(item, 'selected', true)
        }
      }
    },
    computed: {
      ...proyectoComputed,
      ...authUsuarioComputed,
      ...mapGetters({
        paginatedMaterials: 'cubage/paginatedMaterials',
        loadingAllMaterials: 'cubage/loading'
      }),
      wrappedPaginatedMaterials() {
        return this.paginatedMaterials.materials
          ? this.paginatedMaterials.materials.map((item) => {
              if (this.solicitudMaterial.tipo_bodega === 'Central') {
                return {
                  ...item,
                  Producto: item.code,
                  Descripción: item.description,
                  Stock: item.stock,
                  Unidad: item.measurement ? item.measurement.units : 'N/A',
                  Costo: item.cost
                }
              }
              return {
                ...item,
                Producto: item.CodProducto,
                Descripción: item.DescProducto,
                Stock: item.Stock,
                Unidad: item.Unidad,
                Costo: item.Precio
              }
            })
          : []
      },
      selectedRows() {
        return this.items.filter((item) => item.selected)
      }
    },
    watch: {
      'filters.page': function(page) {
        this.getMaterials(page)
      }
    }
  }
  </script>
  
  <style scoped>
  .table-list-materiales >>> th {
    white-space: nowrap !important;
  }
  </style>
  
  <style>
  .crearActividadesProyecto {
    margin-right: 10px;
  }
  
  .table-solicitud-material-detalle {
    height: 300px;
  }
  
  .botones-menu {
    width: 200px;
    margin-right: 10px;
    display: inline-block;
  }
  .subtitulo {
    margin-top: 15px;
  }
  @media (max-width: 576px) {
    .botones-menu {
      width: 100%;
      margin-right: 0px;
      margin: 3px;
    }
  }
  
  .v-data-footer__select > div {
    margin-left: 10px;
    margin-right: 10px;
  }
  
  .v-data-table__checkbox .theme--light.v-icon {
    color: rgb(85, 110, 230);
  }
  .v-data-footer__select {
    display: none;
  }
  </style>
  