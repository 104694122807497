<template>
  <section>
    <loading-flux :value="loading"></loading-flux>
    <v-dialog
      v-model="modalAction"
      max-width="600px"
      @keydown="closeModalConfirmRequestReturnMaterial"
      @click:outside="closeModalConfirmRequestReturnMaterial"
    >
      <v-card>
        <v-card-title>
          <span class="text-h6">Lugar para devolución de materiales</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-form>
              <v-col cols="12" md="12" sm="12">
                <v-autocomplete
                  v-model="$v.form.return_type.$model"
                  :error="$v.form.return_type.$invalid && submitUpload"
                  :items="PLACES_RETURN_MATERIALS"
                  label="Lugar para devolución"
                  item-text="name"
                  item-value="id"
                  :disabled="true"
                ></v-autocomplete>
              </v-col>
              <v-col
                cols="12"
                md="12"
                sm="12"
                v-if="$v.form.return_type.$model == PLACE_RETURN_PROJECT_ID"
              >
                <v-autocomplete
                  v-model="$v.form.destination_code.$model"
                  :error="$v.form.destination_code.$invalid && submitUpload"
                  :items="projects"
                  label="Proyecto"
                  item-text="custom_code"
                  item-value="code"
                ></v-autocomplete>
              </v-col>
            </v-form>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            :disabled="loading"
            color="red darken-1"
            outlined
            text
            @click="closeModalConfirmRequestReturnMaterial"
          >
            <small>
              Cancelar
            </small>
          </v-btn>
          <v-btn
            color="primary"
            :disabled="loading"
            :loading="loading"
            @click="handleSubmit"
          >
            <small class="text-white">
              Aceptar
            </small>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <div id="listMaterialsSelected" style="display: none">
      <list-materials-selected
        :materials="materialsSelected"
      ></list-materials-selected>
    </div>
  </section>
</template>

<script>
import { required } from "vuelidate/lib/validators";
import { mapActions, mapGetters } from "vuex";
import { authUsuarioComputed } from "@/state/helpers";
import swal2 from "sweetalert2";
import {
  PLACES_RETURN_MATERIALS,
  PLACE_RETURN_PROJECT_ID,
} from "@/constants/requestMaterial";
import ListMaterialsSelected from "./ListMaterialsSelected.vue";
export default {
  components: {
    ListMaterialsSelected,
  },
  props: {
    modalAction: {
      type: Boolean,
      default: false,
    },
    closeModalConfirmRequestReturnMaterial: {
      type: Function,
      required: true,
      default: () => {},
    },
  },
  mounted() {
    this.getAllProjects();
  },
  validations: {
    form: {
      destination_code: {},
      return_type: { required },
    },
  },
  data() {
    return {
      PLACES_RETURN_MATERIALS,
      PLACE_RETURN_PROJECT_ID,
      loading: false,
      submitUpload: false,
      form: {
        project_code: null,
        sap_stage: null,
        destination_code: null,
        return_type: PLACES_RETURN_MATERIALS[0].id,
      },
      materialsSelected: [],
      codeProject: this.$route.query.sap_project
        ? this.$route.query.sap_project
        : null,
    };
  },
  methods: {
    ...mapActions({
      getAllProjects: "project/getAllProjects",
      sendRequestReturnMaterial: "returnMaterial/sendRequestReturnMaterial",
    }),
    setMaterialsSelected(materials) {
      this.materialsSelected = materials;
    },
    setFormStageAndProjectCode(values) {
      this.form.sap_stage = values.sap_stage;
      this.form.project_code = values.project_code;
    },
    resetForm() {
      this.form = {
        project_code: null,
        sap_stage: null,
        destination_code: null,
        return_type: PLACES_RETURN_MATERIALS[0].id,
      };
      this.materialsSelected = [];
      this.submitUpload = false;
    },
    async handleSubmit() {
      let self = this;
      let listMaterialsSelectedEl = document.getElementById(
        "listMaterialsSelected"
      );
      this.$swal
        .fire({
          icon: "warning",
          title: `¿Estás seguro de crear la solicitud de devolución con los siguientes materiales?`,
          html: listMaterialsSelectedEl.innerHTML,
          showCancelButton: true,
          showConfirmButton: true,
          confirmButtonText: "Aceptar",
          cancelButtonText: `Cancelar`,
        })
        .then(async (result) => {
          if (result.isConfirmed) {
            self.submitUpload = true;
            let projectCode = self.form.project_code
              ? self.form.project_code
              : self.codeProject;
            if (!self.$v.form.$invalid || !self.materialsSelected.length) {
              self.loading = true;
              const resp = await self.sendRequestReturnMaterial({
                ...self.form,
                material_list: self.materialsSelected,
                project_code: projectCode,
                sap_stage: self.form.sap_stage,
                destination_code:
                  self.$v.form.return_type.$model == PLACE_RETURN_PROJECT_ID
                    ? self.form.destination_code
                    : null,
                user: "leoramirez322@gmail.com", //self.user.email,
                is_sap: true,
              });
              if (resp.status === 500) {
                swal2.fire({
                  icon: "error",
                  title: "Error",
                  text:
                    "Ha ocurrido un error en el servidor, por favor inténtalo nuevamente",
                  position: "top-end",
                  showConfirmButton: false,
                  toast: true,
                  timer: 5000,
                  timerProgressBar: true,
                });
              }
              if (resp.status === 200) {
                swal2.fire({
                  icon: "success",
                  title: "Excelente",
                  text: "Solicitud de devolución creada correctamente",
                  position: "top-end",
                  showConfirmButton: false,
                  toast: true,
                  timer: 5000,
                  timerProgressBar: true,
                });
                self.$emit("requestReturnMaterialCreated", {
                  project: projectCode,
                  sap_stage: self.form.sap_stage,
                });
                self.closeModalConfirmRequestReturnMaterial();
                self.resetForm();
              }
            }
            self.loading = false;
          }
        });
    },
  },
  computed: {
    ...authUsuarioComputed,
    ...mapGetters({
      projects: "project/projects",
    }),
  },
};
</script>

<style></style>
