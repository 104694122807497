var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('main',{staticClass:"px-3"},[_c('v-row',{staticClass:"mt-3",attrs:{"align-content":"center","align-md":"center"}},[_c('v-col',{staticClass:"py-1",attrs:{"cols":"12","md":"3","sm":"12"}},[_c('v-text-field',{attrs:{"label":"Busqueda","clearable":"","append-icon":"mdi-magnify"},on:{"input":function($event){return _vm.getRequestsConsumptionMaterialsData(1)}},model:{value:(_vm.filters.filter),callback:function ($$v) {_vm.$set(_vm.filters, "filter", $$v)},expression:"filters.filter"}})],1),_c('v-col',{staticClass:"mb-2",attrs:{"cols":"12","md":"3","sm":"12"}},[_c('v-autocomplete',{staticClass:"mt-3 ml-5",attrs:{"items":[5, 10, 15, 20, 25, 'Todos'],"label":"Items por página"},on:{"change":function ($event) { return _vm.getRequestsConsumptionMaterialsData(1); }},model:{value:(_vm.filters.items),callback:function ($$v) {_vm.$set(_vm.filters, "items", $$v)},expression:"filters.items"}})],1)],1),_c('v-data-table',{attrs:{"headers":_vm.tableHeaders,"items":_vm.requestsConsumptionMaterials.consume_requests
        ? _vm.requestsConsumptionMaterials.consume_requests
        : [],"page":_vm.filters.page,"items-per-page":_vm.filters.items !== 'Todos' ? _vm.filters.items : 100,"item-key":"id","show-select":false,"loading":_vm.loading,"hide-default-footer":"","no-data-text":"Sin solicitudes actualmente"},on:{"update:page":function($event){return _vm.$set(_vm.filters, "page", $event)}},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":""}},[_c('v-divider',{staticClass:"mx-4",attrs:{"inset":"","vertical":""}}),_c('v-spacer'),_c('v-btn',{attrs:{"color":"blue darken-3","dark":false,"small":""},on:{"click":_vm.openModalAddRequestConsumptionMaterials}},[_c('span',{staticClass:"text-white"},[_vm._v(" Crear solicitud de consumo ")])])],1)]},proxy:true},{key:"item.actions",fn:function(ref){
        var item = ref.item;
return [_c('div',{staticClass:"d-flex align-items-center justify-content-center"},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
        var on = ref.on;
        var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","color":"primary"},on:{"click":function($event){return _vm.openModalDetailsRequestConsumptionMaterials(item)}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"small":""}},[_vm._v(" mdi-eye ")])],1)]}}],null,true)},[_c('span',[_vm._v("Ver detalles")])])],1)]}},{key:"item.created_at",fn:function(ref){
        var item = ref.item;
return [_c('p',{staticClass:"mt-2"},[_vm._v(" "+_vm._s(new Date(item.created_at).toLocaleDateString())+" ")])]}},{key:"item.status",fn:function(ref){
        var item = ref.item;
return [_c('p',{staticClass:"mt-2"},[_vm._v(" "+_vm._s("Aceptada")+" ")])]}}])}),_c('div',{staticClass:"text-center pt-2"},[_c('v-pagination',{attrs:{"total-visible":"8","length":_vm.requestsConsumptionMaterials.number_of_pages
          ? _vm.requestsConsumptionMaterials.number_of_pages
          : 1},model:{value:(_vm.filters.page),callback:function ($$v) {_vm.$set(_vm.filters, "page", $$v)},expression:"filters.page"}})],1),_c('create-request-consumption-material',{attrs:{"modalAction":_vm.dialogRequestConsumptionMaterials,"closeModalRequestConsumptionMaterials":_vm.closeModalRequestConsumptionMaterials},on:{"requestConsumptionMaterialCreated":function($event){return _vm.getRequestsConsumptionMaterialsData(1)}}}),_c('details-request-consumption-materials',{ref:"detailsConsumptionMaterials",attrs:{"currentRequestConsumption":_vm.currentRequestConsumption,"modalAction":_vm.dialogDetailsRequestConsumptionMaterials,"closeModalDetailsRequestConsumptionMaterials":_vm.closeModalDetailsRequestConsumptionMaterials}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }