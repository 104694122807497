<template>
    <div class="">
        
        <loading-flux :value="loading"></loading-flux>
        
        <b-alert
            :show="segundosMensajeActualizacion"
            dismissible
            :variant="mensaje.variant"
            @dismissed="segundosMensajeActualizacion = 0"
            @dismiss-count-down="actualizarSegundosMensajeActualizacion"
        >
            {{ mensaje.texto }}
        </b-alert>
        <div class="d-flex">
            <div class="card-title col-auto me-auto">
                Usuarios
            </div>
            <button class="btn btn-primary col-auto" @click="crearContactos">
                Agregar
            </button>
        </div>
        <div class="row mt-4">
            <div class="col-sm-12 col-md-6">
                <v-text-field
                    v-model="search"
                    append-icon="mdi-magnify"
                    label="Buscar Usuario"
                    single-line
                    hide-details
                ></v-text-field>
            </div>
        </div>
        <v-card-title>
            <v-spacer></v-spacer>
        </v-card-title>
        <v-data-table
            v-model="selected"
            :headers="headers"
            :items="usuarios"
            :single-select="singleSelect"
            item-key="id"
            show-select
            class="elevation-1"
            mobile-breakpoint="0"
            :search="search"
            @input="seleccionarUsuario"
            :footer-props="footerProps"
            :loading="usuariosCargando"
            loading-text="Cargando Usuarios... Espere Por favor"
        >
        </v-data-table>
    </div>
</template>

<script>
import { usuarioMethods, usuarioComputed } from "@/state/helpers";
import { contactoMethods, contactoComputed } from "@/state/helpers";
export default {
    props: { lugarInstalacion: {} },
    data() {
        return {
            loading: false,
            mensaje: { variant: "", texto: "" },
            usuarios: [],
            usuariosCargando: false,
            singleSelect: false,
            selected: [],
            search: "",
            itemsPerPage: 10,
            footerProps: {
                "items-per-page-options": [10, 15, 30, 50, 100],
                "items-per-page-text": "Productos por página",
                "show-first-last-page": true,
            },
            headers: [
                {
                    text: "Código",
                    value: "codigo_usuario",
                    width: "250px",
                    sortable: true,
                },
                {
                    text: "Nombre",
                    value: "nombre_usuario",
                    width: "350px",
                    sortable: true,
                },
                {
                    text: "Email",
                    value: "email",
                    width: "350px",
                    sortable: true,
                },
                {
                    text: "Tipo Usuario",
                    value: "tipo_usuario",
                    width: "200px",
                    sortable: true,
                },
            ],
            segundos: 5,
            segundosMensajeActualizacion: 0,
            usuariosSeleccionados: {},
            usuariosSeleccionadosNuevos: [],
        };
    },
    mounted() {
        this.obtenerUsuariosLocal();
    },
    methods: {
        ...usuarioMethods,
        ...contactoMethods,
        seleccionarUsuario(usuario) {
            this.usuariosSeleccionados.contactos = this.selected;
        },
        actualizarSegundosMensajeActualizacion(segundosMensajeActualizacion) {
            this.segundosMensajeActualizacion = segundosMensajeActualizacion;
        },
        // seleccionarUsuario(usuarioSeleccionado) {
        //     this.usuariosSeleccionados.contactos = usuarioSeleccionado;
        // },
        obtenerUsuariosLocal() {
            this.usuariosCargando = true;
            this.obtenerUsuarios()
                .then((res) => {
                    this.usuarios = res.body;
                    this.usuariosCargando = false;
                })
                .catch((error) => {
                    this.usuariosCargando = false;
                });
        },
        crearContactos() {
            this.loading = true;
            this.submitted = true;
            this.usuariosSeleccionados.lugar_instalacion = this.lugarInstalacion;
            this.crearContactosMasivo(this.usuariosSeleccionados)
                .then((res) => {
                    if (res.status == 201) {
                        this.mensaje.variant = "success";
                        this.mensaje.texto =
                            "Usuarios creados correctamente!!!";

                        this.mostrarModal = false;
                    }

                    if (res.status == 208) {
                        this.mensaje.variant = "danger";
                        this.mensaje.texto = "Algunos usuarios ya existen!!!";
                    }

                    this.segundosMensajeActualizacion = this.segundos;
                    this.loading = false;
                    this.$emit("contactoCreado");
                })
                .catch((error) => {
                    this.mensaje.variant = "danger";
                    this.mensaje.texto =
                        "Ha ocurrido un error, intente nuevamente";
                    this.mostrarModal = false;
                    this.segundosMensajeActualizacion = this.segundos;
                    this.loading = false;
                });
        },
    },
};
</script>

<style>
.v-data-footer__select > div {
    margin-left: 10px;
    margin-right: 10px;
}

.v-data-table__checkbox .theme--light.v-icon {
    color: rgb(85, 110, 230);
}
.v-data-footer__select {
    display: none;
}
</style>
