<template>
    <div class="card text-center">
        <b-alert
            class="position-fixed fixed-top m-0 rounded-0"
            style="z-index: 2000;"
            :show="segundosMensajeActualizacion"
            dismissible
            :variant="mensaje.variant"
            @dismissed="segundosMensajeActualizacion = 0"
            @dismiss-count-down="actualizarSegundosMensajeActualizacion"
        >
            {{ mensaje.texto }}
        </b-alert>
        <div class="card-body">
            <div v-if="!usuario.imagen_perfil" class="avatar-sm mx-auto mb-4">
                <span
                    class="avatar-title rounded-circle bg-soft bg-primary text-primary font-size-16"
                    >{{ usuario.nombre_usuario.charAt(0) }}</span
                >
            </div>
            <div v-if="usuario.imagen_perfil" class="mb-4">
                <img
                    class="rounded-circle avatar-sm"
                    :src="urlImagenUsuario(usuario.imagen_perfil)"
                    alt
                />
            </div>
            <h5 class="font-size-15 mb-1">
                <a href="javascript: void(0);" class="text-dark">{{
                    usuario.nombre_usuario
                }}</a>
            </h5>
            <p class="text-muted">{{ usuario.tipo_usuario }}</p>
            <p class="text-muted">{{ usuario.nombre_cargo }}</p>
            <div>
                <a
                    href="javascript: void(0);"
                    class="badge bg-primary font-size-11 m-1"
                    >{{ usuario.email }}</a
                >
            </div>
        </div>
        <div class="card-footer bg-transparent border-top" v-if="rolModuloPermiso.escritura">
            <div class="contact-links d-flex font-size-20">
                <!--
                <div class="flex-fill">
                    <a
                        v-b-tooltip.hover.top
                        title="Editar"
                        href="javascript: void(0);"
                        @click="abrirModalEditar"
                    >
                        <i class="bx bx-edit"></i>
                    </a>
                </div>
                -->
                <div class="flex-fill">
                    <a
                        v-b-tooltip.hover.top
                        title="Eliminar"
                        href="javascript: void(0);"
                        @click="eliminarContactoProyecto"
                    >
                        <i class="bx bx-trash"></i>
                    </a>
                </div>
            </div>
        </div>

        <b-modal
            v-model="mostrarModal"
            title="Actualizar Datos Generales"
            hide-footer
            size="lg"
        >
            <form @submit.prevent="submit">
                <div class="row">
                    <div class="col-lg-6">
                        <div class="mb-3">
                            <label for="fono">Fono</label>
                            <input
                                id="fono"
                                v-model="$v.form.fono.$model"
                                type="text"
                                class="form-control"
                                placeholder="Fono"
                                :class="{
                                    'is-invalid':
                                        submitted && $v.form.fono.$error,
                                }"
                            />
                            <div
                                v-if="submitted && !$v.form.fono.required"
                                class="invalid-feedback"
                            >
                                El campo es obligatorio.
                            </div>
                        </div>
                    </div>

                    <div class="col-lg-6">
                        <div class="mb-3">
                            <label for="tipoUsuario">Tipo Usuario</label>
                            <multiselect
                                v-model="$v.form.tipoUsuario.$model"
                                :options="tipoUsuarios"
                                placeholder="Seleccionar Tipo Usuario"
                                value="value"
                                label="label"
                                :searchable="true"
                                class="helo"
                                :class="{
                                    'is-invalid':
                                        submitted && $v.form.tipoUsuario.$error,
                                }"
                            ></multiselect>
                            <div
                                v-if="
                                    submitted && !$v.form.tipoUsuario.required
                                "
                                class="invalid-feedback"
                            >
                                El campo es obligatorio.
                            </div>
                        </div>
                    </div>

                    <div class="col-lg-6">
                        <div class="mb-3">
                            <label for="cargo">Cargo</label>
                            <multiselect
                                v-model="$v.form.cargo.$model"
                                :options="cargos"
                                placeholder="Seleccionar Cargo"
                                value="cargo_id"
                                label="nombre_cargo"
                                :searchable="true"
                                :loading="cargosCargando"
                                class="helo"
                                :class="{
                                    'is-invalid':
                                        submitted && $v.form.cargo.$error,
                                }"
                            ></multiselect>
                            <div
                                v-if="submitted && !$v.form.cargo.required"
                                class="invalid-feedback"
                            >
                                El campo es obligatorio.
                            </div>
                        </div>
                    </div>
                </div>
                <div class="text-end mt-3">
                    <b-button variant="light" @click="mostrarModal = false"
                        >Cerrar</b-button
                    >
                    <b-button type="submit" variant="success" class="ms-1"
                        >Actualizar</b-button
                    >
                </div>
            </form>
        </b-modal>
    </div>
</template>

<script>
import Swal from "sweetalert2";
import { contactoMethods, contactoComputed } from "@/state/helpers";
import { usuarioMethods, usuarioComputed } from "@/state/helpers";
import { cargoMethods, cargoComputed } from "@/state/helpers";
import { required } from "vuelidate/lib/validators";
import { validationMixin } from "vuelidate";
import Multiselect from "vue-multiselect";
import {authMethods,authUsuarioComputed} from "@/state/helpers";
import {permiso} from "@/helpers/authservice/obtener-permisos";
export default {
    mixins: [validationMixin],
    props: {
        usuario: Object,
        lugarInstalacion: Object,
    },
    components: {
        Multiselect,
    },
    data() {
        return {
            contactoSeleccionado: {},
            esEditar: false,
            submitted: false,
            mostrarModal: false,
            mensaje: { variant: "", texto: "" },
            showBottom: false,
            segundos: 10,
            segundosMensajeActualizacion: 0,
            cargos: [],
            cargosCargando: false,
            form: {
                fono: null,
                cargo: null,
                tipoUsuario: null,
            },
            tipoUsuarios: [
                { value: "CLIENTE", label: "CLIENTE" },
                { value: "FLUXSOLAR", label: "FLUXSOLAR" },
            ],
            rolModuloPermiso:{}
        };
    },
    validations: {
        form: {
            fono: {  },
            cargo: { required },
            tipoUsuario: { required },
        },
    },
    mounted(){
        let permisos = permiso.obtenerPermisos(this.user,this.$router.currentRoute.name)
        this.rolModuloPermiso = permisos
    },
    methods: {
        ...contactoMethods,
        ...usuarioMethods,
        ...cargoMethods,
        urlImagenUsuario(imagen_perfil){
            return `${process.env.VUE_APP_URL_IMAGENES_USUARIOS}${imagen_perfil}`
        },
        actualizarSegundosMensajeActualizacion(segundosMensajeActualizacion) {
            this.segundosMensajeActualizacion = segundosMensajeActualizacion;
        },
        obtenerCargosLocal() {
            this.obtenerCargos()
                .then((res) => {
                    this.cargos = res.body;
                    if(this.usuario.cargo_id !=null){
                        this.$v.form.cargo.$model = {
                            cargo_id: this.usuario.cargo_id,
                            nombre_cargo: this.usuario.nombre_cargo,
                        };
                    }
                })
                .catch((error) => {});
        },
        abrirModalEditar() {
            this.resetForm();
            this.obtenerCargosLocal();
            this.$v.form.fono.$model = this.usuario.fono1;
            if(this.usuario.tipo_usuario !=null){
                this.$v.form.tipoUsuario.$model = {
                    value: this.usuario.tipo_usuario,
                    label: this.usuario.tipo_usuario,
                };
            }

            this.esEditar = true;
            this.submitted = false;
            this.mostrarModal = true;
        },
        eliminarContactoProyecto() {
            const mensajeEliminar = Swal.mixin({
                customClass: {
                    title: "font-size-18",
                    confirmButton: "btn btn-danger ms-2",
                    cancelButton: "btn btn-gris-claro",
                },
                buttonsStyling: false,
            });
            mensajeEliminar
                .fire({
                    title: "Esta seguro que desea eliminar el registro?",
                    text: "",
                    icon: "warning",
                    showCancelButton: true,
                    confirmButtonText: "Eliminar",
                    cancelButtonText: "Cancelar",
                    allowOutsideClick: false,
                    reverseButtons: true
                })
                .then((result) => {
                    if (result.value) {
                       
                        let contacto_id = this.usuario.contacto_id;
                        this.eliminarContacto(contacto_id)
                            .then((res) => {
                                if (res.status == 202) {
                                    this.mensaje.variant = "danger";
                                    this.mensaje.texto =
                                        "Ha ocurrido un error, intente nuevamente";
                                }

                                if (res.status == 204) {
                                    this.mensaje.variant = "success";
                                    this.mensaje.texto =
                                        "Contacto eliminado correctamente!!!";
                                    this.$emit("contactoEliminado");
                                }
                                this.segundosMensajeActualizacion = this.segundos;
                            })
                            .catch((error) => {
                                this.mensaje.variant = "danger";
                                this.mensaje.texto =
                                    "No se ha eliminado el registro, intente nuevamente";
                                this.mostrarModal = false;
                                this.segundosMensajeActualizacion = this.segundos;
                            });
                    }
                });
        },
        submit(e) {
          
            this.submitted = true;

            this.$v.form.$touch();
            
            if (this.$v.form.$invalid) {
                return;
            }
            
            const email = this.usuario.email;
            const token_usuario = this.usuario.token_usuario;
            const fono1 = this.$v.form.fono.$model;
            const cargo_id = this.$v.form.cargo.$model.cargo_id;
            const nombre_cargo = this.$v.form.cargo.$model.nombre_cargo;
            const tipo_usuario = this.$v.form.tipoUsuario.$model.value;
 
            if (this.esEditar) {
                this.actualizarUsuarioContacto({
                    email,
                    token_usuario,
                    fono1,
                    cargo_id,
                    nombre_cargo,
                    tipo_usuario,
                })
                    .then((res) => {
                        if (res.status == 202) {
                            this.mensaje.variant = "danger";
                            this.mensaje.texto =
                                "Ha ocurrido un error, intente nuevamente";
                        }

                        if (res.status == 204) {
                            this.mensaje.variant = "success";
                            this.mensaje.texto =
                                "Contacto Actualizado correctamente!!!";
                                 this.mostrarModal = false;
                            this.$emit("contactoEliminado");
                            
                        }
                        this.segundosMensajeActualizacion = this.segundos;
                    })
                    .catch((error) => {
                        this.mensaje.variant = "danger";
                        this.mensaje.texto =
                            "No se ha actualizado el registro, intente nuevamente";
                        this.mostrarModal = false;
                        this.segundosMensajeActualizacion = this.segundos;
                    });
            }
            this.submitted = false;
        },
        resetForm() {
            this.form = {
                tipoUsuario: null,
                fono: null,
                cargo: null,
            };

            this.$nextTick(() => {
                this.$v.$reset();
            });
        },
    },
    computed: {
        ...authUsuarioComputed
    },
};
</script>

<style scoped>
.card{
    -webkit-box-shadow: 0px 0px 19px -2px rgba(0,0,0,0.3); 
    box-shadow: 0px 0px 19px -2px rgba(0,0,0,0.3);
    -webkit-transition: all 0.2s ease;
    -moz-transition: all 0.2s ease;
    transition: all 0.2s ease;
}
.card:hover{
    -webkit-box-shadow: 0px 0px 19px -2px rgba(0,0,0,0.5); 
    box-shadow: 0px 0px 19px -2px rgba(0,0,0,0.5);
}
</style>