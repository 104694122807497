<template>
  <section>
    <loading-flux :value="loading"></loading-flux>
    <v-dialog
      v-model="modalAction"
      max-width="1000px"
      @keydown="executeCloseModalRequestConsumptionMaterials"
      @click:outside="executeCloseModalRequestConsumptionMaterials"
    >
      <v-card>
        <v-card-title>
          <span class="text-h6">Crear solicitud de consumo de materiales</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-data-table
              class="px-1 elevation-1"
              v-model="materialsSelected"
              :headers="tableHeaders"
              :items="
                materialsAvailable.materials ? materialsAvailable.materials : []
              "
              :items-per-page="filters.items !== 'Todos' ? filters.items : 100"
              :page.sync="filters.page"
              hide-default-footer
              :loading="loadingMaterialsAvailable"
              loading-text="Cargando materiales"
              show-select
              item-key="id"
              no-data-text="Sin materiales"
              @page-count="pageCount = $event"
              @toggle-select-all="updateQuantityRowSelected"
              @item-selected="updateQuantityRowSelected"
            >
              <template v-slot:top>
                <v-toolbar flat class="pt-5">
                  <v-text-field
                    label="Busqueda"
                    v-model="filters.filter"
                    @input="getMaterials(1)"
                    class="w-25 mx-4"
                    clearable
                    placeholder="Buscar material"
                    append-icon="mdi-magnify"
                  ></v-text-field>
                  <v-autocomplete
                    class="w-25"
                    @change="($event) => getMaterials(1)"
                    v-model="filters.items"
                    :items="[5, 10, 15, 20, 25, 'Todos']"
                    label="Items por página"
                  >
                  </v-autocomplete>
                  <v-divider class="mx-5" inset vertical></v-divider>
                  <v-spacer></v-spacer>
                  <div class="d-flex align-items-center">
                    <p class="mx-3 text-h6">
                      <small class="pull-right">
                        Seleccionados: {{ materialsSelected.length }}
                      </small>
                    </p>
                  </div>
                </v-toolbar>
              </template>
              <template v-slot:item.code="{ item }">
                <span style="font-size: 12.5px">{{ item.material.code }}</span>
              </template>
              <template v-slot:item.available="{ item }">
                <span style="font-size: 12.5px">{{
                  item.available_quantity
                }}</span>
              </template>
              <template v-slot:item.description="{ item }">
                <span style="font-size: 12.5px">{{
                  item.material.description
                }}</span>
              </template>
              <template v-slot:item.units="{ item }">
                <span style="font-size: 12.5px">{{
                  item.material.measurement.units
                }}</span>
              </template>
              <template v-slot:item.request="{ item }">
                <v-text-field
                  v-if="materialIsSelected(item)"
                  type="number"
                  :min="0"
                  :max="item.available_quantity"
                  class="pt-0 mt-0"
                  @input="setMaterialRequestValue($event, item)"
                  :error="
                    materialIsSelected(item)
                      ? materialIsSelected(item).request >
                        item.available_quantity
                      : false
                  "
                  :value="
                    materialIsSelected(item) &&
                    materialIsSelected(item).request !== undefined
                      ? materialIsSelected(item).request
                      : item.available_quantity
                  "
                  hide-details
                ></v-text-field>
              </template>
            </v-data-table>
            <div class="text-center pt-2">
              <v-pagination
                v-model="filters.page"
                :length="
                  materialsAvailable.number_of_pages
                    ? materialsAvailable.number_of_pages
                    : 1
                "
                :total-visible="8"
              ></v-pagination>
            </div>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            :disabled="loading"
            color="red darken-1"
            outlined
            text
            @click="executeCloseModalRequestConsumptionMaterials"
          >
            <small>
              Cancelar
            </small>
          </v-btn>
          <v-btn
            color="blue darken-3"
            :loading="loading"
            :disabled="materialsSelected.length < 1"
            @click="validateMaterialsSelectedAndSendRequestConsumption"
          >
            <small class="text-white">
              Crear solicitud
            </small>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </section>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { authUsuarioComputed } from "@/state/helpers";
import { preventCloseIfNotKeyEventEsc } from "@/helpers/common";
export default {
  props: {
    modalAction: {
      type: Boolean,
      default: false,
    },
    closeModalRequestConsumptionMaterials: {
      type: Function,
      default: () => {},
    },
  },
  data() {
    return {
      loading: false,
      debounce: null,
      debounceTime: 500,
      filters: {
        page: 1,
        items: 10,
        filter: "",
      },
      materialsSelected: [],
      tableHeaders: [
        {
          text: "Código",
          align: "start",
          width: "5%",
          sortable: false,
          value: "code",
        },
        {
          text: "Descripción",
          align: "start",
          width: "70%",
          sortable: false,
          value: "description",
        },
        {
          text: "Unidad",
          align: "start",
          width: "5%",
          sortable: false,
          value: "units",
        },
        {
          text: "Disponible",
          align: "start",
          width: "5%",
          sortable: false,
          value: "available",
        },
        {
          text: "Solicitar",
          align: "center",
          width: "15%",
          sortable: false,
          value: "request",
        },
      ],
      codeProject: this.$route.params.codigoProyecto,
    };
  },
  async mounted() {
    //   await this.loadMaterialsFromErp({
    //     codigo: this.codeProject,
    //     user: this.user.email,
    //   });
    this.getMaterials(1);
  },
  methods: {
    ...mapActions({
      getMaterialsAvailableFromProject:
        "returnMaterial/getMaterialsAvailableFromProject",
      loadMaterialsFromErp: "returnMaterial/loadMaterialsFromErp",
      sendRequestConsumptionMaterial:
        "consumptionMaterial/sendRequestConsumptionMaterial",
    }),
    executeCloseModalRequestConsumptionMaterials($event) {
      let validationEvent = preventCloseIfNotKeyEventEsc($event);
      if (validationEvent) {
        this.materialsSelected = [];
        this.filters.filter = "";
        this.getMaterials(1);
        this.closeModalRequestConsumptionMaterials($event);
      }
    },
    async getMaterials(page = 1) {
      clearTimeout(this.debounce);
      let self = this;
      this.debounce = setTimeout(async function() {
        self.filters.page = page;
        await self.getMaterialsAvailableFromProject({
          page: self.filters.page,
          filter: self.filters.filter ? self.filters.filter : undefined,
          items:
            self.filters.items !== "Todos" ? self.filters.items : undefined,
          project_filter: self.codeProject,
          show_all: self.filters.items === "Todos" ? true : undefined,
        });
      }, self.debounceTime);
    },
    materialIsSelected(material) {
      return this.materialsSelected.find((item) => item.id === material.id);
    },
    updateQuantityRowSelected({ item, items, value }) {
      if (value) {
        let materials = items ? items : [item];
        materials.forEach((material) => {
          this.materialsSelected.push(material);
          this.setMaterialRequestValue(material.available_quantity, material);
        });
      }
    },
    setMaterialRequestValue(value, material) {
      this.materialsSelected.forEach((item) => {
        if (item.id === material.id) {
          item.request = value;
        }
      });
      this.materialsSelected = [...this.materialsSelected];
    },
    showErrorMaterial(material) {
      let currentMaterial = this.materialIsSelected(material);
      if (
        currentMaterial &&
        Number(currentMaterial.request > material.available_quantity)
      ) {
        return ["El valor debe ser menor o igual al disponible"];
      }
      return [];
    },
    validateMaterialsSelectedAndSendRequestConsumption() {
      let validMaterials = true;
      this.materialsSelected.forEach((material) => {
        if (
          isNaN(material.request) ||
          Number(material.request) > material.available_quantity
        ) {
          validMaterials = false;
        }
      });
      if (!validMaterials) {
        return this.$swal({
          icon: "error",
          title: "Error",
          text:
            "Las cantidades a solicitar deben ser menores a la cantidad disponibles de material",
          position: "top-end",
          showConfirmButton: false,
          toast: true,
          timer: 5000,
          timerProgressBar: true,
        });
      }
      this.submitRequestConsumptionMaterial();
    },
    async submitRequestConsumptionMaterial() {
      this.loading = true;
      const resp = await this.sendRequestConsumptionMaterial({
        material_list: this.materialsSelected.map((material) => {
          return {
            material_id: material.id,
            quantity: Number(material.request),
          };
        }),
        project_code: this.codeProject,
        user: this.user.email,
      });
      if (resp.status === 500) {
        this.$swal.fire({
          icon: "error",
          title: "Error",
          text:
            "Ha ocurrido un error en el servidor, por favor inténtalo nuevamente",
          position: "top-end",
          showConfirmButton: false,
          toast: true,
          timer: 5000,
          timerProgressBar: true,
        });
      }
      if (resp.status === 200) {
        this.$swal.fire({
          icon: "success",
          title: "Excelente",
          text: "Solicitud de consumo creada correctamente",
          position: "top-end",
          showConfirmButton: false,
          toast: true,
          timer: 5000,
          timerProgressBar: true,
        });
        this.getMaterials(1);
        this.$emit("requestConsumptionMaterialCreated");
        this.materialsSelected = [];
        this.closeModalRequestConsumptionMaterials();
      }
      this.loading = false;
    },
  },
  computed: {
    ...authUsuarioComputed,
    ...mapGetters({
      loadingMaterialsAvailable: "returnMaterial/loading",
      materialsAvailable: "returnMaterial/materialsAvailable",
    }),
  },
  watch: {
    "filters.page": function(page) {
      this.getMaterials(page);
    },
  },
};
</script>

<style></style>
