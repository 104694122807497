<template>
  <section class="p-3">
    <loading-flux :value="loading"></loading-flux>
    <section class="d-flex flex-column flex-sm-row justify-content-between">
      <h2 class="ml-1 my-2">
        {{ title }}
        {{
          $route.query.sap_project ? `| COD: ${$route.query.sap_project}` : ""
        }}
      </h2>
      <v-breadcrumbs :items="breadcrumb"></v-breadcrumbs>
    </section>
    <v-row align-content="center" align-md="center" class="mt-1">
      <v-col cols="12" md="4" sm="12" v-if="!$route.query.sap_project">
        <label for="proyect"> Filtrar por Estatus </label>
        <v-autocomplete
          v-model="filters.status_filter"
          clearable
          :items="approvedRequestsStatus"
          @input="getApprovedRequestsData(1)"
          hide-details
          item-text="name"
          item-value="id"
          label="Todos"
        ></v-autocomplete>
      </v-col>
      <v-col cols="12" md="4" sm="12" v-if="!$route.query.sap_project">
        <label for="proyect"> Filtrar por Proyecto </label>
        <v-autocomplete
          v-model="filters.project_code"
          clearable
          :items="
            projects.items
              ? projects.items.filter((item) => item.warehouse)
              : []
          "
          @input="getApprovedRequestsData(1)"
          @update:search-input="getProjectsData"
          @click:clear="getProjectsData"
          class=""
          hide-details
          item-text="name_code"
          no-data-text="Sin proyectos"
          :loading="loadingProjects"
          label="Todos"
        ></v-autocomplete>
      </v-col>
      <v-col cols="12" md="4" sm="12">
        <div v-if="!$route.query.sap_project">
          <label for="proyect"> Filtrar por CECO </label>
          <v-autocomplete
            v-model="filters.cost_center"
            clearable
            :items="cecos.items ? cecos.items : []"
            @input="getApprovedRequestsData(1)"
            @update:search-input="getCecosData"
            @click:clear="getCecosData"
            class=""
            hide-details
            item-text="name_code"
            no-data-text="Sin CECOS"
            :loading="loadingCecos"
            label="Todos"
          ></v-autocomplete>
        </div>
      </v-col>
      <!-- <v-col cols="12" md="4" sm="12">
        <label for="proyect"> Filtro de Búsqueda </label>
        <v-text-field
          v-model="filters.filter"
          clearable
          placeholder="Buscar"
          hide-details
        ></v-text-field>
      </v-col> -->
      <v-col cols="12" md="4" sm="12"> </v-col>
      <v-col cols="12" md="4" sm="12">
        <p class="my-0 text-right mt-3">
          Número de Solicitudes Total:
          {{ approveRequests.total_items }}
        </p>
      </v-col>
    </v-row>

    <v-tabs v-model="tab" fixed-tabs class="mt-4">
      <v-tab key="0" id="approvalList"> Por Aprobar </v-tab>
      <v-tab key="1" id="requesterList"> Mis Solicitudes </v-tab>
    </v-tabs>

    <v-data-table
      class="elevation-3 px-5 py-3 mt-5"
      :headers="wrappedHeaders"
      :items="approveRequests.items"
      :item-class="rowColor"
      :items-per-page="20"
      :page.sync="filters.page"
      :loading="loadingApproveRequests"
      loading-text="Cargando solicitudes"
      item-key="id"
      hide-default-footer
      no-data-text="Sin Solicitudes"
      @page-count="pageCount = $event"
    >
      <template v-slot:item.code="{ item }">
        <span class="mt-2" style="font-size: 11px">
          {{
            item.project
              ? item.project.code
              : item.cost_center
              ? item.cost_center.code
              : "-"
          }}
        </span>
      </template>
      <template v-slot:item.shopping_index="{ item }">
        <span class="mt-2" style="font-size: 11px">
          {{ item.shopping_index ? item.shopping_index : "-" }}
        </span>
      </template>
      <template v-slot:item.origin="{ item }">
        <span class="mt-2" style="font-size: 11px">
          {{ item.origin ? item.origin : "-" }}
        </span>
      </template>
      <template v-slot:item.request_type="{ item }">
        <span
          :class="{
            'text-primary font-weight-bold': item.is_maverick,
          }"
          class="mt-2"
          style="font-size: 11px"
        >
          {{
            item.is_maverick
              ? "MAVERICK"
              : ifRequestOfService(item)
              ? item.request_type == REQUEST_MATERIAL
                ? REQUEST_SERVICE
                : REQUEST_SERVICE_URGENT
              : item.request_type
          }}
        </span>
      </template>
      <template v-slot:item.created_at="{ item }">
        <span>
          {{ moment(item.created_at).format("DD/MM/YYYY") }}
        </span>
      </template>
      <template v-slot:item.amount="{ item }">
        <span>
          {{ "$ " + item.amount.toLocaleString("es", optionsFormatting) }}
        </span>
      </template>
      <template v-slot:item.sent_to_erp="{ item }">
        <v-chip :color="getColorStatus(item)" class="text-white">
          {{
            item.cancelled
              ? "Anulada"
              : item.rejected
              ? "Rechazada"
              : item.sent_to_erp
              ? "Aprobada"
              : "Pendiente"
          }}</v-chip
        >
      </template>
      <template v-slot:item.actions="{ item }">
        <div class="d-flex align-items-center justify-content-start">
          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                @click="openModalListMaterialsRequest(item)"
                icon
                color="primary"
                v-bind="attrs"
                v-on="on"
              >
                <v-icon> mdi-eye </v-icon>
              </v-btn>
            </template>
            <span>Ver Detalles</span>
          </v-tooltip>
          <v-tooltip top v-if="permissionToApprove(item)">
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                @click="
                  approveOrRejectedRequestMaterial({
                    item,
                    action: true,
                  })
                "
                icon
                color="success"
                v-bind="attrs"
                v-on="on"
              >
                <v-icon> mdi-check </v-icon>
              </v-btn>
            </template>
            <span>Aprobar solicitud</span>
          </v-tooltip>
          <v-tooltip
            top
            v-if="
              !item.cancelled &&
              !item.rejected &&
              !item.sent_to_erp &&
              permissionToApproveRequestRestricted(item)
            "
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                @click="
                  approveOrRejectedRequestMaterialRestricted({
                    item,
                    action: true,
                  })
                "
                icon
                color="success"
                v-bind="attrs"
                v-on="on"
              >
                <v-icon> mdi-check </v-icon>
              </v-btn>
            </template>
            <span>Aprobar restricción de solicitud urgente</span>
          </v-tooltip>
          <v-tooltip
            top
            v-if="
              user &&
              item.created_by === user.email &&
              !item.approved_index &&
              !item.modified_index &&
              !item.cancelled &&
              !item.rejected &&
              !item.sent_to_erp
            "
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                @click="cancelRequestApprove(item)"
                icon
                color="red darken-2"
                v-bind="attrs"
                v-on="on"
              >
                <v-icon> mdi-delete </v-icon>
              </v-btn>
            </template>
            <span>Anular Solicitud</span>
          </v-tooltip>
          <v-tooltip top v-if="permissionToApprove(item)">
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                @click="
                  approveOrRejectedRequestMaterial({
                    item,
                    action: false,
                  })
                "
                icon
                color="red"
                v-bind="attrs"
                v-on="on"
              >
                <v-icon> mdi-cancel </v-icon>
              </v-btn>
            </template>
            <span>Rechazar solicitud</span>
          </v-tooltip>
          <v-tooltip
            right
            v-if="
              !item.cancelled &&
              !item.rejected &&
              !item.sent_to_erp &&
              permissionToApproveRequestRestricted(item)
            "
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                @click="
                  approveOrRejectedRequestMaterialRestricted({
                    item,
                    action: false,
                  })
                "
                icon
                color="red"
                v-bind="attrs"
                v-on="on"
              >
                <v-icon> mdi-cancel </v-icon>
              </v-btn>
            </template>
            <span>Rechazar restricción de solicitud urgente</span>
          </v-tooltip>
        </div>
      </template>
    </v-data-table>
    <div class="float-right mt-5">
      <v-pagination
        :total-visible="8"
        v-model="filters.page"
        :length="approveRequests ? approveRequests.total_pages : 1"
      ></v-pagination>
    </div>

    <RequestDetails
      @requestUpdated="getApprovedRequestsData(1)"
      ref="requestMaterialItems"
      :currentRequestMaterials="currentRequestMaterials"
      :modalAction="dialogListMaterialsRequest"
      :closeModalListMaterialsRequest="closeModalListMaterialsRequest"
    ></RequestDetails>
    <div id="listMaterialsSelected" style="display: none">
      <table-materials
        ref="approveOrRejectRequest"
        :materials="materialsToApproveOrReject"
      >
        <template v-slot:comment>
          <v-textarea
            outlined
            rows="3"
            class="mt-2 mb-3"
            v-model="form.comment"
            hide-details
          ></v-textarea>
        </template>
      </table-materials>
    </div>
  </section>
</template>

<script>
import { preventCloseIfNotKeyEventEsc } from "@/helpers/common";
import RequestDetails from "./RequestDetails.vue";
import TableMaterials from "./TableMaterials.vue";
import swal2 from "sweetalert2";
import moment from "moment";
import {
  STATUS_REQUESTS_MATERIALS,
  REQUEST_SERVICE,
  REQUEST_SERVICE_URGENT,
  REQUEST_MATERIAL,
  REQUEST_MATERIAL_URGENT,
} from "@/constants/requestMaterial";
import { STATUS_APPROVAL_LIST } from "@/constants/approval";
import { authUsuarioComputed } from "@/state/helpers";
import { mapActions, mapGetters } from "vuex";
import { OPTIONS_AMOUNT_FORMATTING } from "@/constants/common";

export default {
  props: {
    title: {
      type: String,
      default: "",
    },
  },
  components: { TableMaterials, RequestDetails },
  mounted() {
    this.getApprovedRequestsData();
    if (!this.$route.query.sap_project) {
      this.getProjectsData();
      this.getCecosData();
    }
  },
  watch: {
    "filters.page": function (page) {
      this.getApprovedRequestsData(page);
    },
    // "filters.filter": function(page) {

    //   this.getApprovedRequestsData(page);
    // },
    tab: function (tab) {
      this.filters.page = 1;
      this.getApprovedRequestsData(1);
    },
    approveRequests(approveRequests) {
      if (this.currentRequestMaterials) {
        this.changeCurrentRequestMaterials(this.currentRequestMaterials);
      }
    },
  },
  data() {
    return {
      tab: 0,
      moment,
      REQUEST_SERVICE,
      REQUEST_SERVICE_URGENT,
      REQUEST_MATERIAL,
      REQUEST_MATERIAL_URGENT,
      approvedRequestsStatus: STATUS_APPROVAL_LIST,
      loadingApproveRequests: false,
      loadingProjects: false,
      loadingCecos: false,
      optionsFormatting: OPTIONS_AMOUNT_FORMATTING,
      currentRequestMaterials: null,
      materialsToApproveOrReject: [],
      dialogListMaterialsRequest: false,
      statusRequests: STATUS_REQUESTS_MATERIALS,
      filters: {
        page: 1,
        filter: "",
        status_filter: null,
        project_code: null,
        cost_center: null,
      },
      debounceApproveRequests: null,
      debounceProjects: null,
      debounceCecos: null,
      debounceTime: 500,
      pageCount: 0,
      tableHeaders: [
        {
          text: "Bodega",
          align: "center",
          sortable: false,
          value: "origin",
        },
        {
          text: "Fecha de Creación",
          align: "center",
          sortable: false,
          value: "created_at",
        },
        {
          text: "Tipo",
          align: "center",
          sortable: false,
          value: "request_type",
        },
        {
          text: "Estado",
          align: "center",
          sortable: false,
          value: "sent_to_erp",
        },
        {
          text: "Monto Total",
          align: "center",
          sortable: false,
          value: "amount",
        },
      ],
      breadcrumb: [
        {
          text: "Proyectos",
          disabled: false,
          href: "/",
        },
        {
          text: "Solicitudes de Aprobación",
          disabled: true,
          href: "/request-materials/approvals",
        },
      ],
    };
  },
  methods: {
    ...mapActions({
      approveOrRejectRequestApprove:
        "requestMaterial/approveOrRejectRequestApprove",
      sendApproveOrRejectRequestApproveRestricted:
        "requestMaterial/approveOrRejectRequestApproveRestricted",
      sendCancelRequestApprove: "requestMaterial/cancelRequestApprove",
      getMaterialsRequestsToApprove:
        "requestMaterial/getMaterialsRequestsToApprove",
      getMaterialsInfoList: "requestMaterialsSap/getMaterialsInfoList",
      updateDataMaterialsCurrentRequestApprove:
        "requestMaterial/updateDataMaterialsCurrentRequestApprove",
      getProjects: "requestMaterialsSap/getProjects",
      getCecos: "requestMaterialsSap/getCecos",
      getProjectInfo: "project/getProjectInfo",
    }),
    rowColor(item) {
      if (item.is_maverick) {
        return "bg-maverick";
      }
      return "";
    },
    ifRequestOfService(requestApprove) {
      return requestApprove.service_requests.length ? true : false;
    },
    async getApprovedRequestsData(page = 1) {
      let self = this;
      clearTimeout(self.debounceApproveRequests);
      self.debounceApproveRequests = setTimeout(async () => {
        self.loadingApproveRequests = true;
        const params = {
          page,
          filter: this.filters.filter ? this.filters.filter : undefined,
          status_filter: this.filters.status_filter
            ? this.filters.status_filter
            : undefined,
          project_code: this.$route.query.sap_project
            ? this.$route.query.sap_project
            : this.filters.project_code
            ? this.filters.project_code.split(" - ")[0]
            : undefined,
          cost_center: this.filters.cost_center
            ? this.filters.cost_center.split(" - ")[0]
            : undefined,
        };
        if (this.tab == 0) {
          params.user = this.user.email;
        } else {
          params.created_by = this.user.email;
        }
        await this.getMaterialsRequestsToApprove(params);
        self.loadingApproveRequests = false;
      }, self.debounceTime);
    },
    changeCurrentRequestMaterials(requestMaterials) {
      let requestMaterialsUpdated = this.approveRequests.items.find(
        (item) => item.id == requestMaterials.id
      );
      this.currentRequestMaterials = requestMaterialsUpdated;
    },
    permissionToApprove(requestMaterial) {
      if (this.user && requestMaterial.request_approvers) {
        return (
          requestMaterial.request_approvers.find((item) => {
            return (
              (item.approver.email == this.user.email ||
                item.approver.substituting_for.find(
                  ({ substitute }) => substitute.email == this.user.email
                )) &&
              Math.abs(item.order - requestMaterial.approved_index) == 1
            );
          }) !== undefined &&
          !requestMaterial.sent_to_erp &&
          !requestMaterial.rejected &&
          !requestMaterial.cancelled
        );
      }
      return false;
    },
    permissionToApproveRequestRestricted(item) {
      return (
        this.user &&
        item.restricted &&
        item.restriction_approvers.find((approver) => {
          return approver.approver.email == this.user.email;
        })
      );
    },
    getColorStatus(item) {
      if (item && (item.rejected || item.cancelled)) {
        return "red darken-2";
      }
      switch (item.sent_to_erp) {
        case this.statusRequests.pending:
          return "yellow darken-4";
        case this.statusRequests.accepted:
          return "blue darken-2";
        default:
          break;
      }
    },
    async openModalListMaterialsRequest(
      requestMaterial,
      openApprovers = false
    ) {
      let self = this;
      this.currentRequestMaterials = requestMaterial;
      await self.getProjectInfo({
        project_code: requestMaterial.project
          ? requestMaterial.project.code
          : requestMaterial.cost_center.code,
      });
      this.dialogListMaterialsRequest = true;
      if (openApprovers) {
        setTimeout(() => {
          self.$refs["requestMaterialItems"].openApproversList();
        }, 1000);
      }
    },
    closeModalListMaterialsRequest($event) {
      let validationEvent = preventCloseIfNotKeyEventEsc($event);
      if (validationEvent) {
        this.currentRequestMaterials = null;
        this.dialogListMaterialsRequest = false;
      }
    },
    async cancelRequestApprove(item) {
      let self = this;
      this.$swal
        .fire({
          icon: "warning",
          title: `¿Estás seguro de anular la solicitud de materiales con código de ${
            item.project ? "Proyecto" : "CECO"
          }: ${item.project ? item.project.code : item.cost_center.code}`,
          showCancelButton: true,
          showConfirmButton: true,
          confirmButtonText: `Anular`,
          confirmButtonColor: "red",
          cancelButtonText: `Cancelar`,
        })
        .then(async (result) => {
          if (result.isConfirmed) {
            const response = await self.sendCancelRequestApprove({
              request_id: item.id,
              user: self.user.email,
            });
            if (response.status == 200) {
              self.$swal.fire({
                icon: "success",
                title: "Excelente",
                text: `Solicitud anulada con éxito`,
                position: "top-end",
                showConfirmButton: false,
                toast: true,
                timer: 4000,
                timerProgressBar: true,
              });
              this.getApprovedRequestsData(1);
            }
            if (response.status == 500) {
              self.$swal.fire({
                icon: "error",
                title: "Error",
                text: "Ha ocurrido un error en el servidor, intentalo nuevamente",
                position: "top-end",
                showConfirmButton: false,
                toast: true,
                timer: 4000,
                timerProgressBar: true,
              });
            }
          }
        });
    },
    async approveOrRejectedRequestMaterial({ item, action }) {
      let self = this;
      if (!item.service_requests.length) {
        const listMaterials = await this.getMaterialsInfoList({
          material_list: item.materials
            .map((material) => material.code)
            .join(","),
        });
        this.updateDataMaterialsCurrentRequestApprove({
          id: item.id,
          materials: listMaterials.materials,
          is_service: false,
        });
      } else {
        this.updateDataMaterialsCurrentRequestApprove({
          id: item.id,
          materials: item.service_requests[0].items,
          is_service: true,
        });
      }
      self.materialsToApproveOrReject = item.materials;
      let listMaterialsSelectedEl = document.getElementById(
        "listMaterialsSelected"
      );
      await setTimeout(async () => {
        await swal2
          .fire({
            icon: "warning",
            title: `¿Estás seguro de ${
              action ? "aprobar" : "rechazar"
            } la solicitud de materiales con código de ${
              item.project ? "Proyecto" : "CECO"
            }: ${
              item.project ? item.project.code : item.cost_center.code
            } con los siguientes materiales?`,
            html: listMaterialsSelectedEl.innerHTML,
            showCancelButton: true,
            showConfirmButton: true,
            confirmButtonText: `${action ? "Aprobar" : "Rechazar"}`,
            confirmButtonColor: action ? "primary" : "red",
            cancelButtonText: `Cancelar`,
            input: "textarea",
            inputPlaceholder: action
              ? "Comentario (Opcional)"
              : "Motivo de Rechazo",
            inputValue: "",
            inputAttributes: {
              class: "mt-5",
            },
          })
          .then(async (result) => {
            if (result.isConfirmed) {
              const value = result.value;
              if (!action && !value) {
                self.$swal.fire({
                  icon: "error",
                  title: "Error",
                  text: "El motivo de rechazo es requerido",
                  position: "top-end",
                  showConfirmButton: false,
                  toast: true,
                  timer: 4000,
                  timerProgressBar: true,
                });
                return;
              }
              const response = await self.approveOrRejectRequestApprove({
                request_id: item.id,
                user: self.user.email,
                approval: action,
                comment: result.value,
              });
              if (response?.response && response.response.status == 500) {
                self.$swal.fire({
                  icon: "error",
                  title: "Error",
                  text: "Ha ocurrido un error en el servidor, intentalo nuevamente",
                  position: "top-end",
                  showConfirmButton: false,
                  toast: true,
                  timer: 4000,
                  timerProgressBar: true,
                });
              }
              if (
                response?.response &&
                (response.response.status == 400 ||
                  response.response.status == 404)
              ) {
                self.$swal.fire({
                  icon: "error",
                  title: "Error",
                  text: response.response.data.detail,
                  position: "top-end",
                  showConfirmButton: false,
                  toast: true,
                  timer: 4000,
                  timerProgressBar: true,
                });
              }
              if (response.status == 200) {
                self.$swal.fire({
                  icon: "success",
                  title: "Excelente",
                  text: `Solicitud ${
                    action ? "Aprobada" : "Rechazada"
                  } con éxito`,
                  position: "top-end",
                  showConfirmButton: false,
                  toast: true,
                  timer: 4000,
                  timerProgressBar: true,
                });
                this.getApprovedRequestsData(1);
              }
            }
          });
      }, 300);
    },
    async approveOrRejectedRequestMaterialRestricted({ item, action }) {
      let self = this;
      await setTimeout(async () => {
        await swal2
          .fire({
            icon: "warning",
            title: `¿Estás seguro de ${
              action ? "aprobar" : "rechazar"
            } la restricción de la solicitud de materiales urgente con código de ${
              item.project ? "Proyecto" : "CECO"
            }: ${item.project ? item.project.code : item.cost_center.code} ?`,
            showCancelButton: true,
            showConfirmButton: true,
            confirmButtonText: `${action ? "Aprobar" : "Rechazar"}`,
            confirmButtonColor: action ? "primary" : "red",
            cancelButtonText: `Cancelar`,
            input: "textarea",
            inputPlaceholder: action
              ? "Comentario (Opcional)"
              : "Motivo de Rechazo",
            inputValue: "",
            inputAttributes: {
              class: "mt-5",
            },
          })
          .then(async (result) => {
            if (result.isConfirmed) {
              const value = result.value;
              if (!action && !value) {
                self.$swal.fire({
                  icon: "error",
                  title: "Error",
                  text: "El motivo de rechazo es requerido",
                  position: "top-end",
                  showConfirmButton: false,
                  toast: true,
                  timer: 4000,
                  timerProgressBar: true,
                });
                return;
              }
              const response =
                await self.sendApproveOrRejectRequestApproveRestricted({
                  request_id: item.id,
                  user: self.user.email,
                  approval: action,
                  comment: result.value,
                });
              if (response.status == 400 || response.status == 404) {
                self.$swal.fire({
                  icon: "error",
                  title: "Error",
                  text: response.data.detail,
                  position: "top-end",
                  showConfirmButton: false,
                  toast: true,
                  timer: 4000,
                  timerProgressBar: true,
                });
              }
              if (response.status == 200) {
                self.$swal.fire({
                  icon: "success",
                  title: "Excelente",
                  text: `Restricción de solicitud ${
                    action ? "Aprobada" : "Rechazada"
                  } con éxito`,
                  position: "top-end",
                  showConfirmButton: false,
                  toast: true,
                  timer: 4000,
                  timerProgressBar: true,
                });
                this.getApprovedRequestsData(1);
              }
              if (response.status == 500) {
                self.$swal.fire({
                  icon: "error",
                  title: "Error",
                  text: "Ha ocurrido un error en el servidor, intentalo nuevamente",
                  position: "top-end",
                  showConfirmButton: false,
                  toast: true,
                  timer: 4000,
                  timerProgressBar: true,
                });
              }
            }
          });
      }, 300);
    },
    async getProjectsData(search = "") {
      if (
        (search && search.type) ||
        ((search || typeof search == "string") &&
          this.filters.project_code !== search)
      ) {
        this.loadingProjects = true;
        clearTimeout(this.debounceProjects);
        let self = this;
        this.debounceProjects = setTimeout(async function () {
          await self.getProjects({
            filter: search && !search.type ? search : undefined,
            items: 20,
          });
          self.loadingProjects = false;
        }, self.debounceTime);
      }
    },
    async getCecosData(search = "") {
      if (
        (search && search.type) ||
        ((search || typeof search == "string") &&
          this.filters.cost_center !== search)
      ) {
        this.loadingCecos = true;
        clearTimeout(this.debounceCecos);
        let self = this;
        this.debounceCecos = setTimeout(async function () {
          await self.getCecos({
            filter: search && !search.type ? search : undefined,
            items: 20,
          });
          self.loadingCecos = false;
        }, self.debounceTime);
      }
    },
  },
  computed: {
    ...authUsuarioComputed,
    ...mapGetters({
      loading: "requestMaterial/loading",
      approveRequests: "requestMaterial/materialsRequestsToApprove",
      projects: "requestMaterialsSap/projects",
      cecos: "requestMaterialsSap/cecos",
      projectInfo: "project/projectInfo",
    }),
    wrappedHeaders() {
      if (this.$route.query.sap_project) {
        return [
          {
            text: "Acciones",
            align: "center",
            sortable: false,
            value: "actions",
          },
          {
            text: "Cesta de Compra",
            align: "center",
            value: "shopping_index",
            sortable: false,
          },

          ...this.tableHeaders,
        ];
      }
      return [
        {
          text: "Acciones",
          align: "center",
          sortable: false,
          value: "actions",
        },
        {
          text: "Cesta de Compra",
          align: "center",
          value: "shopping_index",
          sortable: false,
        },
        {
          text: "Proyecto/CECO",
          align: "center",
          value: "code",
          sortable: false,
        },
        ...this.tableHeaders,
      ];
    },
  },
};
</script>

<style>
.bg-maverick {
  background-color: #e3f2fd !important;
}
</style>
