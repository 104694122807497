<template>
    <v-card>
        <b-overlay :show="loading" variant="white" rounded="sm">
            <div class="card pt-3">
                <div class="d-flex p-2"  v-if="showTitle">
                    <div class="card-title titulo-tarjetas">
                        Comentarios
                    </div>
                </div>
                
                <div class="card-body pt-0 p-4">
                    <div v-if="comentariosUsuarioProyecto.length > 0">
                        <v-timeline
                            align-top
                            :dense="$vuetify.breakpoint.smAndDown"
                            :class="{'scrolled': showTitle && comentariosUsuarioProyecto.length > 0 }"
                            class="px-5"
                        >
                            <v-timeline-item
                            v-for="(item, i) in comentariosUsuarioProyecto"
                            :key="i"
                            :icon="getInicial(item.nombre_usuario)"
                            :color="stringToColour(item.nombre_usuario)"
                            fill-dot
                            >
                            <Comentario :comentario="item" />
                            </v-timeline-item>
                        </v-timeline>
                    </div>
                    <div v-else class="py-5">
                        <v-alert
                        color="red lighten-3"
                        type="info"
                        >
                        <span class="brown--text text--darken-4">Aún no hay comentarios para mostrar</span>
                        </v-alert>
                    </div>

                    
                    <div class="card col-md-12 mb-0" v-if="rolModuloPermiso.escritura">
                        <div 
                            :class="{'comment-box': !showTitle}">
                            <div 
                                v-if="!showTitle"
                                class="mask-left"
                            >&nbsp;</div>
                            <div 
                                class="card-body pt-0"
                                :class="{'comment-body': !showTitle}"
                                >
                                <div class="pt-4 row">
                                    <div class="col">
                                        <div class="position-relative">
                                            <input
                                                type="text"
                                                v-model="mensajeComentario"
                                                class="form-control input-comentario rounded"
                                                :placeholder="'Escribir comentario...'"
                                                @keyup.enter="crearComentarioProyecto()"
                                            />
                                        </div>
                                    </div>
                                    <div class="col-auto">
                                        <button
                                            class="
                                            btn btn-primary btn-rounded
                                            chat-send
                                            w-md
                                        "
                                            @click="crearComentarioProyecto()"
                                        >
                                            <span class="d-none d-sm-inline-block me-2"
                                                >Enviar</span
                                            >
                                            <i class="mdi mdi-send"></i>
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                
            </div>
        </b-overlay>
    </v-card>
</template>

<script>
import { comentarioMethods, comentarioComputed } from "@/state/helpers";
import Comentario from "@/components/widgets/comentario/comentario";
import { proyectoMethods, proyectoComputed } from "@/state/helpers";
import {authMethods,authUsuarioComputed} from "@/state/helpers";
import {permiso} from "@/helpers/authservice/obtener-permisos";
export default {
    components: {
        Comentario,
    },
    props: {
        writeable: {
            type: Boolean,
            default: false,
        },
        showTitle: {
            type: Boolean,
            default: true,
        }
    },
    data() {
        return {
            loading:false,
            comentariosUsuarioProyecto: [],
            mensajeComentario: "",
            lugarInstalacion:{},
            rolModuloPermiso:{},
            codigoProyecto:null,
            codigoLugarInstalacion:null,
            proyectoSeleccionado:{}
        };
    },
    mounted() {
       if(this.proyectoUsuario == null){
             this.obtenerProyectoUsuario()
        }else{
            this.proyectoSeleccionado = this.proyectoUsuario
        }

        this.codigoProyecto = this.$route.params.codigoProyecto;
        this.codigoLugarInstalacion = this.$route.params.codigoLugarInstalacion;
        this.lugarInstalacion = {codigo_proyecto:this.codigoProyecto,codigo_lugar_instalacion:this.codigoLugarInstalacion}
        this.comentariosUsuarioProyecto = [];
        this.obtenerComentariosProyecto();
        let permisos = permiso.obtenerPermisos(this.user,"comentarios");
        this.rolModuloPermiso = permisos;
    },
    methods: {
        ...comentarioMethods,
        ...proyectoMethods,
        stringToColour(str) {
            var hash = 0;
            for (var i = 0; i < str.length; i++) {
                hash = str.charCodeAt(i) + ((hash << 5) - hash);
            }
            var colour = '#';
            for (var i = 0; i < 3; i++) {
                var value = (hash >> (i * 8)) & 0xFF;
                colour += ('00' + value.toString(16)).substr(-2);
            }
            return colour;
        },
        getInicial(nombre_usuario){
            return nombre_usuario != null ? nombre_usuario.charAt(0) : "U";
        },
        obtenerProyectoUsuario() {
            this.obtenerProyecto(this.$route.params.codigoProyecto)
                .then((res) => {
                    this.proyectoSeleccionado = res.body;
                })
                .catch((error) => {
                });
        },
        obtenerComentariosProyecto() {
            this.loading = true
            this.obtenerComentarios({codigoProyecto:this.codigoProyecto,codigoLugarInstalacion:this.codigoLugarInstalacion})
                .then((res) => {
                    this.comentariosUsuarioProyecto = res.body;
                    this.handleScroll();
                    this.loading = false
                })
                .catch((error) => {this.loading = false});
        },
        crearComentarioProyecto() {
            
            if(this.mensajeComentario != ""){
                this.loading = true
                var datos_comentario = {
                    comentario: this.mensajeComentario,
                    codigo_proyecto: this.codigoProyecto,
                    codigo_lugar_instalacion: this.codigoLugarInstalacion,
                    usuario: this.user.email,
                };
                this.crearComentario(datos_comentario)
                    .then((res) => {
                        this.mensajeComentario = "";
                        this.obtenerComentariosProyecto();
                        this.loading = false
                    })
                    .catch((error) => {this.loading = false});
            }
        },

        irLugarinstalacion() {
            this.$router.push({
                name: "lugaresInstalacion",
            });
        },

        handleScroll() {
            if (this.$refs.contenedorComentarios.$el) {
                setTimeout(() => {
                    this.$refs.contenedorComentarios.SimpleBar.getScrollElement().scrollTop =
                        this.$refs.contenedorComentarios.SimpleBar.getScrollElement()
                            .scrollHeight + 1500;
                }, 500);
            }
        },
    },
    computed: {
        ...comentarioComputed,
        ...proyectoComputed,
        ...authUsuarioComputed
    },
};
</script>

<style scoped>
.mask-left{
    float: left;
    width: 274px;
    background-color: #2a3042;
    height: 80px;
    border-right: 25px solid #e5e5e5;
}
@media(max-width: 992px){
    .mask-left{
        width: 0px;
    }
}
.comment-box{
    position: fixed;
    bottom: 0;
    right: 0;
    left: 0;
    z-index: 1002;
    background-color: #fff;
    -webkit-box-shadow: 0 0.75rem 1.5rem rgb(18 38 63 / 3%);
    box-shadow: 0 0.75rem 1.5rem rgb(18 38 63 / 3%);
}
.comment-body{
    border-right: 25px solid #e5e5e5;
}
.scrolled{
    height: 600px;
    overflow-y: auto;
    overflow-x: hidden;
}
.v-timeline-item >>> .v-icon.v-icon{
    font-style: normal !important;
}
.input-comentario {
    background-color: #eff2f7 !important;
    border-color: #eff2f7 !important;
}

.contenedor-comentarios {
    height: 500px;
    background: #fff;
}

.card-title {
    margin-left: 15px;
}
</style>
