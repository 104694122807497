<template>
  <div>
    <div class="row">
      <div class="col mb-5">
        <InformacionTecnicaComponente
          v-if="rolModuloPermisoInformacionTecnica.lectura"
          :writeable="rolModuloPermisoInformacionTecnica.escritura == 1"
          :showTitle="true"
        />
      </div>
    </div>

    <ActividadesProyectoComponente
      v-if="rolModuloPermisoActividadesProyecto.lectura"
    />

    <SolicitudMaterialComponente
      v-if="rolModuloPermisoSolicitudMateriales.lectura"
      :writeable="rolModuloPermisoInformacionTecnica.escritura == 1"
      :showTitle="true"
    />

    <!-- <ComentariosComponente 
            v-if="rolModuloPermisoComentarios.lectura" 
            :writeable="rolModuloPermisoComentarios.escritura == 1"
            :showTitle="true"
        /> -->

    <DocumentosComponente
      v-if="rolModuloPermisoDocumentos.lectura"
      :writeable="rolModuloPermisoDocumentos.escritura == 1"
      :showTitle="true"
    />

    <!-- <ContactosComponente 
            v-if="rolModuloPermisoContactos.lectura"
            :writeable="rolModuloPermisoContactos.escritura == 1"
            :showTitle="true"
        /> -->
    <!-- <GanttComponente v-if="rolModuloPermisoGantt.lectura" /> -->

    <GaleriaComponente
      v-if="rolModuloPermisoImagenes.lectura"
      :writeable="rolModuloPermisoImagenes.escritura == 1"
      :showTitle="true"
      :max-height="300"
    />

    <!-- <UbicacionComponente 
            v-if="rolModuloPermisoUbicacion.lectura" 
            :writeable="rolModuloPermisoUbicacion.escritura == 1"
            :showTitle="true"
        /> -->
  </div>
</template>
<script>
import appConfig from "@/app.config";
import InformacionTecnicaComponente from "@/components/proyectos/lugarInstalacion/LugarInformacionTecnica.vue";

import ActividadesProyectoComponente from "./actividadProyecto/actividades-proyecto-componente.vue";
import ComentariosComponente from "@/components/proyectos/lugarInstalacion/LugarComentarios.vue";
import DocumentosComponente from "@/components/proyectos/lugarInstalacion/LugarDocumentos.vue";
import GaleriaComponente from "@/components/proyectos/lugarInstalacion/LugarGaleria.vue";
import GanttComponente from "./gantt/gantt-componente.vue";
import SolicitudMaterialComponente from "@/components/proyectos/lugarInstalacion/LugarSolicitudMaterial.vue";
// import InformeDiarioComponente from "./informeDiario/informe-diario-componente.vue";
import UbicacionComponente from "@/components/proyectos/lugarInstalacion/LugarUbicacion.vue";
import ContactosComponente from "@/components/proyectos/lugarInstalacion/LugarContactos.vue";
import {
  authMethods,
  authUsuarioComputed,
  lugarInstalacionMethods,
} from "@/state/helpers";
import { permiso } from "@/helpers/authservice/obtener-permisos";
export default {
  name: "InformacionCompletaComponent",
  page: {
    title: "Información Completa",
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },
  components: {
    InformacionTecnicaComponente,
    ActividadesProyectoComponente,
    ComentariosComponente,
    DocumentosComponente,
    GaleriaComponente,
    GanttComponente,
    // InformeDiarioComponente,
    SolicitudMaterialComponente,
    UbicacionComponente,
    ContactosComponente,
  },
  data() {
    return {
      title: `Información Completa - ${this.$route.params.codigoProyecto}`,
      items: [
        {
          text: "Proyectos",
          href: `/proyectos/proyectos`,
        },
        {
          text: "Lugares Instalación",
          href: `/proyectos/lugaresInstalacion/${this.$route.params.codigoProyecto}`,
        },
        {
          text: "Información Completa",
          active: true,
        },
      ],
      rolModuloPermisoImagenes: { lectura: false, escritura: false },
      rolModuloPermisoComentarios: { lectura: false, escritura: false },
      rolModuloPermisoUbicacion: { lectura: false, escritura: false },
      rolModuloPermisoInformacionTecnica: {
        lectura: false,
        escritura: false,
      },
      rolModuloPermisoLugarInstalacion: {
        lectura: false,
        escritura: false,
      },
      rolModuloPermisoContactos: { lectura: false, escritura: false },
      rolModuloPermisoActividadesProyecto: {
        lectura: false,
        escritura: false,
      },
      rolModuloPermisoDocumentos: { lectura: false, escritura: false },
      rolModuloPermisoSolicitudMateriales: {
        lectura: false,
        escritura: false,
      },
      rolModuloPermisoGantt: { lectura: false, escritura: false },
      lugarInstalacion: {},
      codigoProyecto: null,
      codigoLugarInstalacion: null,
    };
  },
  mounted() {
    this.codigoProyecto = this.$route.params.codigoProyecto;
    this.codigoLugarInstalacion = this.$route.params.codigoLugarInstalacion;
    this.obtenerLugarInstalacionProyecto();

    let permisosModulo = permiso.obtenerPermisos(this.user, "comentarios");
    if (permisosModulo == null) {
      this.rolModuloPermisoComentarios.lectura = false;
    } else {
      this.rolModuloPermisoComentarios = permisosModulo;
    }

    permisosModulo = permiso.obtenerPermisos(
      this.user,
      "ubicacionLugarInstalacion"
    );
    if (permisosModulo == null) {
      this.rolModuloPermisoUbicacion.lectura = false;
    } else {
      this.rolModuloPermisoUbicacion = permisosModulo;
    }
    permisosModulo = permiso.obtenerPermisos(this.user, "informacionTecnica");
    if (permisosModulo == null) {
      this.rolModuloPermisoInformacionTecnica.lectura = false;
    } else {
      this.rolModuloPermisoInformacionTecnica = permisosModulo;
    }
    permisosModulo = permiso.obtenerPermisos(this.user, "lugarInstalacion");
    if (permisosModulo == null) {
      this.rolModuloPermisoLugarInstalacion.lectura = false;
    } else {
      this.rolModuloPermisoLugarInstalacion = permisosModulo;
    }
    permisosModulo = permiso.obtenerPermisos(this.user, "contactos");
    if (permisosModulo == null) {
      this.rolModuloPermisoContactos.lectura = false;
    } else {
      this.rolModuloPermisoContactos = permisosModulo;
    }
    permisosModulo = permiso.obtenerPermisos(this.user, "actividadesProyecto");
    if (permisosModulo == null) {
      this.rolModuloPermisoActividadesProyecto.lectura = false;
    } else {
      this.rolModuloPermisoActividadesProyecto = permisosModulo;
    }
    permisosModulo = permiso.obtenerPermisos(this.user, "galeriaImagenes");
    if (permisosModulo == null) {
      this.rolModuloPermisoImagenes.lectura = false;
    } else {
      this.rolModuloPermisoImagenes = permisosModulo;
    }
    permisosModulo = permiso.obtenerPermisos(this.user, "documentos");
    if (permisosModulo == null) {
      this.rolModuloPermisoDocumentos.lectura = false;
    } else {
      this.rolModuloPermisoDocumentos = permisosModulo;
    }
    permisosModulo = permiso.obtenerPermisos(this.user, "solicitudMateriales");
    if (permisosModulo == null) {
      this.rolModuloPermisoSolicitudMateriales.lectura = false;
    } else {
      this.rolModuloPermisoSolicitudMateriales = permisosModulo;
    }
    permisosModulo = permiso.obtenerPermisos(this.user, "gantt");
    if (permisosModulo == null) {
      this.rolModuloPermisoGantt.lectura = false;
    } else {
      this.rolModuloPermisoGantt = permisosModulo;
    }
  },
  computed: {
    ...authUsuarioComputed,
  },
  methods: {
    ...lugarInstalacionMethods,
    obtenerLugarInstalacionProyecto() {
      this.loading = true;
      this.obtenerLugarInstalacion({
        codigoProyecto: this.codigoProyecto,
        codigoLugarInstalacion: this.codigoLugarInstalacion,
      })
        .then((res) => {
          this.lugarInstalacion = res.body;
          this.loading = false;
        })
        .catch((error) => {
          this.loading = false;
        });
    },
  },
};
</script>
<style scoped>
.col-informacion {
  width: 50%;
  flex-wrap: wrap;
}
@media (max-width: 1500px) {
  .d-flex {
    display: block !important;
  }
  .col-informacion {
    width: 100%;
    float: none;
    display: block !important;
  }
}
</style>
