<template>
  <v-card>
    <b-overlay :show="loadingPrivado" variant="white" rounded="sm">
      <div class="card">
        <div class="d-flex p-2" v-if="showTitle">
          <div class="card-title titulo-tarjetas">Documentos</div>
        </div>

        <div class="card-body">
          <b-alert
            :show="segundosMensajeActualizacion"
            dismissible
            :variant="mensaje.variant"
            @dismissed="segundosMensajeActualizacion = 0"
            @dismiss-count-down="actualizarSegundosMensajeActualizacion"
          >
            {{ mensaje.texto }}
          </b-alert>
          <div class="d-flex" v-if="rolModuloPermiso.escritura">
            <button class="btn btn-success col-auto" @click="abrirModalNuevo">
              <i class="mdi mdi-plus me-1"></i>
              Agregar Adjunto
            </button>
          </div>
          <!-- 
                      <h4 class="card-title">
                          Documento Tipo Proyecto Configuración
                      </h4> -->

          <div>
            <div class="row mt-4">
              <div class="col-sm-12 col-md-6">
                <div id="tickets-table_length" class="dataTables_length">
                  <label class="d-inline-flex align-items-center">
                    Mostrar&nbsp;
                    <b-form-select
                      class="form-select form-select-sm"
                      v-model="perPage"
                      size="sm"
                      :options="pageOptions"
                    ></b-form-select>
                  </label>
                </div>
              </div>

              <!-- Search -->
              <div class="col-sm-12 col-md-6">
                <div
                  id="tickets-table_filter"
                  class="dataTables_filter text-md-end"
                >
                  <label class="d-inline-flex align-items-center">
                    <b-form-input
                      v-model="filter"
                      type="search"
                      placeholder="Buscar..."
                      class="form-control"
                    ></b-form-input>
                  </label>
                </div>
              </div>
              <!-- End search -->
            </div>
            <div class="row mt-2">
              <div class="col-sm-12 col-md-3">
                <label for="filtrarTipoDocumento"
                  >Filtrar Área Responsable</label
                >
                <multiselect
                  @input="filtrarDocumentosTipoDocumento()"
                  v-model="filtroTipoDocumento"
                  placeholder="Área Responsable"
                  :options="sortedFiltroTipoDocumentos"
                  value="tipo_documento_id"
                  label="nombre_tipo_documento"
                  :searchable="true"
                  class="helo"
                ></multiselect>
              </div>
              <div class="col-sm-12 col-md-3">
                <label for="filtrarNombreDocumento"
                  >Filtrar Nombre Documento</label
                >
                <multiselect
                  @input="filtrarDocumentosNombreDocumento()"
                  v-model="filtroNombreDocumento"
                  :options="sortedFiltroNombreDocumentos"
                  placeholder="Nombre Documento"
                  value="nombre_documento_id"
                  label="nombre_documento"
                  :searchable="true"
                  class="helo"
                ></multiselect>
              </div>
              <div class="col-sm-12 col-md-3">
                <button
                  class="btn btn-primary"
                  style="margin-top: 27px"
                  @click="limpiarFiltro"
                >
                  Limpiar
                </button>
              </div>
            </div>
            <!-- Table -->
            <div class="table-responsive mb-0">
              <b-table
                id="tabla-documentos"
                class="datatables tabla-personalizada"
                :items="documentos"
                :fields="fields"
                responsive="sm"
                :per-page="perPage"
                :current-page="currentPage"
                :sort-by.sync="sortBy"
                :sort-desc.sync="sortDesc"
                :filter="filter"
                :filter-included-fields="filterOn"
                select-mode="single"
                selectable
                @filtered="onFiltered"
                empty-text="Sin documentos"
                empty-filtered-text="Sin resultados"
                show-empty
                :busy="documentosCargando"
              >
                <template #table-busy>
                  <div class="text-center text-danger my-2">
                    <b-spinner class="align-middle"></b-spinner>
                  </div>
                </template>
                <template
                  #cell(accion)="data"
                  v-if="rolModuloPermiso.escritura"
                >
                  <!-- <i
                                          class="bx bx-edit text-primary pointer icono-botom-tabla"
                                          @click="abrirModalEditar(data.item)"
                                      ></i> -->
                  <i
                    class="bx bx-trash text-primary pointer icono-botom-tabla"
                    @click="eliminarDocumentoLocal(data.item)"
                  ></i>
                </template>

                <template #cell(documento_id)="data">
                  <a target="_blank" :href="urlDocumento(data.item)">
                    <i
                      class="mdi h2 pointer me-2"
                      :class="seleccionarIconoDocumento(data.item.extension)"
                    ></i
                  ></a>
                </template>
                <template #cell(nombre_tipo_documento)="data">
                  <span class="badge bg-success font-size-13">{{
                    data.item.nombre_tipo_documento
                  }}</span>
                </template>
                <template
                  #cell(privado)="data"
                  v-if="rolModuloPermiso.escritura"
                >
                  <div class="form-check form-switch form-switch-lg mb-3">
                    <input
                      @click="actualizarEstadoPrivado(data.item)"
                      class="form-check-input"
                      type="checkbox"
                      :checked="data.item.privado"
                    />
                  </div>
                </template>
                <template #cell(fecha_limite)="data">
                  {{
                    data.item.fecha_limite != null
                      ? formatDate(data.item.fecha_limite)
                      : ""
                  }}
                </template>
                <template #cell(fecha_subida)="data">
                  {{ formatDate(data.item.fecha_subida) }}
                </template>
              </b-table>
            </div>
            <div class="row">
              <div class="col mt-5">
                <div
                  class="dataTables_paginate paging_simple_numbers float-end"
                >
                  <ul class="pagination pagination-rounded mb-0">
                    <!-- pagination -->
                    <b-pagination
                      v-model="currentPage"
                      :total-rows="totalRows"
                      :per-page="perPage"
                    ></b-pagination>
                  </ul>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-12">
                <div
                  style="
                    width: 100%;
                    height: 200px;
                    overflow-y: hidden;
                    overflow-x: auto;
                  "
                >
                  <div
                    :style="`width: ${
                      img_mini_square_width * documentos_solo_imagenes.length
                    }px;`"
                  >
                    <img
                      v-for="(item, i) in documentos_solo_imagenes"
                      :key="i"
                      :src="item"
                      class="img_mini_square"
                      :style="`width: ${img_mini_square_width}px; height: ${img_mini_square_height}px;`"
                      @click="() => showImg(i)"
                    />
                  </div>
                </div>
              </div>
              <vue-easy-lightbox
                :visible="visible"
                :imgs="documentos_solo_imagenes"
                :index="indexGalery"
                @hide="handleHide"
              ></vue-easy-lightbox>
            </div>
          </div>
          <div v-if="documentosCargando" class="py-5">
            <h5 class="text-center">Cargando documentos</h5>
          </div>
        </div>
      </div>

      <!-- <div class="row" v-if="filterOnlyImagesCPT.length > 0">
                  <div class="col-12 col-lg-6 mx-auto">
                      <v-carousel>
                  <v-carousel-item
                  v-for="(item,i) in filterOnlyImagesCPT"
                  :key="i"
                  :src="urlImagen(item.documento_id + item.extension)"
                  reverse-transition="fade-transition"
                  transition="fade-transition"
                  ></v-carousel-item>
              </v-carousel>
                  </div>
              </div> -->
    </b-overlay>

    <b-modal
      v-model="mostrarModal"
      title="Agregar Adjunto"
      hide-footer
      size="xl"
    >
      <loading-flux :value="loading"></loading-flux>

      <form @submit.prevent="submit">
        <div class="card-title-desc">
          {{ proyectoSeleccionado.nombre_proyecto }} -
          {{ lugarInstalacion.codigo_proyecto }} -
          {{ lugarInstalacion.codigo_lugar_instalacion }}
        </div>
        <div class="row">
          <div class="col-lg-6">
            <div class="mb-3">
              <label for="tipoDocumento">Área Responsable</label>
              <multiselect
                @input="filtrarNombreDocumentos()"
                v-model="$v.form.tipoDocumento.$model"
                placeholder="Seleccionar Área Responsable"
                :options="sortedTipoDocumentos"
                value="tipo_documento_id"
                label="nombre_tipo_documento"
                :searchable="true"
                class="helo"
                :class="{
                  'is-invalid': submitted && $v.form.tipoDocumento.$error,
                }"
              ></multiselect>
              <div
                v-if="submitted && !$v.form.tipoDocumento.required"
                class="invalid-feedback"
              >
                El campo es obligatorio.
              </div>
            </div>
          </div>

          <div class="col-lg-6">
            <div class="mb-3">
              <label for="nombreDocumento">Nombre Documento</label>
              <multiselect
                v-model="$v.form.nombreDocumento.$model"
                :options="sortedNombreDocumentos"
                placeholder="Seleccionar Documento"
                value="nombre_documento_id"
                label="nombre_documento"
                :searchable="true"
                :loading="nombreDocumentosCargando"
                open-direction="bottom"
                class="helo"
                :class="{
                  'is-invalid': submitted && $v.form.nombreDocumento.$error,
                }"
              ></multiselect>
              <div
                v-if="submitted && !$v.form.nombreDocumento.required"
                class="invalid-feedback"
              >
                El campo es obligatorio.
              </div>
            </div>
          </div>

          <div class="col-lg-6">
            <div class="mb-3">
              <label for="comentario">Comentario</label>
              <input
                id="comentario"
                v-model="$v.form.comentario.$model"
                type="text"
                class="form-control"
                placeholder="Comentario"
                :class="{
                  'is-invalid': submitted && $v.form.comentario.$error,
                }"
              />
              <div
                v-if="submitted && !$v.form.comentario.required"
                class="invalid-feedback"
              >
                El campo es obligatorio.
              </div>
            </div>
          </div>

          <div class="col-lg-6">
            <div
              class="mb-3"
              style="position: absolute; left: -9999999px; opacity: 0"
            >
              <!--Meanwhile-->
              <label for="fechaLimite">Fecha Limite</label>

              <v-menu
                v-model="menu2"
                :close-on-content-click="false"
                :nudge-right="40"
                transition="scale-transition"
                offset-y
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <div class="input-group mb-3">
                    <input
                      v-model="computedDateFormattedMomentjs"
                      append-icon="mdi-calendar"
                      readonly
                      v-bind="attrs"
                      v-on="on"
                      id="fechaLimite"
                      outlined
                      class="form-control"
                    />
                    <div class="input-group-append">
                      <span class="input-group-text"
                        ><v-icon>mdi-calendar</v-icon></span
                      >
                    </div>
                  </div>
                </template>
                <v-date-picker
                  v-model="$v.form.fechaLimite.$model"
                  @input="menu2 = false"
                  locale="es-cl"
                ></v-date-picker>
              </v-menu>

              <div
                v-if="submitted && !$v.form.fechaLimite.required"
                class="invalid-feedback"
              >
                El campo es obligatorio.
              </div>
            </div>
          </div>

          <div class="col-lg-6">
            <div class="mb-3">
              <label for="fileDocumento">Documento</label>
              <input
                class="form-control"
                type="file"
                name="fileDocumento"
                accept=".png, .jpg, .jpeg,.bmp,.txt,.doc,.docx,.xls,.xlsx,.pdf,.rar,.zip"
                @change="onFileChange($event)"
                :class="{
                  'is-invalid': submitted && documentoParaSubir == null,
                }"
              />
              <div
                v-if="submitted && documentoParaSubir == null"
                class="invalid-feedback"
              >
                El campo es obligatorio.
              </div>
              <!-- <div
                                  v-if="
                                      submitted && !$v.form.fechaLimite.required
                                  "
                                  class="invalid-feedback"
                              >
                                  El campo es obligatorio.
                              </div> -->
            </div>
          </div>

          <div class="col-lg-6" v-if="false">
            <div class="mb-3">
              <label for="privado">Privado</label>
              <div class="form-check form-switch form-switch-lg mb-3">
                <input
                  v-model="$v.form.privado.$model"
                  class="form-check-input"
                  type="checkbox"
                />
              </div>
            </div>
          </div>
        </div>
        <div class="text-end mt-3">
          <span v-if="loadingChunked"> Subiendo archivo </span>
          <b-spinner class="mx-2" v-if="loadingChunked" small variant="primary">
          </b-spinner>
          <span v-if="percentageUploadedChunked">
            {{ percentageUploadedChunked.toFixed(0) }} %
          </span>
          <b-button variant="light" @click="mostrarModal = false"
            >Cerrar</b-button
          >
          <b-button type="submit" variant="success" class="ms-1"
            >Guardar</b-button
          >
        </div>
      </form>
    </b-modal>
  </v-card>
</template>
<script>
import { required } from "vuelidate/lib/validators";
import { validationMixin } from "vuelidate";
import { documentoMethods, documentoComputed } from "@/state/helpers";
import { tipoDocumentoMethods, tipoDocumentoComputed } from "@/state/helpers";
import {
  nombreDocumentoMethods,
  nombreDocumentoComputed,
} from "@/state/helpers";
import { proyectoMethods, proyectoComputed } from "@/state/helpers";
import Multiselect from "vue-multiselect";
import Swal from "sweetalert2";
import { authMethods, authUsuarioComputed } from "@/state/helpers";
import { permiso } from "@/helpers/authservice/obtener-permisos";
import moment from "moment";
import VueEasyLightbox from "vue-easy-lightbox";
import { mapActions, mapGetters } from "vuex";
moment.locale("es");
export default {
  mixins: [validationMixin],
  components: {
    Multiselect,
    VueEasyLightbox,
  },
  props: {
    writeable: {
      type: Boolean,
      default: false,
    },
    showTitle: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    const now = new Date();
    const today = new Date(now.getFullYear(), now.getMonth(), now.getDate());
    const minDate = new Date(today);

    return {
      img_mini_square_width: 250,
      img_mini_square_height: 180,
      visible: false,
      documentos_solo_imagenes: [],
      indexGalery: 0,
      date: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
        .toISOString()
        .substr(0, 10),
      menu: false,
      modal: false,
      menu2: false,
      imagesExtensions: [".jpeg", ".jpg", ".png"],
      loading: false,
      loadingPrivado: false,
      mensaje: { variant: "", texto: "" },
      segundos: 5,
      segundosMensajeActualizacion: 0,
      lugarInstalacion: {},
      proyectos: [],
      totalRows: 1,
      todoTotalRows: 1,
      currentPage: 1,
      todocurrentPage: 1,
      perPage: 10,
      todoperPage: 10,
      pageOptions: [10, 25, 50, 100],
      todoOptions: [10, 50, 100, 150, 200],
      filter: null,
      todoFilter: null,
      filterOn: [],
      todofilterOn: [],
      sortBy: "fecha_subida",
      sortDesc: true,
      fields: [
        {
          key: "Accion",
          sortable: false,
        },
        {
          key: "documento_id",
          label: "Documento",
          sortable: false,
        },
        {
          key: "nombre_tipo_documento",
          sortable: true,
        },
        {
          key: "nombre_documento",
          sortable: true,
        },
        {
          key: "comentario",
          sortable: true,
        },
        {
          key: "privado",
          sortable: true,
        },
        {
          key: "fecha_subida",
          sortable: true,
        },
      ],
      codigoProyecto: null,
      codigoLugarInstalacion: null,
      guardarCerrar: true,
      documentoSeleccionado: {},
      documentos: [],
      esEditar: false,
      submitted: false,
      mostrarModal: false,
      tipoDocumentos: [],
      nombreDocumentos: [],
      tipoDocumentosCargando: false,
      nombreDocumentosCargando: false,
      documentosCargando: false,
      fechaLimiteValidar: null,
      documentoParaSubir: null,
      maxBytesSizeFile: 2000000000,
      minSizeToChunkFile: 100000000,
      form: {
        tipoDocumento: null,
        nombreDocumento: null,
        comentario: null,
        privado: null,
        fechaLimite: null,
      },
      rolModuloPermiso: {},
      filtroTipoDocumento: "",
      filtroTipoDocumentos: [],
      filtroNombreDocumento: "",
      filtroNombreDocumentos: [],
      min: minDate,
      proyectoSeleccionado: {},
    };
  },
  validations: {
    form: {
      tipoDocumento: { required },
      nombreDocumento: { required },
      comentario: {},
      privado: {},
      fechaLimite: {},
    },
  },
  mounted() {
    if (this.proyectoUsuario == null) {
      this.obtenerProyectoUsuario();
    } else {
      this.proyectoSeleccionado = this.proyectoUsuario;
    }

    this.codigoProyecto = this.$route.params.codigoProyecto;
    this.codigoLugarInstalacion = this.$route.params.codigoLugarInstalacion;
    this.lugarInstalacion = {
      codigo_lugar_instalacion: this.codigoLugarInstalacion,
      codigo_proyecto: this.codigoProyecto,
    };
    this.obtenerDocumentosLocal();
    this.obtenerNombreDocumentosLocal();
    this.obtenerTipoDocumentosFiltro();
    this.obtenerNombreDocumentosFiltro();
    let permisos = permiso.obtenerPermisos(
      this.user,
      this.$router.currentRoute.name
    );
    this.rolModuloPermiso = permisos;
  },
  methods: {
    ...documentoMethods,
    ...tipoDocumentoMethods,
    ...nombreDocumentoMethods,
    ...proyectoMethods,
    ...mapActions({
      uploadDocumentFromPlace: "document/uploadDocumentFromPlace",
      uploadDocumentChunckedFromPlace:
        "document/uploadDocumentChunckedFromPlace",
    }),
    showImg(index) {
      this.indexGalery = index;
      this.visible = true;
    },
    handleHide() {
      this.visible = false;
    },
    getKeyByValue(object, value) {
      return Object.keys(object).find((key) => object[key] === value);
    },
    urlImagen({ document, image }) {
      return `${process.env.VUE_APP_URL_DOCUMENTOS}${image}`;
    },
    actualizarSegundosMensajeActualizacion(segundosMensajeActualizacion) {
      this.segundosMensajeActualizacion = segundosMensajeActualizacion;
    },
    urlDocumento(item) {
      return `${process.env.VUE_APP_URL_DOCUMENTOS}${item.documento_id}${item.extension}`;
    },
    descargarDocumento() {},
    onFileChange(e) {
      const file = e.target.files[0];

      if (typeof FileReader === "function") {
        const reader = new FileReader();
        this.documentoParaSubir = file;
        reader.readAsDataURL(file);
      } else {
        alert("Sorry, FileReader API not supported");
      }
    },
    abrirModalNuevo() {
      this.resetForm();
      this.esEditar = false;
      this.submitted = false;
      this.mostrarModal = true;
      this.obtenerTipoDocumentosLocal();
    },
    abrirModalEditar(datos) {
      this.documentoSeleccionado = datos;
      this.resetForm();
      this.obtenerTipoDocumentosLocal();
      this.obtenerNombreDocumentosLocal();

      this.$v.form.comentario.$model = datos.comentario;
      this.$v.form.privado.$model = datos.privado;
      this.$v.form.fechaLimite.$model = datos.fecha_limite;

      this.esEditar = true;
      this.submitted = false;
      this.mostrarModal = true;
    },
    obtenerProyectoUsuario() {
      this.obtenerProyecto(this.$route.params.codigoProyecto)
        .then((res) => {
          this.proyectoSeleccionado = res.body;
        })
        .catch((error) => {});
    },
    limpiarFiltro() {
      this.obtenerDocumentosLocal();
      this.filtroTipoDocumento = null;
      this.filtroNombreDocumento = null;
    },
    filtrarDocumentosTipoDocumento() {
      this.documentosCargando = true;

      if (this.filtroTipoDocumento.tipo_documento_id) {
        this.filtrarNombreDocumentosFiltro();
      }
      if (this.filtroTipoDocumento.tipo_documento_id == 0) {
        this.obtenerDocumentosLocal();
      } else {
        this.obtenerDocumentosTipoDocumento({
          codigoLugarInstalacion: this.codigoLugarInstalacion,
          codigoProyecto: this.codigoProyecto,
          tipo_documento_id: this.filtroTipoDocumento.tipo_documento_id,
        })
          .then((res) => {
            this.documentos = res.body;
            this.totalRows = this.documentos.length;
            this.documentosCargando = false;
            this.documentos_solo_imagenes = this.filterOnlyImagesCPT;
          })
          .catch((error) => {
            this.documentosCargando = false;
          });
      }
    },
    filtrarDocumentosNombreDocumento() {
      this.documentosCargando = true;

      if (this.filtroNombreDocumento.nombre_documento_id == 0) {
        this.obtenerDocumentosLocal();
      } else {
        this.obtenerDocumentosNombreDocumento({
          codigoLugarInstalacion: this.codigoLugarInstalacion,
          codigoProyecto: this.codigoProyecto,
          nombre_documento_id: this.filtroNombreDocumento.nombre_documento_id,
        })
          .then((res) => {
            this.documentos = res.body;
            this.totalRows = this.documentos.length;
            this.documentosCargando = false;
            this.documentos_solo_imagenes = this.filterOnlyImagesCPT;
          })
          .catch((error) => {
            this.documentosCargando = false;
          });
      }
    },
    obtenerDocumentosLocal() {
      this.documentosCargando = true;
      this.obtenerDocumentos({
        codigoLugarInstalacion: this.codigoLugarInstalacion,
        codigoProyecto: this.codigoProyecto,
      })
        .then((res) => {
          if (this.user.codigo_rol == "CLIENTE") {
            this.documentos = res.body.filter((val) => val.privado == false);
          } else {
            this.documentos = res.body;
          }
          this.totalRows = this.documentos.length;
          this.documentosCargando = false;
          this.$v.form.nombreDocumento.$model = {
            nombre_documento_id: this.documentoSeleccionado.nombre_documento_id,
            nombre_documento: this.documentoSeleccionado.nombre_documento,
          };
          this.documentos_solo_imagenes = this.filterOnlyImagesCPT;
        })
        .catch((error) => {
          this.documentosCargando = false;
        });
    },
    obtenerTipoDocumentosLocal() {
      this.tipoDocumentosCargando = true;
      this.obtenerTipoDocumentos()
        .then((res) => {
          this.tipoDocumentos = res.body;
          if (this.esEditar) {
            this.$v.form.tipoDocumento.$model = {
              tipo_documento_id: this.documentoSeleccionado.tipo_documento_id,
              nombre_tipo_documento:
                this.documentoSeleccionado.nombre_tipo_documento,
            };
          }
          this.tipoDocumentosCargando = false;
        })
        .catch((error) => {
          this.tipoDocumentosCargando = false;
        });
    },
    obtenerTipoDocumentosFiltro() {
      this.tipoDocumentosCargando = true;
      this.obtenerTipoDocumentos()
        .then((res) => {
          this.filtroTipoDocumentos.unshift({
            tipo_documento_id: 0,
            nombre_tipo_documento: "Seleccionar Área Responsable",
          });
          res.body.map((item) => {
            this.filtroTipoDocumentos.push(item);
          });
          this.tipoDocumentosCargando = false;
        })
        .catch((error) => {
          this.tipoDocumentosCargando = false;
        });
    },
    obtenerNombreDocumentosLocal() {
      this.nombreDocumentosCargando = true;
      this.obtenerNombreDocumentos()
        .then((res) => {
          this.nombreDocumentos = res.body;
          this.nombreDocumentosCargando = false;
          this.$v.form.nombreDocumento.$model = {
            nombre_documento_id: this.documentoSeleccionado.nombre_documento_id,
            nombre_documento: this.documentoSeleccionado.nombre_documento,
          };
        })
        .catch((error) => {
          this.nombreDocumentosCargando = false;
        });
    },
    obtenerNombreDocumentosFiltro() {
      this.nombreDocumentosCargando = true;
      this.obtenerNombreDocumentos()
        .then((res) => {
          this.filtroNombreDocumentos.unshift({
            nombre_documento_id: 0,
            nombre_documento: "Seleccionar Nombre Documento",
          });
          res.body.map((item) => {
            this.filtroNombreDocumentos.push(item);
          });
        })
        .catch((error) => {
          this.nombreDocumentosCargando = false;
        });
    },
    filtrarNombreDocumentos() {
      this.obtenerNombreDocumentosPorTipoDocumento(
        this.$v.form.tipoDocumento.$model.tipo_documento_id
      )
        .then((res) => {
          this.nombreDocumentos = res.body;
          this.nombreDocumentosCargando = false;
        })
        .catch((error) => {
          this.nombreDocumentosCargando = false;
        });
    },
    filtrarNombreDocumentosFiltro() {
      this.filtroNombreDocumentos = [];
      this.obtenerNombreDocumentosPorTipoDocumento(
        this.filtroTipoDocumento.tipo_documento_id
      )
        .then((res) => {
          this.filtroNombreDocumentos.unshift({
            nombre_documento_id: 0,
            nombre_documento: "Seleccionar Nombre Documento",
          });
          res.body.map((item) => {
            this.filtroNombreDocumentos.push(item);
          });
        })
        .catch((error) => {
          this.nombreDocumentosCargando = false;
        });
    },
    actualizarEstadoPrivado(item) {
      this.loadingPrivado = true;
      this.actualizarPrivadoDocumento({ documento_id: item.documento_id })
        .then((res) => {
          if (res.status == 202) {
            this.mensaje.variant = "danger";
            this.mensaje.texto = "Ha ocurrido un error, intente nuevamente";
          }

          if (res.status == 204) {
            this.mensaje.variant = "success";
            this.mensaje.texto = "Documento actualizado correctamente!!!";
            this.mostrarModal = false;
          }

          this.segundosMensajeActualizacion = this.segundos;
          this.obtenerDocumentosLocal();
          this.loadingPrivado = false;
        })
        .catch((error) => {
          this.mensaje.variant = "danger";
          this.mensaje.texto = "Ha ocurrido un error, intente nuevamente";
          this.mostrarModal = false;
          this.segundosMensajeActualizacion = this.segundos;
          this.loadingPrivado = false;
        });
    },

    submit(e) {
      if (this.documentoParaSubir?.size > this.maxBytesSizeFile) {
        this.mensaje.variant = "danger";
        this.mensaje.texto = "Ha ocurrido un error, intente nuevamente";
        this.segundosMensajeActualizacion = this.segundos;
        return;
      }
      this.submitted = true;
      this.$v.form.$touch();
      if (this.$v.form.$invalid) {
        this.loading = false;
        return;
      }
      if (this.documentoParaSubir == null) {
        this.loading = false;
        return;
      }
      let documento_id = "";
      if (this.esEditar) {
        documento_id = this.documentoSeleccionado.documento_id;
      }
      let formData = new FormData();
      const codigo_proyecto = this.codigoProyecto;
      const codigo_lugar_instalacion = this.codigoLugarInstalacion;
      const tipo_documento_id =
        this.$v.form.tipoDocumento.$model.tipo_documento_id;
      const nombre_tipo_documento =
        this.$v.form.tipoDocumento.$model.nombre_tipo_documento;
      const nombre_documento_id =
        this.$v.form.nombreDocumento.$model.nombre_documento_id;
      const nombre_documento =
        this.$v.form.nombreDocumento.$model.nombre_documento;
      const comentario = this.$v.form.comentario.$model;
      const fecha_limite = `${new Date().toISOString().split("T")[0]}`;
      const fecha_subida = fecha_limite;
      const privado = 1;
      let payload = {
        documento_id,
        codigo_proyecto,
        codigo_lugar_instalacion,
        tipo_documento_id,
        nombre_tipo_documento,
        nombre_documento_id,
        nombre_documento,
        comentario,
        fecha_limite,
        fecha_subida,
        privado,
      };
      if (this.documentoParaSubir.size <= this.minSizeToChunkFile) {
        this.loading = true;
        formData.append("file", this.documentoParaSubir);
        formData.append("body", JSON.stringify(payload));
      }
      if (this.esEditar) {
        if (this.documentoParaSubir == null) {
          this.actualizarSinDocumento({
            documento_id,
            codigo_proyecto,
            codigo_lugar_instalacion,
            tipo_documento_id,
            nombre_tipo_documento,
            nombre_documento_id,
            nombre_documento,
            comentario,
            fecha_limite,
            fecha_subida,
            privado,
          })
            .then((res) => {
              if (res.status == 202) {
                this.mensaje.variant = "danger";
                this.mensaje.texto = "Ha ocurrido un error, intente nuevamente";
              }

              if (res.status == 204) {
                this.mensaje.variant = "success";
                this.mensaje.texto = "Documento actualizado correctamente!!!";
                this.mostrarModal = false;
              }

              this.segundosMensajeActualizacion = this.segundos;
              this.obtenerDocumentosLocal();
              this.loading = false;
            })
            .catch((error) => {
              this.mensaje.variant = "danger";
              this.mensaje.texto = "Ha ocurrido un error, intente nuevamente";
              this.mostrarModal = false;
              this.segundosMensajeActualizacion = this.segundos;
              this.loading = false;
            });
        } else {
          this.actualizarDocumento(formData)
            .then((res) => {
              if (res.status == 202) {
                this.mensaje.variant = "danger";
                this.mensaje.texto = "Ha ocurrido un error, intente nuevamente";
              }

              if (res.status == 204) {
                this.mensaje.variant = "success";
                this.mensaje.texto = "Documento actualizado correctamente!!!";
                this.mostrarModal = false;
              }

              this.segundosMensajeActualizacion = this.segundos;
              this.obtenerDocumentosLocal();
              this.loading = false;
            })
            .catch((error) => {
              this.mensaje.variant = "danger";
              this.mensaje.texto = "Ha ocurrido un error, intente nuevamente";
              this.mostrarModal = false;
              this.segundosMensajeActualizacion = this.segundos;
              this.loading = false;
            });
        }
      } else {
        if (this.documentoParaSubir.size <= this.minSizeToChunkFile) {
          this.uploadDocumentFromPlace(formData)
            .then((res) => {
              if (res.status == 200) {
                this.mensaje.variant = "success";
                this.mensaje.texto = "Documento creado correctamente!!!";
                this.mostrarModal = false;
              }

              if (res.status == 208) {
                this.mensaje.variant = "danger";
                this.mensaje.texto = "El documento ya existe!!!";
              }

              this.segundosMensajeActualizacion = this.segundos;
              this.obtenerDocumentosLocal();
              this.loading = false;
            })
            .catch((error) => {
              this.mensaje.variant = "danger";
              this.mensaje.texto = "Ha ocurrido un error, intente nuevamente";

              this.segundosMensajeActualizacion = this.segundos;
              this.loading = false;
            });
        } else {
          this.uploadDocumentChunckedFromPlace({
            ...payload,
            file: this.documentoParaSubir,
          })
            .then((res) => {
              if (res.status == 200) {
                this.mensaje.variant = "success";
                this.mensaje.texto = "Documento creado correctamente!!!";
                this.mostrarModal = false;
              }

              if (res.status == 208) {
                this.mensaje.variant = "danger";
                this.mensaje.texto = "El documento ya existe!!!";
              }

              this.segundosMensajeActualizacion = this.segundos;
              this.obtenerDocumentosLocal();
              this.loading = false;
            })
            .catch((error) => {
              this.mensaje.variant = "danger";
              this.mensaje.texto = "Ha ocurrido un error, intente nuevamente";

              this.segundosMensajeActualizacion = this.segundos;
              this.loading = false;
            });
        }
      }

      this.submitted = false;
    },
    resetForm() {
      this.form = {
        tipoDocumento: null,
        nombreDocumento: null,
        comentario: null,
        privado: null,
        fechaLimite: null,
      };
      this.documentoParaSubir = null;
      this.$nextTick(() => {
        this.$v.$reset();
      });
    },
    eliminarDocumentoLocal(item) {
      const mensajeEliminar = Swal.mixin({
        customClass: {
          title: "font-size-18",
          confirmButton: "btn btn-danger ms-2",
          cancelButton: "btn btn-gris-claro",
        },
        buttonsStyling: false,
      });
      mensajeEliminar
        .fire({
          title: "Esta seguro que desea eliminar el registro?",
          text: "",
          icon: "warning",
          showCancelButton: true,
          confirmButtonText: "Eliminar",
          cancelButtonText: "Cancelar",
          allowOutsideClick: false,
          reverseButtons: true,
        })
        .then((result) => {
          if (result.value) {
            this.eliminarDocumento(item.documento_id)
              .then((res) => {
                if (res.status == 202) {
                  this.mensaje.variant = "danger";
                  this.mensaje.texto =
                    "Ha ocurrido un error, intente nuevamente";
                }

                if (res.status == 204) {
                  this.mensaje.variant = "success";
                  this.mensaje.texto = "Documento eliminada correctamente!!!";
                  if (this.guardarCerrar) {
                    this.mostrarModal = false;
                  }
                }
                this.segundosMensajeActualizacion = this.segundos;
                this.obtenerDocumentosLocal();
              })
              .catch((error) => {
                this.mensaje.variant = "danger";
                this.mensaje.texto =
                  "No se ha eliminado el registro, intente nuevamente";
                this.mostrarModal = false;
                this.segundosMensajeActualizacion = this.segundos;
              });
          }
        });
    },
    seleccionarIconoDocumento(extension) {
      if (extension == ".doc" || extension == ".docx") {
        return "mdi-file-word text-primary";
      } else if (
        extension == ".xls" ||
        extension == ".xlsx" ||
        extension == ".csv"
      ) {
        return "mdi-file-excel text-success";
      } else if (extension == ".pdf") {
        return "mdi-file-pdf text-danger";
      } else if (extension == ".zip") {
        return "mdi-folder-zip text-warning";
      } else if (extension == ".doc" || extension == ".docx") {
        return "mdi-file-document text-primary";
      } else if (
        extension == ".jpg" ||
        extension == ".jpeg" ||
        extension == ".png" ||
        extension == ".bmp"
      ) {
        return "mdi-image-area text-danger";
      } else {
        return "mdi-file";
      }
    },
    irLugarinstalacion(proyecto) {
      this.seleccionarProyecto(proyecto[0]);
      this.$router.push({
        name: "lugaresInstalacion",
        params: { codigoProyecto: proyecto[0].codigo_proyecto },
      });
    },
    formatDate(value) {
      const duration = moment(value).format("DD/MM/yyyy");
      return `${duration}`;
    },
    onFiltered(filteredItems) {
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },

    todoFiltered(filteredItems) {
      this.todoTotalRows = filteredItems.length;
      this.todocurrentPage = 1;
    },

    handleInput(value, data) {
      this.editableDataItems[data.index][data.field.key] = value;
    },
  },
  computed: {
    ...proyectoComputed,
    ...authUsuarioComputed,
    ...mapGetters({
      loadingChunked: "document/loadingChunked",
      percentageUploadedChunked: "document/percentageUploadedChunked",
    }),
    filterOnlyImagesCPT() {
      let result = [];
      if (this.documentos) {
        //result = this.documentos.filter( x => this.imagesExtensions.includes(x.extension));
        this.documentos.forEach((value, index) => {
          if (this.imagesExtensions.includes(value.extension)) {
            result.push(
              this.urlImagen({
                document: value,
                image: `${value.documento_id}${value.extension}`,
              })
            );
          }
        });
      }

      return result;
    },
    computedDateFormattedMomentjs() {
      return this.$v.form.fechaLimite.$model
        ? moment(this.$v.form.fechaLimite.$model).format("dddd, DD MMMM YYYY")
        : "";
    },
    sortedFiltroTipoDocumentos() {
      return this.filtroTipoDocumentos
        .sort((a, b) => {
          if (
            a.nombre_tipo_documento.toLowerCase() <
            b.nombre_tipo_documento.toLowerCase()
          )
            return -1;
          if (
            a.nombre_tipo_documento.toLowerCase() >
            b.nombre_tipo_documento.toLowerCase()
          )
            return 1;
          return 0;
        })
        .sort((a, b) => {
          if (a.tipo_documento_id == 0) return -1;
          if (b.tipo_documento_id == 0) return 1;
          return 0;
        });
    },
    sortedFiltroNombreDocumentos() {
      return this.filtroNombreDocumentos
        .sort((a, b) => {
          if (
            a.nombre_documento.toLowerCase() < b.nombre_documento.toLowerCase()
          )
            return -1;
          if (
            a.nombre_documento.toLowerCase() > b.nombre_documento.toLowerCase()
          )
            return 1;
          return 0;
        })
        .sort((a, b) => {
          if (a.nombre_documento_id == 0) return -1;
          if (b.nombre_documento_id == 0) return 1;
          return 0;
        });
    },
    sortedTipoDocumentos() {
      return [...this.tipoDocumentos].sort((a, b) => {
        if (
          a.nombre_tipo_documento.toLowerCase() <
          b.nombre_tipo_documento.toLowerCase()
        )
          return -1;
        if (
          a.nombre_tipo_documento.toLowerCase() >
          b.nombre_tipo_documento.toLowerCase()
        )
          return 1;
        return 0;
      });
    },
    sortedNombreDocumentos() {
      return [...this.nombreDocumentos].sort((a, b) => {
        if (a.nombre_documento.toLowerCase() < b.nombre_documento.toLowerCase())
          return -1;
        if (a.nombre_documento.toLowerCase() > b.nombre_documento.toLowerCase())
          return 1;
        return 0;
      });
    },
  },
};
</script>

<style scoped>
.img_mini_square {
  cursor: pointer;
  object-fit: cover;
  -webkit-box-shadow: 0px 0px 9px 0px rgb(0 0 0 / 16%);
  -moz-box-shadow: 0px 0px 9px 0px rgba(0, 0, 0, 0.16);
  box-shadow: 0px 0px 9px 0px rgb(0 0 0 / 16%);
  float: left;
}
</style>
