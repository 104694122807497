<template>
  <section>
    <v-dialog
      v-model="modalAction"
      max-width="1400px"
      scrollable
      @keydown="executeCloseModalListMaterialsRequest"
      @click:outside="executeCloseModalListMaterialsRequest"
    >
      <v-card>
        <div class="p-4 pb-0">
          <h5>Detalles de la Solicitud</h5>
        </div>
        <hr />
        <v-spacer></v-spacer>
        <v-card-text>
          <v-container class="px-1">
            <v-row align-content="center" v-if="currentRequestMaterials">
              <v-col cols="12" md="4" sm="12">
                <v-card-subtitle class="pb-0 font-weight-bold">
                  CeCo / Proyecto
                </v-card-subtitle>
                <v-card-text class="text--primary mt-1">
                  <span>
                    {{
                      currentRequestMaterials.project
                        ? currentRequestMaterials.project.code
                        : currentRequestMaterials.cost_center.sap_code
                    }}
                  </span>
                </v-card-text>
              </v-col>
              <v-col cols="12" md="5" sm="12">
                <v-card-subtitle class="pb-0 font-weight-bold">
                  Usuario que Solicita
                </v-card-subtitle>
                <v-card-text class="text--primary mt-1">
                  <span
                    v-html="
                      getUserFromEmail(currentRequestMaterials.created_by)
                    "
                  >
                  </span>
                </v-card-text>
              </v-col>
              <v-col cols="12" md="3" sm="12">
                <v-card-subtitle class="pb-0 pt-3 ml-1 font-weight-bold">
                  Estado de la Solicitud
                </v-card-subtitle>
                <v-card-text class="text--primary">
                  <v-chip
                    :color="getColorStatus(currentRequestMaterials)"
                    class="text-white mt-1"
                    small
                    >{{
                      currentRequestMaterials.cancelled
                        ? "Anulada"
                        : currentRequestMaterials.rejected
                        ? "Rechazada"
                        : currentRequestMaterials.sent_to_erp
                        ? "Aprobada"
                        : "Pendiente"
                    }}</v-chip
                  >
                </v-card-text>
              </v-col>
              <v-col
                cols="12"
                md="4"
                sm="12"
                v-if="currentRequestMaterials.sap_stage"
              >
                <v-card-subtitle class="pb-0 font-weight-bold">
                  Etapa del Proyecto
                </v-card-subtitle>
                <v-card-text class="text--primary mt-1">
                  <span>
                    {{ currentRequestMaterials.sap_stage }}
                  </span>
                </v-card-text>
              </v-col>
            </v-row>
            <v-tabs v-model="tab" fixed-tabs class="mt-4">
              <v-tab key="materials">
                {{
                  currentRequestMaterials && currentRequestMaterials.is_service
                    ? "Servicios"
                    : "Materiales"
                }}
              </v-tab>
              <v-tab
                key="approval"
                v-if="
                  currentRequestMaterials && !currentRequestMaterials.rejected
                "
                id="approvalList"
              >
                Aprobación de la Solicitud
              </v-tab>
              <v-tab
                key="approval-restrictions"
                v-if="
                  currentRequestMaterials &&
                    !currentRequestMaterials.rejected &&
                    currentRequestMaterials.restriction_approvers.length
                "
              >
                Aprobaciones de restricción por limitación de SMU
              </v-tab>
              <v-tab
                key="reason-reject"
                v-if="
                  currentRequestMaterials && currentRequestMaterials.rejected
                "
              >
                Motivo de Rechazo de la Solicitud
              </v-tab>
            </v-tabs>
            <v-tabs-items v-model="tab" v-if="currentRequestMaterials">
              <v-tab-item key="materials" class="pt-3 my-5">
                <div
                  v-if="permissionToApprove"
                  class="text-danger mb-5 text-center border w-50 mx-auto pt-3 pb-1 border-danger border-2"
                >
                  <b>IMPORTANTE</b>
                  <ul
                    class="text-center mt-3"
                    v-if="!ifCurrentRequestOfService"
                  >
                    <span class="w-50 mx-auto">
                      Recuerda que puedes <b>MODIFICAR</b> la solicitud
                    </span>
                    <br />
                    <span class="w-50 mx-auto">
                      Si realizó cambios a la solicitud, al finalizar presione
                      el botón
                      <b>ACTUALIZAR SOLICITUD</b>
                    </span>
                  </ul>
                  <ul class="text-center mt-3" v-else>
                    <li class="mx-8 mb-2">
                      Recuerda que puedes eliminar los servicios de la solicitud
                    </li>
                    <li class="mx-8">
                      Si cotizaste en una divisa distinta al peso chileno,
                      recuerda que el monto total puede variar según el día en
                      que se apruebe la solicitud
                    </li>
                  </ul>
                </div>
                <v-row align-content="center">
                  <v-col
                    cols="12"
                    md="10"
                    sm="12"
                    class="ml-5 pb-0"
                    v-if="!ifCurrentRequestOfService"
                  >
                    <label for="dispatch">
                      Tipo de Solicitud de Materiales
                    </label>
                    <v-select
                      :disabled="!permissionToApprove"
                      clearable
                      @change="
                        updateValueCurrentRequest($event, 'request_type')
                      "
                      :items="typeRequests.map((item) => item.name)"
                      class="pt-0 w-50"
                      :value="
                        currentRequestMaterials
                          ? currentRequestMaterials.request_type_original
                          : null
                      "
                    ></v-select>
                  </v-col>
                  <v-col cols="12" md="4" sm="12">
                    <v-text-field
                      label="Buscar"
                      v-model="filters.filter"
                      clearable
                      class="ml-5"
                      placeholder="Buscar material"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" md="4" sm="12"> </v-col>
                  <v-col cols="12" md="4" sm="12">
                    <v-btn
                      v-if="permissionToApprove && !ifCurrentRequestOfService"
                      small
                      outlined
                      class="float-right mt-5"
                      color="primary text-white"
                      @click="openModalTableAddMaterials"
                    >
                      Agregar Materiales
                    </v-btn>
                  </v-col>
                </v-row>
                <v-data-table
                  class="px-2 elevation-4 py-3"
                  :headers="wrappedTableHeaders"
                  :items="filteredMaterials"
                  :items-per-page="
                    filters.items !== 'Todos' ? filters.items : 100
                  "
                  :page.sync="filters.page"
                  hide-default-footer
                  :loading="loading"
                  loading-text="Cargando Materiales"
                  item-key="id"
                  no-data-text="Sin materiales"
                  @page-count="pageCount = $event"
                >
                  <template v-slot:item.code="{ item }">
                    <p class="my-0 text-left">
                      {{ item.code }}
                    </p>
                  </template>
                  <template v-slot:item.description="{ item }">
                    <p class="my-0 text-left">
                      {{
                        item.description
                          ? item.description
                          : "Cargando Material.."
                      }}
                    </p>
                  </template>
                  <template v-slot:item.price="{ item }">
                    <span>
                      {{
                        "$ " +
                          item.price.toLocaleString("es", optionsFormatting)
                      }}
                      {{ (item.option != "Precio Real" && !currentRequestMaterials.is_service ) ? "*" : "" }}
                    </span>
                  </template>
                  <template v-slot:item.subtotal="{ item }">
                    <span>
                      {{
                        "$ " +
                          getSubtotalPrice(item).toLocaleString(
                            "es",
                            optionsFormatting
                          )
                      }}
                    </span>
                  </template>
                  <template v-slot:item.quantity="{ item }">
                    <v-text-field
                      v-if="
                        permissionToApprove &&
                          !currentRequestHaveApproversWithApproved &&
                          (!ifCurrentRequestOfService ||
                            codeProductIsActive(item.code))
                      "
                      type="number"
                      :min="1"
                      class="pt-0 mt-0"
                      @input="
                        updateValueMaterialRequestApprove(
                          $event,
                          item,
                          'quantity'
                        )
                      "
                      :value="item.quantity"
                      hide-details
                    ></v-text-field>
                    <p v-else class="my-0 text-right">
                      {{ item.quantity }}
                    </p>
                  </template>
                  <template v-slot:item.actions="{ item }">
                    <v-tooltip top>
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          @click="deleteMaterial(item)"
                          icon
                          small
                          color="red"
                          v-bind="attrs"
                          v-on="on"
                        >
                          <v-icon> mdi-delete-outline </v-icon>
                        </v-btn>
                      </template>
                      <span
                        >Eliminar
                        {{
                          !ifCurrentRequestOfService ? "Material" : "Servicio"
                        }}</span
                      >
                    </v-tooltip>
                    <v-tooltip top>
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          v-if="ifCurrentRequestOfService"
                          @click="openModalFilesUploadService(item)"
                          icon
                          small
                          class="mx-1"
                          v-bind="attrs"
                          v-on="on"
                        >
                          <v-badge
                            bordered
                            :color="item.documents.length ? 'green' : 'red'"
                            :content="
                              item.documents.length
                                ? item.documents.length
                                : '0'
                            "
                            overlap
                          >
                            <v-icon> mdi-file-multiple-outline </v-icon>
                          </v-badge>
                        </v-btn>
                      </template>
                      <span>Documentos del Servicio</span>
                    </v-tooltip>
                    <v-tooltip top>
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          v-if="ifCurrentRequestOfService"
                          @click="openModalCommentsService(item)"
                          icon
                          small
                          color="primary"
                          v-bind="attrs"
                          v-on="on"
                        >
                          <v-badge
                            :content="
                              item.comments && item.comments.length
                                ? item.comments.length
                                : '0'
                            "
                            :color="
                              item.comments && item.comments.length
                                ? 'success'
                                : 'red'
                            "
                            overlap
                          >
                            <v-icon> mdi-comment-outline </v-icon>
                          </v-badge>
                        </v-btn>
                      </template>
                      <span>Comentarios del Servicio</span>
                    </v-tooltip>
                  </template>
                </v-data-table>
                <span v-if="!currentRequestMaterials.is_service" class="float-left mt-3 mr-2 text-body-1 text-danger">
                  Los precios con * son de referencia
                </span>

                <span class="float-right mt-3 mr-14 text-body-1">
                  <p class="ma-0">
                    Total:
                  </p>
                  <p class="ma-0">
                    <span class="font-weight-bold">
                      $ {{ getTotalCurrentRequestMaterials }}
                    </span>
                  </p>
                </span>
                <v-col
                  cols="12"
                  md="12"
                  sm="12"
                  class="mt-5 pt-5"
                  v-if="!ifCurrentRequestOfService"
                >
                  <label for="comments" class="mt-4">
                    Comentarios de la Solicitud
                  </label>
                  <v-textarea
                    :disabled="!permissionToApprove"
                    rows="4"
                    counter
                    outlined
                    @input="updateValueCurrentRequest($event, 'description')"
                    :value="
                      currentRequestMaterials
                        ? currentRequestMaterials.description
                        : null
                    "
                  ></v-textarea>
                </v-col>
              </v-tab-item>
              <v-tab-item
                key="approval"
                v-if="
                  currentRequestMaterials && !currentRequestMaterials.rejected
                "
              >
                <approvers
                  :currentRequestMaterials="currentRequestMaterials"
                ></approvers>
              </v-tab-item>
              <v-tab-item
                key="approval-restrictions"
                v-if="
                  currentRequestMaterials &&
                    !currentRequestMaterials.rejected &&
                    currentRequestMaterials.restriction_approvers.length
                "
              >
                <approvers
                  :currentRequestMaterials="currentRequestMaterials"
                  :showRestrictionsApprovers="true"
                ></approvers>
              </v-tab-item>
              <v-tab-item
                key="reason-reject"
                v-if="
                  currentRequestMaterials && currentRequestMaterials.rejected
                "
              >
                <reason-reject
                  :reasonReject="currentRequestMaterials.rejected_comment"
                  :dateRejected="currentRequestMaterials.rejected_at"
                  :rejectedBy="currentRequestMaterials.rejected_by"
                ></reason-reject>
              </v-tab-item>
            </v-tabs-items>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            v-if="permissionToApprove"
            :disabled="loading"
            color="warning text-white"
            class="text-white"
            @click="updateCurrentRequestApprove"
          >
            <small> Actualizar Solicitud </small>
          </v-btn>
          <v-btn
            :disabled="loading"
            color="red darken-1"
            outlined
            @click="executeCloseModalListMaterialsRequest"
          >
            <small> Cerrar </small>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <table-add-materials
      ref="tableAddMaterials"
      :currentRequestMaterials="currentRequestMaterials"
      :modalAction="dialogTableAddMaterials"
      :closeModalTableAddMaterials="closeModalTableAddMaterials"
      @addMaterialsSelected="addMaterialsSelected"
    ></table-add-materials>
    <modal-files-service
      :modalAction="dialogFilesUploadService"
      :closeModalFilesService="closeModalFilesUploadService"
      :currentService="currentFilesUploadService"
      :uploadFiles="false"
      :userCreateRequestService="false"
    >
    </modal-files-service>
    <modal-comments-service
      :modalAction="dialogCommmentsService"
      :closeModalComments="closeModalCommentsService"
      :currentService="currentServiceComments"
      :addComments="false"
    >
    </modal-comments-service>
  </section>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import { authUsuarioComputed } from "@/state/helpers";
import { preventCloseIfNotKeyEventEsc } from "@/helpers/common";
import {
  STATUS_REQUESTS_MATERIALS,
  TYPE_REQUESTS,
  TYPE_DESTINY_PROJECT_ID,
  TYPE_DESTINY_CECO_ID,
} from "@/constants/requestMaterial";
import { OPTIONS_AMOUNT_FORMATTING } from "@/constants/common";
import TableAddMaterials from "./TableAddMaterials.vue";
import ModalFilesService from "@/router/views/requestServices/components/ModalFilesService.vue";
import ModalCommentsService from "@/router/views/requestServices/components/ModalCommentsService.vue";
import ReasonReject from "./ReasonReject.vue";
import Approvers from "./Approvers.vue";
export default {
  props: {
    modalAction: {
      type: Boolean,
      default: false,
    },
    closeModalListMaterialsRequest: {
      type: Function,
      default: () => {},
    },
    currentRequestMaterials: {
      default: null,
    },
  },
  components: {
    TableAddMaterials,
    ReasonReject,
    Approvers,
    ModalFilesService,
    ModalCommentsService,
  },
  data() {
    return {
      statusRequests: STATUS_REQUESTS_MATERIALS,
      typeRequests: TYPE_REQUESTS,
      tab: "materials",
      currentFilesUploadService: null,
      currentServiceComments: null,
      dialogTableAddMaterials: false,
      dialogFilesUploadService: false,
      dialogCommmentsService: false,
      loading: false,
      debounce: null,
      debounceTime: 500,
      filters: {
        page: 1,
        items: 10,
        filter: "",
      },
      form: {},
      optionsFormatting: OPTIONS_AMOUNT_FORMATTING,
      tableHeaders: [
        {
          text: "Código",
          align: "start",
          width: "10%",
          sortable: false,
          value: "code",
        },
        {
          text: "Descripción",
          width: "45%",
          sortable: false,
          value: "description",
        },
        {
          text: "Cantidad Solicitada",
          align: "center",
          width: "8%",
          sortable: false,
          value: "quantity",
        },
        {
          text: "Precio",
          align: "start",
          width: "10%",
          sortable: false,
          value: "price",
        },
        {
          text: "Subtotal",
          align: "start",
          width: "10%",
          sortable: false,
          value: "subtotal",
        },
      ],
    };
  },
  methods: {
    ...mapActions({
      getExchangeRate: "requestService/getExchangeRate",
      updateMaterialRequestApprove:
        "requestMaterial/updateMaterialRequestApprove",
      updateValueRequestApproval: "requestMaterial/updateValueRequestApproval",
      updateMaterialRequestApproval:
        "requestMaterial/updateMaterialRequestApproval",
      updateMaterialsRequestApproval:
        "requestMaterial/updateMaterialsRequestApproval",
      spliceMaterialRequestAproval:
        "requestMaterial/spliceMaterialRequestAproval",
      updateDataMaterialsCurrentRequestApprove:
        "requestMaterial/updateDataMaterialsCurrentRequestApprove",
      getMaterialsInfoList: "requestMaterialsSap/getMaterialsInfoList",
    }),
    openApproversList() {
      document.getElementById("approvalList").click();
    },
    getSubtotalPrice(item) {
      return item.price * item.quantity;
    },
    codeProductIsActive(code_product) {
      return code_product.startsWith("A");
    },
    async updateCurrentRequestApprove() {
      if (!this.currentRequestMaterials.materials.length) {
        this.$swal.fire({
          icon: "error",
          title: "Error",
          text: "La solicitud debe tener al menos un material",
          position: "top-end",
          showConfirmButton: false,
          toast: true,
          timer: 4000,
          timerProgressBar: true,
        });
        return;
      }
      if (
        this.currentRequestMaterials.materials.find((item) => item.quantity < 1)
      ) {
        this.$swal.fire({
          icon: "error",
          title: "Error",
          text: "Todos los materiales deben tener al menos 1 unidad",
          position: "top-end",
          showConfirmButton: false,
          toast: true,
          timer: 4000,
          timerProgressBar: true,
        });
        return;
      }
      let self = this;
      this.$swal
        .fire({
          icon: "info",
          title: `¿Deseas guardar los cambios realizados en esta solicitud de aprobación?`,
          showCancelButton: true,
          showConfirmButton: true,
          confirmButtonColor: "primary",
          confirmButtonText: "Guardar",
          cancelButtonText: `Cancelar`,
        })
        .then(async (result) => {
          if (result.isConfirmed) {
            const response = await self.updateMaterialRequestApprove({
              params: {
                user: self.user.email,
                request_id: self.currentRequestMaterials.id,
                monto_nuevo: Number(
                  self.getTotalCurrentRequestMaterials.replaceAll(".", "").replaceAll(",", ".")
                ),
              },
              payload: {
                ...self.currentRequestMaterials.default_request_material_json,
                materials: self.ifCurrentRequestOfService
                  ? self.currentRequestMaterials.materials.map((item) => {
                      return {
                        [item.code]: Number(item.quantity),
                      };
                    })
                  : self.currentRequestMaterials.materials.reduce(
                      (finalObject, currentObject) => {
                        return {
                          ...finalObject,
                          [currentObject.code]: finalObject[currentObject.code]
                            ? Number(finalObject[currentObject.code]) +
                              Number(currentObject.quantity)
                            : Number(currentObject.quantity),
                        };
                      },
                      {}
                    ),
                request_type: self.currentRequestMaterials.request_type,
                description: self.currentRequestMaterials.description,
              },
            });
            if (response.status == 200) {
              self.$swal.fire({
                icon: "success",
                title: "Excelente",
                text: "Solicitud de aprobación modificada con éxito",
                position: "top-end",
                showConfirmButton: false,
                toast: true,
                timer: 4000,
                timerProgressBar: true,
              });
              self.$emit("requestUpdated");
            }
            if (response.status == 404 || response.status == 400) {
              self.$swal.fire({
                icon: "error",
                title: "Error",
                text: response.data.detail,
                position: "top-end",
                showConfirmButton: false,
                toast: true,
                timer: 4000,
                timerProgressBar: true,
              });
            }
            if (response.status == 500) {
              self.$swal.fire({
                icon: "error",
                title: "Error",
                text:
                  "Ha ocurrido un error en el servidor, intentalo nuevamente",
                position: "top-end",
                showConfirmButton: false,
                toast: true,
                timer: 4000,
                timerProgressBar: true,
              });
            }
            self.closeModalListMaterialsRequest();
          }
        });
    },
    addMaterialsSelected(materials) {
      this.updateMaterialsRequestApproval({
        id: this.currentRequestMaterials.id,
        materials: materials.map((item) => {
          return {
            quantity: 1,
            code: item.code,
            description: item.description,
            price: item.price,
          };
        }),
      });
    },
    async getMaterialsFromTypeDestiny() {
      this.$refs.tableAddMaterials.updateTypeDestiny(
        this.currentRequestMaterials.project
          ? TYPE_DESTINY_PROJECT_ID
          : TYPE_DESTINY_CECO_ID
      );
      this.$refs.tableAddMaterials.getMaterials();
    },
    openModalTableAddMaterials() {
      this.dialogTableAddMaterials = true;
    },
    closeModalTableAddMaterials($event) {
      let validationEvent = preventCloseIfNotKeyEventEsc($event);
      if (validationEvent) {
        this.dialogTableAddMaterials = false;
      }
    },
    getColorStatus(item) {
      if (item && (item.rejected || item.cancelled)) {
        return "red darken-2";
      }
      switch (item.sent_to_erp) {
        case this.statusRequests.pending:
          return "yellow darken-4";
        case this.statusRequests.accepted:
          return "blue darken-2";
        default:
          break;
      }
    },
    getUserFromEmail(email) {
      let user = this.usersActiveContract.find((user) => user.email == email);
      return user
        ? `${user.name} <small>(${user.email})</small>`
        : `<span>${email}</span>`;
    },
    async updateValueCurrentRequest(value = null, field) {
      clearTimeout(this.debounce);
      let self = this;
      this.debounce = setTimeout(async function() {
        await self.updateValueRequestApproval({
          id: self.currentRequestMaterials.id,
          field,
          value,
        });
      }, self.debounceTime);
    },
    async updateValueMaterialRequestApprove(value = null, material, field) {
      clearTimeout(this.debounce);
      let self = this;
      this.debounce = setTimeout(async function() {
        await self.updateMaterialRequestApproval({
          id: self.currentRequestMaterials.id,
          codeProduct: material.code,
          field,
          value,
        });
      }, self.debounceTime);
    },
    deleteMaterial(material) {
      let self = this;
      this.$swal
        .fire({
          icon: "error",
          title: `¿Deseas eliminar este ${
            this.ifCurrentRequestOfService ? "servicio" : "material"
          }: ${material.description}, con la cantidad solicitada de ${
            material.quantity
          }?`,
          showCancelButton: true,
          showConfirmButton: true,
          confirmButtonColor: "red",
          confirmButtonText: "Eliminar",
          cancelButtonText: `Cancelar`,
        })
        .then(async (result) => {
          if (result.isConfirmed) {
            await self.spliceMaterialRequestAproval({
              id: self.currentRequestMaterials.id,
              codeProduct: material.code,
            });
            self.$swal.fire({
              icon: "success",
              title: "Excelente",
              text: `${
                this.ifCurrentRequestOfService ? "Servicio" : "Material"
              } eliminado correctamente, recuerda actualizar la solicitud para que los cambios surjan efecto`,
              position: "top-end",
              showConfirmButton: false,
              toast: true,
              timer: 6000,
              timerProgressBar: true,
            });
          }
        });
    },
    executeCloseModalListMaterialsRequest($event) {
      let validationEvent = preventCloseIfNotKeyEventEsc($event);
      if (validationEvent) {
        this.filters.filter = "";
        this.closeModalListMaterialsRequest($event);
      }
    },
    formatAmount(amount, showDecimals = true) {
      return amount.toLocaleString("de-DE", {
        minimumFractionDigits: showDecimals? 2 : 0,
        maximumFractionDigits: showDecimals? 2 : 0,
      });
    },
    async openModalFilesUploadService(service) {
      const coins = [
        ...new Set(
          service.documents
            .filter((item) => item.coin && item.coin !== "CLP")
            .map((item) => item.coin)
        ),
      ];
      let exchangeRates = [];
      const promises = coins.map(async (item) => {
        return this.getExchangeRate({ to_currency: item });
      });
      exchangeRates = await Promise.all(promises);
      service.documents = service.documents.map((item) => {
        if (item.coin && item.coin !== "CLP") {
          const exchange = exchangeRates.find(
            (exchange) => exchange.to_currency == item.coin
          );
          return {
            ...item,
            coin: item.coin ? item.coin : "CLP",
            unit_amount: this.formatAmount(item.price, item.coin && item.coin !== "CLP"),
            amount: this.formatAmount(item.price * exchange.rate, false),
            exchange_rate: `1 ${item.coin} ⟺ ${exchange.rate
              .toString()
              .replaceAll(".", ",")} CLP`,
          };
        } else {
          return {
            ...item,
            coin: "CLP",
            unit_amount: this.formatAmount(item.price, false),
            amount: this.formatAmount(item.price, false),
            exchange_rate: "---",
          };
        }
      });
      this.currentFilesUploadService = service;
      this.dialogFilesUploadService = true;
    },
    closeModalFilesUploadService() {
      this.currentFilesUploadService = null;
      this.dialogFilesUploadService = false;
    },
    openModalCommentsService(service) {
      this.currentServiceComments = service;
      this.dialogCommmentsService = true;
    },
    closeModalCommentsService() {
      this.currentServiceComments = null;
      this.dialogCommmentsService = false;
    },
  },
  computed: {
    ...authUsuarioComputed,
    ...mapGetters({
      usersActiveContract: "user/users",
    }),
    filteredMaterials() {
      return this.currentRequestMaterials
        ? this.currentRequestMaterials.materials.filter((material) => {
            if (this.filters.filter) {
              let filter = this.filters.filter.trim().toLowerCase();
              return (
                material.code
                  .toString()
                  .toLowerCase()
                  .includes(filter) ||
                material.description?.toLowerCase().includes(filter) ||
                material.price
                  ?.toString()
                  .toLowerCase()
                  .includes(filter)
              );
            }
            return material;
          })
        : [];
    },
    wrappedTableHeaders() {
      if (
        this.permissionToApprove &&
        !this.currentRequestMaterials.sent_to_erp &&
        !this.currentRequestMaterials.rejected
      ) {
        return [
          {
            text: "Acciones",
            align: "center",
            sortable: false,
            value: "actions",
            width: "12%",
          },
          ...this.tableHeaders,
        ];
      }
      return this.tableHeaders;
    },
    getTotalCurrentRequestMaterials() {
      return this.currentRequestMaterials
        ? this.currentRequestMaterials.materials
            .filter((item) => item.quantity >= 1)
            .reduce((total, currentProduct) => {
              let costProduct = currentProduct.quantity * currentProduct?.price;
              return total + costProduct;
            }, 0)
            .toLocaleString("es", this.optionsFormatting)
        : 0;
    },
    currentRequestHaveApproversWithApproved() {
      return (
        this.currentRequestMaterials &&
        this.currentRequestMaterials.request_approvers.some((item) => {
          return item.approved == true;
        })
      );
    },
    permissionToApprove() {
      if (this.user) {
        return (
          this.currentRequestMaterials &&
          (this.currentRequestMaterials.request_approvers.find((item) => {
            return (
              (item.approver.email == this.user.email ||
                item.approver.substituting_for.find(
                  ({ substitute }) => substitute.email == this.user.email
                )) &&
              Math.abs(
                item.order - this.currentRequestMaterials.approved_index
              ) == 1
            );
          }) !== undefined ||
            (this.user.email == this.currentRequestMaterials.created_by &&
              !this.currentRequestMaterials.modified_index)) &&
          !this.currentRequestMaterials.sent_to_erp &&
          !this.currentRequestMaterials.rejected &&
          !this.currentRequestMaterials.cancelled
        );
      }
      return false;
    },
    ifCurrentRequestOfService() {
      return this.currentRequestMaterials &&
        this.currentRequestMaterials.service_requests.length
        ? true
        : false;
    },
  },
  watch: {
    currentRequestMaterials: {
      immediate: true,
      handler: async function(requestMaterial) {
        if (requestMaterial) {
          if (!this.ifCurrentRequestOfService) {
            const listMaterials = await this.getMaterialsInfoList({
              material_list: requestMaterial.materials
                .map((item) => item.code)
                .join(","),
            });
            this.updateDataMaterialsCurrentRequestApprove({
              id: requestMaterial.id,
              materials: listMaterials.materials,
              is_service: false,
            });
            this.getMaterialsFromTypeDestiny();
          } else {
            this.updateDataMaterialsCurrentRequestApprove({
              id: requestMaterial.id,
              materials: requestMaterial.service_requests[0].items,
              is_service: true,
            });
          }
        }
      },
    },
  },
};
</script>

<style></style>
