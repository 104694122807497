<template>
  <section>
    <loading-flux :value="loading"></loading-flux>
    <v-dialog
      v-model="modalAction"
      max-width="1200px"
      @keydown="executeCloseModalRequestReturnMaterials"
      @click:outside="executeCloseModalRequestReturnMaterials"
    >
      <v-card>
        <div class="p-4 pb-0">
          <h5>
            Crear solicitud de devolución de materiales
          </h5>
        </div>
        <hr />
        <v-spacer></v-spacer>
        <v-card-text class="pb-0">
          <v-container>
            <v-row align="start" align-content="start">
              <v-col cols="12" md="4" sm="12" class="mb-1 pt-0 ml-1">
                <v-autocomplete
                  v-if="!$route.query.sap_project"
                  v-model="form.project"
                  :items="
                    projects.items
                      ? projects.items.filter((item) => item.warehouse)
                      : []
                  "
                  item-text="name_code"
                  label="Proyecto"
                  @input="getStagesFromProject()"
                  @update:search-input="getProjectsData"
                  @click:clear="getProjectsData"
                  no-data-text="Sin proyectos"
                  :loading="loadingProjects"
                ></v-autocomplete>
                <small
                  class="text-danger"
                  v-if="!$route.query.sap_project && !form.project"
                >
                  Selecciona un proyecto y su etapa para buscar los materiales
                  disponibles para devolución
                </small>
                <section v-if="validationShowInfoProjectsAndStages">
                  <label class="font-weight-bold">
                    Unidad de Negocio
                  </label>
                  <p style="font-size: 12px">
                    {{ getCurrentProjectFromCode.business_unit.name }}
                  </p>
                  <label class="font-weight-bold">
                    Tipo de Venta
                  </label>
                  <p style="font-size: 12px">
                    {{ getCurrentProjectFromCode.sale_type }}
                  </p>
                </section>
              </v-col>
              <v-col cols="12" md="4" sm="12" class="mb-1 pt-0 ml-1">
                <section>
                  <v-autocomplete
                    label="Etapa del proyecto"
                    v-model="form.sap_stage"
                    @change="getMaterialsFromErp"
                    :items="availableStagesProjects"
                    :disabled="
                      !availableStagesProjects ||
                        !availableStagesProjects.length ||
                        statusAvailableProjects === 404 ||
                        !validationShowInfoProjectsAndStages
                    "
                    item-value="sap_code"
                    item-text="name"
                  >
                  </v-autocomplete>
                  <p
                    class="text-danger"
                    v-if="
                      (form.project_code || codeProject) &&
                        (!availableStagesProjects ||
                          !availableStagesProjects.length ||
                          statusAvailableProjects === 404)
                    "
                  >
                    <b>Este proyecto no tiene etapas de proyecto activas</b>
                  </p>
                </section>
              </v-col>
            </v-row>
            <v-data-table
              class="px-1 elevation-1"
              v-model="materialsSelected"
              :headers="tableHeaders"
              :items="
                materialsAvailable.materials ? materialsAvailable.materials : []
              "
              :items-per-page="filters.items !== 'Todos' ? filters.items : 100"
              :page.sync="filters.page"
              hide-default-footer
              :loading="loadingMaterialsAvailable"
              loading-text="Cargando materiales"
              show-select
              item-key="id"
              no-data-text="Sin materiales"
              @page-count="pageCount = $event"
              @toggle-select-all="updateQuantityRowSelected"
              @item-selected="updateQuantityRowSelected"
            >
              <template v-slot:top>
                <v-toolbar flat class="pt-5">
                  <v-text-field
                    label="Busqueda"
                    v-model="filters.filter"
                    :disabled="
                      !form.sap_stage || (!codeProject && !form.project_code)
                    "
                    @input="getMaterials(1)"
                    class="w-25 mx-4"
                    clearable
                    placeholder="Buscar material"
                    append-icon="mdi-magnify"
                  ></v-text-field>
                  <v-autocomplete
                    class="w-25"
                    @change="($event) => getMaterials(1)"
                    v-model="filters.items"
                    :items="[5, 10, 15, 20, 25, 'Todos']"
                    label="Items por página"
                  >
                  </v-autocomplete>
                  <v-divider class="mx-5" inset vertical></v-divider>
                  <v-spacer></v-spacer>
                  <div class="d-flex align-items-center">
                    <p class="mx-3 text-h6">
                      <small class="pull-right">
                        Seleccionados: {{ materialsSelected.length }}
                      </small>
                    </p>
                  </div>
                </v-toolbar>
              </template>
              <template v-slot:item.code="{ item }">
                <span style="font-size: 12px">{{ item.material.code }}</span>
              </template>
              <template v-slot:item.available="{ item }">
                <p class="my-0 text-right" style="font-size: 12px">
                  {{ item.available_quantity }}
                </p>
              </template>
              <template v-slot:item.description="{ item }">
                <p class="my-0 text-left" style="font-size: 12px;">
                  {{ item.material.description }}
                </p>
              </template>
              <template v-slot:item.units="{ item }">
                <p class="my-0 text-center" style="font-size: 12px">
                  {{ item.material.measurement.units }}
                </p>
              </template>
              <template v-slot:item.request="{ item }">
                <v-text-field
                  v-if="materialIsSelected(item)"
                  type="number"
                  :min="1"
                  :max="item.available_quantity"
                  class="pt-0 mt-0"
                  @input="setMaterialRequestValue($event, item)"
                  :error="
                    materialIsSelected(item)
                      ? materialIsSelected(item).request >
                        item.available_quantity
                      : false
                  "
                  :value="
                    materialIsSelected(item) &&
                    materialIsSelected(item).request !== undefined
                      ? materialIsSelected(item).request
                      : item.available_quantity
                  "
                  hide-details
                ></v-text-field>
              </template>
            </v-data-table>
            <div class="d-flex justify-content-end pt-4">
              <v-pagination
                v-model="filters.page"
                :length="
                  materialsAvailable.number_of_pages
                    ? materialsAvailable.number_of_pages
                    : 1
                "
                :total-visible="8"
              ></v-pagination>
            </div>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            :disabled="loading"
            color="red darken-1"
            outlined
            text
            @click="executeCloseModalRequestReturnMaterials"
          >
            <small>
              Cancelar
            </small>
          </v-btn>
          <v-btn
            color="primary"
            class="text-white"
            :loading="loading"
            :disabled="materialsSelected.length < 1"
            @click="validateMaterialsSelectedAndOpenConfirm"
          >
            <small class="text-white">
              Crear solicitud
            </small>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <ConfirmRequestReturnMaterial
      ref="confirmMaterials"
      @requestReturnMaterialCreated="
        closeModalCreateRequestAndEmitLoadReturnRequests
      "
      :modalAction="dialogConfirmRequestReturnMaterial"
      :closeModalConfirmRequestReturnMaterial="
        closeModalConfirmRequestReturnMaterial
      "
    ></ConfirmRequestReturnMaterial>
  </section>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { authUsuarioComputed } from "@/state/helpers";
import { preventCloseIfNotKeyEventEsc } from "@/helpers/common";
import ConfirmRequestReturnMaterial from "./ConfirmRequestReturnMaterial.vue";
export default {
  components: { ConfirmRequestReturnMaterial },
  props: {
    modalAction: {
      type: Boolean,
      default: false,
    },
    closeModalRequestReturnMaterials: {
      type: Function,
      default: () => {},
    },
  },
  data() {
    return {
      dialogConfirmRequestReturnMaterial: false,
      loading: false,
      loadingProjects: false,
      loadingMaterialsAvailable: false,
      debounce: null,
      debounceProjects: null,
      debounceTime: 500,
      filters: {
        page: 1,
        items: 10,
        filter: "",
      },
      form: {
        project: null,
        sap_stage: null,
      },
      statusAvailableProjects: null,
      materialsSelected: [],
      tableHeaders: [
        {
          text: "Código",
          align: "start",
          width: "10%",
          sortable: false,
          value: "code",
        },
        {
          text: "Descripción",
          align: "start",
          width: "70%",
          sortable: false,
          value: "description",
        },
        {
          text: "Unidad",
          align: "start",
          width: "5%",
          sortable: false,
          value: "units",
        },
        {
          text: "Disponible",
          align: "end",
          width: "5%",
          sortable: false,
          value: "available",
        },
        {
          text: "Cantidad",
          align: "center",
          width: "15%",
          sortable: false,
          value: "request",
        },
      ],
      codeProject: this.$route.query.sap_project
        ? this.$route.query.sap_project
        : null,
    };
  },
  methods: {
    ...mapActions({
      getMaterialsAvailableFromProject:
        "returnMaterial/getMaterialsAvailableFromProject",
      loadMaterialsFromErp: "returnMaterial/loadMaterialsFromErp",
      getStagesAvailableFromProject:
        "requestMaterialsSap/getStagesAvailableFromProject",
      getProjects: "requestMaterialsSap/getProjects",
      cleanMaterialsAvailable: "returnMaterial/cleanMaterialsAvailable",
      clearAvailableStages: "requestMaterialsSap/clearAvailableStages",
    }),
    async getMaterialsFromErp() {
      if ((this.codeProject || this.form.project) && this.form.sap_stage) {
        this.materialsSelected = [];
        this.loadingMaterialsAvailable = true;
        await this.loadMaterialsFromErp({
          codigo: this.codeProject
            ? this.codeProject
            : this.form.project.split(" - ")[0],
          sap_stage: this.form.sap_stage,
          user: this.user.email,
          is_sap: true,
        });
        await this.getMaterials(1);
      }
    },
    async getStagesFromProject() {
      if (this.form.project || this.$route.query.sap_project) {
        const res = await this.getStagesAvailableFromProject({
          project_code: this.$route.query.sap_project
            ? this.$route.query.sap_project
            : this.form.project?.split(" - ")[0],
        });
        this.statusAvailableProjects = res.status;
        this.form.sap_stage = null;
      } else {
        this.form.sap_stage = null;
        this.clearAvailableStages();
      }
    },
    setMaterialsToConfirmRequest(materials) {
      this.$refs.confirmMaterials.setMaterialsSelected(materials);
    },
    setFormStageAndProjectCodeToConfirmRequest(values) {
      this.$refs.confirmMaterials.setFormStageAndProjectCode(values);
    },
    openModalConfirmRequestReturnMaterial() {
      this.dialogConfirmRequestReturnMaterial = true;
    },
    async closeModalCreateRequestAndEmitLoadReturnRequests({
      project,
      sap_stage,
    }) {
      this.$emit("requestReturnMaterialCreated");
      await this.loadMaterialsFromErp({
        codigo: project,
        sap_stage: sap_stage,
        user: this.user.email,
        is_sap: true,
      });
      this.executeCloseModalRequestReturnMaterials();
    },
    closeModalConfirmRequestReturnMaterial($event) {
      let validationEvent = preventCloseIfNotKeyEventEsc($event);
      if (validationEvent) {
        this.dialogConfirmRequestReturnMaterial = false;
      }
    },
    executeCloseModalRequestReturnMaterials($event) {
      let validationEvent = preventCloseIfNotKeyEventEsc($event);
      if (validationEvent) {
        this.cleanMaterialsAvailable();
        this.resetForm();
        this.closeModalRequestReturnMaterials($event);
      }
    },
    resetForm() {
      this.materialsSelected = [];
      this.filters.filter = "";
      this.form.project = null;
      this.form.sap_stage = null;
    },
    async getMaterials(page = 1) {
      clearTimeout(this.debounce);
      let self = this;
      this.debounce = setTimeout(async function() {
        self.filters.page = page;
        await self.getMaterialsAvailableFromProject({
          page: self.filters.page,
          filter: self.filters.filter ? self.filters.filter : undefined,
          items:
            self.filters.items !== "Todos" ? self.filters.items : undefined,
          project_filter: self.form.project
            ? self.form.project?.split(" - ")[0]
            : self.$route.query.sap_project,
          sap_stage: self.form.sap_stage,
          show_all: self.filters.items === "Todos" ? true : undefined,
          is_sap: true,
        });
        self.loadingMaterialsAvailable = false;
      }, self.debounceTime);
    },
    async getProjectsData(search = "") {
      if (
        (search && search.type) ||
        ((search || typeof search == "string") && this.form.project !== search)
      ) {
        this.loadingProjects = true;
        clearTimeout(this.debounceProjects);
        let self = this;
        this.debounceProjects = setTimeout(async function() {
          await self.getProjects({
            filter: search && !search.type ? search : undefined,
            items: 20,
          });
          self.loadingProjects = false;
        }, self.debounceTime);
      }
    },
    materialIsSelected(material) {
      return this.materialsSelected.find((item) => item.id === material.id);
    },
    updateQuantityRowSelected({ item, items, value }) {
      if (value) {
        let materials = items ? items : [item];
        materials.forEach((material) => {
          this.materialsSelected.push(material);
          this.setMaterialRequestValue(material.available_quantity, material);
        });
      }
    },
    setMaterialRequestValue(value, material) {
      this.materialsSelected.forEach((item) => {
        if (item.id === material.id) {
          item.request = value;
        }
      });
      this.materialsSelected = [...this.materialsSelected];
    },
    validateMaterialsSelectedAndOpenConfirm() {
      let validMaterials = true;
      this.materialsSelected.forEach((material) => {
        if (
          isNaN(material.request) ||
          Number(material.request) > material.available_quantity
        ) {
          validMaterials = false;
        }
      });
      if (!validMaterials) {
        return this.$swal({
          icon: "error",
          title: "Error",
          text:
            "Las cantidades a solicitar deben ser menores a la cantidad disponibles de material",
          position: "top-end",
          showConfirmButton: false,
          toast: true,
          timer: 5000,
          timerProgressBar: true,
        });
      }
      if (!this.form.sap_stage) {
        return this.$swal({
          icon: "error",
          title: "Error",
          text: "Debe seleccionar la etapa para continuar",
          position: "top-end",
          showConfirmButton: false,
          toast: true,
          timer: 5000,
          timerProgressBar: true,
        });
      }
      this.setFormStageAndProjectCodeToConfirmRequest({
        sap_stage: this.form.sap_stage,
        project_code: this.form.project
          ? this.form.project.split(" - ")[0]
          : null,
      });
      this.setMaterialsToConfirmRequest(
        this.materialsSelected.map((item) => {
          return {
            code: item.material.code,
            description: item.material.description,
            units: item.material.measurement.units,
            material_id: item.id,
            quantity: Number(item.request),
          };
        })
      );
      this.openModalConfirmRequestReturnMaterial();
    },
  },
  computed: {
    ...authUsuarioComputed,
    ...mapGetters({
      projects: "requestMaterialsSap/projects",
      materialsAvailable: "returnMaterial/materialsAvailable",
      availableStagesProjects: "requestMaterialsSap/availableStagesProjects",
    }),
    getCurrentProjectFromCode() {
      if (
        (this.form.project || this.$route.query.sap_project) &&
        this.projects.items
      ) {
        return this.projects.items.find(
          (item) =>
            item.code == this.form.project?.split(" - ")[0] ||
            item.code == this.$route.query.sap_project
        );
      }
      return null;
    },
    validationShowInfoProjectsAndStages() {
      return (
        (this.form.project &&
          !this.loadingProjects &&
          this.getCurrentProjectFromCode) ||
        (this.$route.query.sap_project && this.getCurrentProjectFromCode)
      );
    },
  },
  watch: {
    "filters.page": function(page) {
      this.getMaterials(page);
    },
    projects(newProjects) {
      if (newProjects.items && this.$route.query.sap_project) {
        this.getStagesFromProject();
      }
    },
    "form.project": function(project) {
      if (!project) {
        this.cleanMaterialsAvailable();
      }
    },
  },
};
</script>

<style></style>
