<template>
  <section>
    <loading-flux :value="loading"></loading-flux>
    <v-dialog
      v-model="modalAction"
      max-width="1000px"
      @keydown="executeCloseModalDetailsConsumptionMaterials"
      @click:outside="executeCloseModalDetailsConsumptionMaterials"
    >
      <v-card>
        <v-card-title>
          <span class="text-h6">
            {{
              currentRequestConsumption
                ? currentRequestConsumption.description
                : "Cargando..."
            }}
          </span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-data-table
              class="px-1 elevation-1"
              :headers="wrappedTableHeaders"
              :items="
                requestMaterials.consumed_materials
                  ? requestMaterials.consumed_materials
                  : []
              "
              :items-per-page="filters.items !== 'Todos' ? filters.items : 100"
              :page.sync="filters.page"
              hide-default-footer
              :loading="loadingMaterialsRequest"
              loading-text="Cargando materiales"
              item-key="id"
              no-data-text="Sin materiales"
              @page-count="pageCount = $event"
            >
              <template v-slot:top>
                <v-toolbar flat class="pt-5">
                  <v-text-field
                    label="Busqueda"
                    v-model="filters.filter"
                    @input="getMaterialsFromRequestConsumptionData(1)"
                    class="w-25 mx-4"
                    clearable
                    placeholder="Buscar material"
                    append-icon="mdi-magnify"
                  ></v-text-field>
                  <v-autocomplete
                    class="w-25"
                    @change="() => getMaterialsFromRequestConsumptionData(1)"
                    v-model="filters.items"
                    :items="[5, 10, 15, 20, 25, 'Todos']"
                    label="Items por página"
                  >
                  </v-autocomplete>
                  <v-divider class="mx-5" inset vertical></v-divider>
                  <v-spacer></v-spacer>
                  <div class="d-flex align-items-center">
                    <p class="mx-3 text-h6">
                      <small class="pull-right">
                        Seleccionados: {{ materialsSelected.length }}
                      </small>
                    </p>
                  </div>
                </v-toolbar>
              </template>
              <template v-slot:item.code="{ item }">
                <span style="font-size: 12.5px">{{
                  item.material_project.material
                    ? item.material_project.material.code
                    : null
                }}</span>
              </template>
              <template v-slot:item.description="{ item }">
                <span style="font-size: 12.5px">{{
                  item.material_project.material.description
                }}</span>
              </template>
              <template v-slot:item.units="{ item }">
                <span style="font-size: 12.5px">{{
                  item.material_project.material.measurement.units
                }}</span>
              </template>
              <template v-slot:item.requested="{ item }">
                <span style="font-size: 12.5px">{{
                  item.consumed_quantity
                }}</span>
              </template>
            </v-data-table>
            <div class="text-center pt-2">
              <v-pagination
                v-model="filters.page"
                :length="
                  requestMaterials.number_of_pages
                    ? requestMaterials.number_of_pages
                    : 1
                "
                :total-visible="8"
              ></v-pagination>
            </div>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            :disabled="loading"
            color="red darken-1"
            outlined
            text
            @click="executeCloseModalDetailsConsumptionMaterials"
          >
            <small>
              Cerrar
            </small>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </section>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { authUsuarioComputed } from "@/state/helpers";
import { preventCloseIfNotKeyEventEsc } from "@/helpers/common";
export default {
  props: {
    modalAction: {
      type: Boolean,
      default: false,
    },
    closeModalDetailsRequestConsumptionMaterials: {
      type: Function,
      default: () => {},
    },
    currentRequestConsumption: {
      default: null,
    },
  },
  data() {
    return {
      loading: false,
      debounce: null,
      debounceTime: 500,
      filters: {
        page: 1,
        items: 10,
        filter: "",
      },
      materialsSelected: [],
      tableHeaders: [
        {
          text: "Código",
          align: "start",
          width: "5%",
          sortable: false,
          value: "code",
        },
        {
          text: "Descripción",
          align: "start",
          width: "70%",
          sortable: false,
          value: "description",
        },
        {
          text: "Unidad",
          align: "start",
          width: "5%",
          sortable: false,
          value: "units",
        },
        {
          text: "Consumido",
          align: "center",
          width: "15%",
          sortable: false,
          value: "requested",
        },
      ],
      codeProject: this.$route.params.codigoProyecto,
    };
  },
  methods: {
    ...mapActions({
      getMaterialsFromRequestConsumption:
        "consumptionMaterial/getMaterialsFromRequestConsumption",
      cleanRequestMaterials: "consumptionMaterial/cleanRequestMaterials",
    }),
    executeCloseModalDetailsConsumptionMaterials($event) {
      let validationEvent = preventCloseIfNotKeyEventEsc($event);
      if (validationEvent) {
        this.filters.filter = "";
        this.cleanRequestMaterials();
        this.closeModalDetailsRequestConsumptionMaterials($event);
      }
    },
    async getMaterialsFromRequestConsumptionData(page = 1) {
      clearTimeout(this.debounce);
      let self = this;
      this.debounce = setTimeout(async function() {
        self.filters.page = page;
        await self.getMaterialsFromRequestConsumption({
          page: self.filters.page,
          filter: self.filters.filter ? self.filters.filter : undefined,
          items:
            self.filters.items !== "Todos" ? self.filters.items : undefined,
          consumed_id: self.currentRequestConsumption.id,
          show_all: self.filters.items === "Todos" ? true : undefined,
        });
      }, self.debounceTime);
    },
  },
  computed: {
    ...authUsuarioComputed,
    ...mapGetters({
      loadingMaterialsRequest: "consumptionMaterial/loadingMaterialsRequest",
      requestMaterials: "consumptionMaterial/requestMaterials",
    }),
    wrappedTableHeaders() {
      return this.tableHeaders;
    },
  },
  watch: {
    "filters.page": function(page) {
      this.getMaterialsFromRequestConsumptionData(page);
    },
  },
};
</script>

<style></style>
