<template>
    <div>
        <b-overlay :show="loading" variant="white" rounded="sm">
            <div class="row">
                <div class="col-12">
                    <div class="card">
                        <div class="card-body">
                            <b-alert
                                :show="segundosMensajeActualizacion"
                                dismissible
                                :variant="mensaje.variant"
                                @dismissed="segundosMensajeActualizacion = 0"
                                @dismiss-count-down="
                                    actualizarSegundosMensajeActualizacion
                                "
                            >
                                {{ mensaje.texto }}
                            </b-alert>
                            <div class="d-flex">
                                <div class="card-title col-auto me-auto titulo-tarjetas">
                                    Actividades Proyecto
                                </div>
                                <Menu :lugarInstalacion="lugarInstalacion" />
                            </div>
                            <h5 class="card-title-desc mt-30-576 mt-30-1024px">
                                {{ proyectoUsuario.nombre_proyecto }} -
                                {{ lugarInstalacion.codigo_proyecto }} -
                                {{ lugarInstalacion.codigo_lugar_instalacion }}
                            </h5>
                            <div
                                class="row"
                                v-if="rolModuloPermiso.escritura"
                            >
                                <button
                                    class="btn btn-primary col-lg-auto crearActividadesProyecto boton-actividades-proyectos"
                                    @click="crearActividadesProyecto"
                                >
                                    <i class="mdi mdi-plus me-1"></i>
                                    Crear Actividades Base
                                </button>

                                <button
                                    class="btn btn-primary col-lg-auto boton-actividades-proyectos"
                                    @click="abrirModalNuevo"
                                >
                                    <i class="mdi mdi-plus me-1"></i>
                                    Nueva Configuración
                                </button>
                            </div>
                            <!-- 
                            <h4 class="card-title">
                                Actividad Tipo Proyecto Configuración
                            </h4> -->
                            <div class="row mt-4">
                                <div class="col-sm-12 col-md-6">
                                    <div
                                        id="tickets-table_length"
                                        class="dataTables_length"
                                    >
                                        <label
                                            class="d-inline-flex align-items-center"
                                        >
                                            Mostrar&nbsp;
                                            <b-form-select
                                                class="form-select form-select-sm"
                                                v-model="perPage"
                                                size="sm"
                                                :options="pageOptions"
                                            ></b-form-select>
                                        </label>
                                    </div>
                                </div>
                                <!-- Search -->
                                <div class="col-sm-12 col-md-6">
                                    <div
                                        id="tickets-table_filter"
                                        class="dataTables_filter text-md-end"
                                    >
                                        <label
                                            class="d-inline-flex align-items-center"
                                        >
                                            Buscar:
                                            <b-form-input
                                                v-model="filter"
                                                type="search"
                                                placeholder="Buscar..."
                                                class="form-control form-control-sm ms-2"
                                            ></b-form-input>
                                        </label>
                                    </div>
                                </div>
                                <!-- End search -->
                            </div>
                            <!-- Table -->
                            <div class="table-responsive mb-0">
                                <b-table
                                    class="datatables tabla-personalizada"
                                    :items="actividadesProyecto"
                                    :fields="fields"
                                    responsive="sm"
                                    :per-page="perPage"
                                    :current-page="currentPage"
                                    :sort-by.sync="sortBy"
                                    :sort-desc.sync="sortDesc"
                                    :filter="filter"
                                    :filter-included-fields="filterOn"
                                    select-mode="single"
                                    selectable
                                    @filtered="onFiltered"
                                    :busy="actividadesProyectoCargando"
                                >
                                    <template #table-busy>
                                        <div
                                            class="text-center text-danger my-2"
                                        >
                                            <b-spinner
                                                class="align-middle"
                                            ></b-spinner>
                                        </div>
                                    </template>
                                    <template
                                        #cell(accion)="data"
                                        v-if="rolModuloPermiso.escritura"
                                    >
                                        <i
                                            class="bx bx-edit text-primary icono-botom-tabla"
                                            @click="abrirModalEditar(data.item)"
                                        ></i>
                                        <i
                                            class="bx bx-trash text-primary icono-botom-tabla"
                                            @click="
                                                eliminarActividadProyectoLocal(
                                                    data.item
                                                )
                                            "
                                        ></i>
                                    </template>

                                    <template #cell(nombre_actividad)="data">
                                        <span
                                            class="badge bg-success font-size-13"
                                            >{{
                                                data.item.nombre_actividad
                                            }}</span
                                        >
                                    </template>
                                    <template #cell(fecha_inicio)="data">
                                        {{ formatDate(data.item.fecha_inicio) }}
                                    </template>

                                    <template #cell(fecha_termino)="data">
                                        {{
                                            formatDate(data.item.fecha_termino)
                                        }}
                                    </template>
                                </b-table>
                            </div>
                            <div class="row">
                                <div class="col">
                                    <div
                                        class="dataTables_paginate paging_simple_numbers float-end"
                                    >
                                        <ul
                                            class="pagination pagination-rounded mb-0"
                                        >
                                            <!-- pagination -->
                                            <b-pagination
                                                v-model="currentPage"
                                                :total-rows="totalRows"
                                                :per-page="perPage"
                                            ></b-pagination>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </b-overlay>

        <b-modal
            v-model="mostrarModal"
            title="Actividad Tipo Proyecto"
            hide-footer
            size="lg"
        >
        <loading-flux :value="loadingForm"></loading-flux>
            <form @submit.prevent="submit">
                <div class="row">
                    <div class="col-lg-6">
                        <div class="mb-3">
                            <label for="actividad">Actividad</label>
                            <multiselect
                                v-model="$v.form.actividad.$model"
                                :options="actividades"
                                placeholder="Seleccionar Actividad"
                                value="actividad_id"
                                label="nombre_actividad"
                                :searchable="true"
                                :loading="actividadesCargando"
                                open-direction="bottom"
                                :disabled="esEditar"
                                class="helo"
                                :class="{
                                    'is-invalid':
                                        submitted && $v.form.actividad.$error,
                                }"
                            ></multiselect>
                            <div
                                v-if="submitted && !$v.form.actividad.required"
                                class="invalid-feedback"
                            >
                                El campo es obligatorio.
                            </div>
                        </div>
                    </div>

                    <div class="col-lg-6">
                        <div class="mb-3">
                            <label for="dependeDe">Depende de</label>
                            <input
                                id="dependeDe"
                                v-model="$v.form.dependeDe.$model"
                                type="text"
                                class="form-control"
                                placeholder="Depende de"
                                :class="{
                                    'is-invalid':
                                        submitted && $v.form.dependeDe.$error,
                                }"
                            />
                            <div
                                v-if="submitted && !$v.form.dependeDe.required"
                                class="invalid-feedback"
                            >
                                El campo es obligatorio.
                            </div>
                        </div>
                    </div>

                    <div class="col-lg-6">
                        <div class="mb-3">
                            <label for="duracion">Duración</label>
                            <input
                                id="duracion"
                                v-model="$v.form.duracion.$model"
                                type="text"
                                class="form-control"
                                placeholder="Duración"
                                :class="{
                                    'is-invalid':
                                        submitted && $v.form.duracion.$error,
                                }"
                            />
                            <div
                                v-if="submitted && !$v.form.duracion.required"
                                class="invalid-feedback"
                            >
                                El campo es obligatorio.
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-6">
                        <div class="mb-3">
                            <label for="fechaInicio">Fecha Inicio</label>
                            <b-form-datepicker
                                v-model="$v.form.fechaInicio.$model"
                                placeholder="Fecha Inicio"
                                :state="fechaInicioValidar"
                                class="mb-2"
                            ></b-form-datepicker>
                            <!-- <b-form-input
                                v-model="$v.form.fechaInicio.$model"
                                type="date"
                                placeholder="Fecha Inicio"
                                :state="
                                    submitted && !$v.form.fechaInicio.required
                                "
                            ></b-form-input> -->

                            <div
                                v-if="
                                    submitted && !$v.form.fechaInicio.required
                                "
                                class="invalid-feedback"
                            >
                                El campo es obligatorio.
                            </div>
                        </div>
                    </div>

                    <div class="col-lg-6">
                        <div class="mb-3">
                            <label for="fechaTermino">Fecha Termino</label>

                            <b-form-datepicker
                                v-model="$v.form.fechaTermino.$model"
                                placeholder="Fecha Termino"
                                :state="fechaTerminoValidar"
                                class="mb-2"
                            ></b-form-datepicker>

                            <!-- <b-form-input
                                v-model="$v.form.fechaTermino.$model"
                                type="date"
                                placeholder="Fecha Termino"
                                :state="
                                    submitted && !$v.form.fechaTermino.required
                                "
                            ></b-form-input> -->

                            <div
                                v-if="
                                    submitted && !$v.form.fechaTermino.required
                                "
                                class="invalid-feedback"
                            >
                                El campo es obligatorio.
                            </div>
                        </div>
                    </div>

                    <div class="col-lg-6">
                        <div class="mb-3">
                            <label for="total">Total</label>
                            <input
                                id="total"
                                v-model="$v.form.total.$model"
                                type="text"
                                class="form-control"
                                placeholder="Total"
                                :class="{
                                    'is-invalid':
                                        submitted && $v.form.total.$error,
                                }"
                            />
                            <div
                                v-if="submitted && !$v.form.total.required"
                                class="invalid-feedback"
                            >
                                El campo es obligatorio.
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-6">
                        <div class="mb-3">
                            <label for="ponderacion">Ponderación</label>
                            <input
                                id="ponderacion"
                                v-model="$v.form.ponderacion.$model"
                                type="text"
                                class="form-control"
                                placeholder="Ponderación"
                                :class="{
                                    'is-invalid':
                                        submitted && $v.form.ponderacion.$error,
                                }"
                            />
                            <div
                                v-if="
                                    submitted && !$v.form.ponderacion.required
                                "
                                class="invalid-feedback"
                            >
                                El campo es obligatorio.
                            </div>
                        </div>
                    </div>

                    <div class="col-lg-6">
                        <div class="mb-3">
                            <label for="unidad">Unidad</label>
                            <multiselect
                                v-model="$v.form.unidad.$model"
                                :options="unidades"
                                placeholder="Seleccionar Unidad"
                                value="value"
                                label="label"
                                :searchable="true"
                                class="helo"
                                :class="{
                                    'is-invalid':
                                        submitted && $v.form.unidad.$error,
                                }"
                            ></multiselect>
                            <div
                                v-if="submitted && !$v.form.unidad.required"
                                class="invalid-feedback"
                            >
                                El campo es obligatorio.
                            </div>
                        </div>
                    </div>
                </div>
                <div class="text-end mt-3">
                    <b-button variant="light" @click="mostrarModal = false"
                        >Cerrar</b-button
                    >
                    <b-button
                        type="submit"
                        variant="success"
                        class="ms-1"
                        @click="guardarCerrar = false"
                        v-if="rolModuloPermiso.escritura"
                        >Guardar</b-button
                    >
                    <b-button
                        type="submit"
                        variant="success"
                        class="ms-1"
                        @click="guardarCerrar = true"
                        v-if="rolModuloPermiso.escritura"
                        >Guardar y Cerrar</b-button
                    >
                </div>
            </form>
        </b-modal>
    </div>
</template>
<script>
import { required } from "vuelidate/lib/validators";
import { validationMixin } from "vuelidate";
import { proyectoMethods, proyectoComputed } from "@/state/helpers";
import { tipoProyectoMethods, tipoProyectoComputed } from "@/state/helpers";
import { actividadMethods, actividadComputed } from "@/state/helpers";
import {
    actividadProyectoMethods,
    actividadProyectoComputed,
} from "@/state/helpers";
import {
    actividadTipoProyectoConfiguracionMethods,
    actividadTipoProyectoConfiguracionComputed,
} from "@/state/helpers";
import { authMethods, authUsuarioComputed } from "@/state/helpers";
import { permiso } from "@/helpers/authservice/obtener-permisos";
import Multiselect from "vue-multiselect";
import Swal from "sweetalert2";
import Menu from "@/components/widgets/menu/MenuWidget";
import moment from "moment";
moment.locale("es");
export default {
    mixins: [validationMixin],
    components: {
        Multiselect,
        Menu,
    },
    data() {
        return {
            loading: false,
            loadingForm: false,
            mensaje: { variant: "", texto: "" },
            segundos: 5,
            segundosMensajeActualizacion: 0,
            lugarInstalacion: {},
            proyectos: [],
            codigoProyecto: null,
            codigoLugarInstalacion: null,
            totalRows: 1,
            todoTotalRows: 1,
            currentPage: 1,
            todocurrentPage: 1,
            perPage: 10,
            todoperPage: 10,
            pageOptions: [10, 25, 50, 100],
            todoOptions: [10, 50, 100, 150, 200],
            filter: null,
            todoFilter: null,
            filterOn: [],
            todofilterOn: [],
            sortBy: "Proyecto",
            sortDesc: false,
            fields: [
                {
                    key: "Accion",
                    sortable: false,
                },
                {
                    key: "nombre_actividad",
                    sortable: true,
                },
                {
                    key: "depende_de",
                    sortable: true,
                },
                {
                    key: "duracion",
                    sortable: true,
                },
                {
                    key: "fecha_inicio",
                    sortable: true,
                },
                {
                    key: "fecha_termino",
                    sortable: true,
                },
                {
                    key: "total",
                    sortable: true,
                },
                {
                    key: "ponderacion",
                    sortable: true,
                },
                {
                    key: "unidad",
                    sortable: true,
                },
            ],
            guardarCerrar: true,
            actividadProyectoSeleccionada: {},
            actividadesProyecto: [],
            esEditar: false,
            submitted: false,
            mostrarModal: false,
            tipoProyectos: [],
            actividades: [],
            tipoProyectosCargando: false,
            actividadesCargando: false,
            actividadesProyectoCargando: false,
            unidades: [
                { value: "un", label: "un" },
                { value: "m", label: "m" },
                { value: "m3", label: "m3" },
                { value: "kg", label: "kg" },
                { value: "ton", label: "ton" },
                { value: "día", label: "día" },
            ],
            fechaInicioValidar: null,
            fechaTerminoValidar: null,
            form: {
                actividad: null,
                dependeDe: null,
                duracion: null,
                fechaInicio: null,
                fechaTermino: null,
                total: null,
                ponderacion: null,
                unidad: null,
            },
            rolModuloPermiso: {},
            proyectoSeleccionado: {},
        };
    },
    validations: {
        form: {
            actividad: { required },
            dependeDe: { required },
            duracion: { required },
            fechaInicio: { required },
            fechaTermino: { required },
            total: { required },
            ponderacion: { required },
            unidad: { required },
        },
    },
    mounted() {
        if (this.proyectoUsuario == null) {
            this.obtenerProyectoUsuario();
        } else {
            this.proyectoSeleccionado = this.proyectoUsuario;
        }
        this.codigoProyecto = this.$route.params.codigoProyecto;
        this.codigoLugarInstalacion = this.$route.params.codigoLugarInstalacion;
        this.lugarInstalacion = {
            codigo_lugar_instalacion: this.codigoLugarInstalacion,
            codigo_proyecto: this.codigoProyecto,
        };
        this.obtenerActividadesProyectoLocal();

        let permisos = permiso.obtenerPermisos(
            this.user,
            this.$router.currentRoute.name
        );
        this.rolModuloPermiso = permisos;
    },
    methods: {
        ...actividadProyectoMethods,
        ...proyectoMethods,
        ...actividadMethods,
        ...actividadTipoProyectoConfiguracionMethods,
        obtenerProyectoUsuario() {
            this.obtenerProyecto(this.$route.params.codigoProyecto)
                .then((res) => {
                    this.proyectoSeleccionado = res.body;
                })
                .catch((error) => {});
        },
        actualizarSegundosMensajeActualizacion(segundosMensajeActualizacion) {
            this.segundosMensajeActualizacion = segundosMensajeActualizacion;
        },
        abrirModalNuevo() {
            this.resetForm();
            this.esEditar = false;
            this.submitted = false;
            this.mostrarModal = true;
            this.obtenerActividadesLocal();
        },
        abrirModalEditar(datos) {
            this.actividadProyectoSeleccionada = datos;
            this.resetForm();
            this.obtenerActividadesLocal();

            this.$v.form.dependeDe.$model = datos.depende_de;
            this.$v.form.duracion.$model = datos.duracion;
            this.$v.form.fechaInicio.$model = datos.fecha_inicio;
            this.$v.form.fechaTermino.$model = datos.fecha_termino;
            this.$v.form.total.$model = datos.total;
            this.$v.form.ponderacion.$model = datos.ponderacion;

            this.$v.form.unidad.$model = {
                value: datos.unidad,
                label: datos.unidad,
            };

            this.esEditar = true;
            this.submitted = false;
            this.mostrarModal = true;
        },
        obtenerActividadesLocal() {
            this.actividadesCargando = true;
            this.obtenerActividades()
                .then((res) => {
                    this.actividades = res.body;
                    if (this.esEditar) {
                        this.$v.form.actividad.$model = {
                            actividad_id: this.actividadProyectoSeleccionada
                                .actividad_id,
                            nombre_actividad: this.actividadProyectoSeleccionada
                                .nombre_actividad,
                        };
                    }
                    this.actividadesCargando = false;
                })
                .catch((error) => {
                    this.actividadesCargando = false;
                });
        },
        obtenerActividadesProyectoLocal() {
            this.actividadesProyectoCargando = true;

            this.obtenerActividadesProyecto({
                codigoLugarInstalacion: this.codigoLugarInstalacion,
                codigoProyecto: this.codigoProyecto,
            })
                .then((res) => {
                    this.actividadesProyecto = res.body;
                    this.actividadesProyectoCargando = false;
                    this.totalRows = this.actividadesProyecto
                })
                .catch((error) => {
                    this.actividadesProyectoCargando = false;
                });
        },

        crearActividadesProyecto() {
            this.loading = true;
            this.crearActividadesProyectoBase({
                codigo_lugar_instalacion: this.codigoLugarInstalacion,
                codigo_proyecto: this.codigoProyecto,
            })
                .then((res) => {
                    if (res.status == 201) {
                        this.mensaje.variant = "success";
                        this.mensaje.texto =
                            "Los datos se han actualizado correctamente!!!";
                    }

                    if (res.status == 202) {
                        this.mensaje.variant = "danger";
                        this.mensaje.texto =
                            "El lugar de instalación no tiene el tipo de proyecto configurado";
                    }

                    if (res.status == 204) {
                        this.mensaje.variant = "danger";
                        this.mensaje.texto =
                            "No existe configuración de actividades para este tipo de proyecto";
                    }

                    if (res.status == 208) {
                        this.mensaje.variant = "danger";
                        this.mensaje.texto = "Las actividades ya existen!!!";
                    }

                    this.segundosMensajeActualizacion = this.segundos;
                    this.obtenerActividadesProyectoLocal();
                    this.loading = false;
                })
                .catch((error) => {
                    this.loading = false;
                });
        },
        submit(e) {
            this.loadingForm = true;
            this.submitted = true;
            this.$v.form.$touch();

            let validarFechas = false;
            if (this.$v.form.fechaInicio.$model == null) {
                this.fechaInicioValidar = false;
                validarFechas = true;
            }
            if (this.$v.form.fechaTermino.$model == null) {
                this.fechaTerminoValidar = false;
                validarFechas = true;
            }

            if (this.$v.form.$invalid) {
                this.loadingForm = false;
                return;
            }
            if (validarFechas) {
                this.loadingForm = false;
                return;
            }
            const actividad_proyecto_id = this.actividadProyectoSeleccionada
                .actividad_proyecto_id;
            const codigo_proyecto = this.codigoProyecto;
            const codigo_lugar_instalacion = this.codigoLugarInstalacion;
            const actividad_id = this.$v.form.actividad.$model.actividad_id;
            const nombre_actividad = this.$v.form.actividad.$model
                .nombre_actividad;
            const depende_de = this.$v.form.dependeDe.$model;
            const duracion = this.$v.form.duracion.$model;
            const fecha_inicio = this.$v.form.fechaInicio.$model;
            const fecha_termino = this.$v.form.fechaTermino.$model;
            const total = this.$v.form.total.$model;
            const ponderacion = this.$v.form.ponderacion.$model;
            const unidad = this.$v.form.unidad.$model.value;

            if (this.esEditar) {
                this.actualizarActividadProyecto({
                    actividad_proyecto_id,
                    codigo_proyecto,
                    codigo_lugar_instalacion,
                    actividad_id,
                    nombre_actividad,
                    depende_de,
                    duracion,
                    fecha_inicio,
                    fecha_termino,
                    total,
                    ponderacion,
                    unidad,
                })
                    .then((res) => {
                        if (res.status == 202) {
                            this.mensaje.variant = "danger";
                            this.mensaje.texto =
                                "Ha ocurrido un error, intente nuevamente";
                        }

                        if (res.status == 204) {
                            this.mensaje.variant = "success";
                            this.mensaje.texto =
                                "Proyecto actualizado correctamente!!!";
                            if (this.guardarCerrar) {
                                this.mostrarModal = false;
                            }
                        }

                        this.segundosMensajeActualizacion = this.segundos;
                        this.obtenerActividadesProyectoLocal();
                        this.loadingForm = false;
                    })
                    .catch((error) => {
                        this.mensaje.variant = "danger";
                        this.mensaje.texto =
                            "Ha ocurrido un error, intente nuevamente";
                        this.mostrarModal = false;
                        this.segundosMensajeActualizacion = this.segundos;
                        this.loadingForm = false;
                    });
            } else {
                this.crearActividadProyecto({
                    codigo_proyecto,
                    codigo_lugar_instalacion,
                    actividad_id,
                    nombre_actividad,
                    depende_de,
                    duracion,
                    fecha_inicio,
                    fecha_termino,
                    total,
                    ponderacion,
                    unidad,
                })
                    .then((res) => {
                        if (res.status == 201) {
                            this.mensaje.variant = "success";
                            this.mensaje.texto =
                                "Actividad creada correctamente!!!";
                            if (this.guardarCerrar) {
                                this.mostrarModal = false;
                            } else {
                                this.resetFormAgregarOtro();
                            }
                        }

                        if (res.status == 208) {
                            this.mensaje.variant = "danger";
                            this.mensaje.texto = "Las actividad ya existe!!!";
                        }

                        this.segundosMensajeActualizacion = this.segundos;
                        this.obtenerActividadesProyectoLocal();
                        this.loadingForm = false;
                    })
                    .catch((error) => {
                        this.mensaje.variant = "danger";
                        this.mensaje.texto =
                            "Ha ocurrido un error, intente nuevamente";
                        this.mostrarModal = false;
                        this.segundosMensajeActualizacion = this.segundos;
                        this.loadingForm = false;
                    });
            }

            this.submitted = false;
        },
        resetForm() {
            this.form = {
                actividad: null,
                dependeDe: null,
                duracion: null,
                fechaInicio: null,
                fechaTermino: null,
                total: null,
                ponderacion: null,
                unidad: null,
            };

            this.$nextTick(() => {
                this.$v.$reset();
            });
        },
        resetFormAgregarOtro() {
            this.form.actividad = null;
            this.form.dependeDe = null;
            this.form.duracion = null;
            this.form.fechaInicio = null;
            this.form.fechaTermino = null;
            this.form.total = null;
            this.form.ponderacion = null;
            this.form.unidad = null;

            this.$nextTick(() => {
                this.$v.$reset();
            });
        },
        eliminarActividadProyectoLocal(item) {
            const mensajeEliminar = Swal.mixin({
                customClass: {
                    title: "font-size-18",
                    confirmButton: "btn btn-danger ms-2",
                    cancelButton: "btn btn-gris-claro",
                },
                buttonsStyling: false,
            });
            mensajeEliminar
                .fire({
                    title: "Esta seguro que desea eliminar el registro?",
                    text: "",
                    icon: "warning",
                    showCancelButton: true,
                    confirmButtonText: "Eliminar",
                    cancelButtonText: "Cancelar",
                    allowOutsideClick: false,
                    reverseButtons: true
                })
                .then((result) => {
                    if (result.value) {
                        this.eliminarActividadProyecto(
                            item.actividad_proyecto_id
                        )
                            .then((res) => {
                                if (res.status == 202) {
                                    this.mensaje.variant = "danger";
                                    this.mensaje.texto =
                                        "Ha ocurrido un error, intente nuevamente";
                                }

                                if (res.status == 204) {
                                    this.mensaje.variant = "success";
                                    this.mensaje.texto =
                                        "Actividad eliminada correctamente!!!";
                                    if (this.guardarCerrar) {
                                        this.mostrarModal = false;
                                    }
                                }
                                this.segundosMensajeActualizacion = this.segundos;
                                this.obtenerActividadesProyectoLocal();
                            })
                            .catch((error) => {
                                this.mensaje.variant = "danger";
                                this.mensaje.texto =
                                    "No se ha eliminado el registro, intente nuevamente";
                                this.mostrarModal = false;
                                this.segundosMensajeActualizacion = this.segundos;
                            });
                    }
                });
        },
        irLugarinstalacion(proyecto) {
            this.seleccionarProyecto(proyecto[0]);
            this.$router.push({
                name: "lugaresInstalacion",
                params: { codigoProyecto: proyecto[0].codigo_proyecto },
            });
        },
        formatDate(value) {
            const duration = moment(value).format("DD/MM/yyyy");
            return `${duration}`;
        },
        onFiltered(filteredItems) {
            this.totalRows = filteredItems.length;
            this.currentPage = 1;
        },

        todoFiltered(filteredItems) {
            this.todoTotalRows = filteredItems.length;
            this.todocurrentPage = 1;
        },

        handleInput(value, data) {
            this.editableDataItems[data.index][data.field.key] = value;
        },
    },
    computed: {
        ...proyectoComputed,
        ...authUsuarioComputed,
    },
};
</script>

<style>
.crearActividadesProyecto {
    margin-right: 10px;
}
@media (min-width: 576px) {
    .boton-actividades-proyectos {
        width: auto;
    }
}

@media (max-width: 576px) {
    .boton-actividades-proyectos {
        margin-top: 10px;
        
    }
}
</style>
