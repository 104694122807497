<template>
  <v-container class="p-2">
    <div v-if="reasonReject">
      <label for="comments" class="mt-4 font-weight-bold">
        Motivo de rechazo:
      </label>
      <p>
        {{ reasonReject }}
      </p>
    </div>
    <div>
      <label for="comments" class="mt-4 font-weight-bold">
        Rechazado el día:
      </label>
      <p>
        {{ moment(dateRejected).format("DD/MM/YYYY") }}
      </p>
    </div>
    <div>
      <label for="comments" class="mt-4 font-weight-bold">
        Quien rechazó:
      </label>
      <p v-html="getUserFromEmail(rejectedBy)"></p>
    </div>
  </v-container>
</template>

<script>
import moment from "moment";
import { mapGetters } from "vuex";
export default {
  props: {
    reasonReject: {
      default: null,
    },
    rejectedBy: {
      default: null,
    },
    dateRejected: {
      default: null,
    },
  },
  data() {
    return {
      moment,
    };
  },
  methods: {
    getUserFromEmail(email) {
      let user = this.usersActiveContract.find((user) => user.email == email);
      return user
        ? `${user.name} <small>(${user.email})</small>`
        : `<span>${email}</span>`;
    },
  },
  computed: {
    ...mapGetters({
      usersActiveContract: "user/users",
    }),
  },
};
</script>

<style></style>
