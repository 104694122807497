<template>
  <v-card>
    <b-overlay :show="loading" variant="white" rounded="sm" class="h-100 mb-5">
      <v-card-actions>
        <v-col class="text-right">
          <v-btn
            class="bg-primary text-white"
            v-if="writeable"
            @click="abrirModalEditar"
          >
            Editar Datos
          </v-btn>
        </v-col>
      </v-card-actions>

      <v-card-title>
        Información Técnica de Lugar de Instalación
      </v-card-title>

      <v-card-text>
        <b-alert
          :show="segundosMensajeActualizacion"
          dismissible
          :variant="mensaje.variant"
          @dismissed="segundosMensajeActualizacion = 0"
          @dismiss-count-down="actualizarSegundosMensajeActualizacion"
        >
          {{ mensaje.texto }}
        </b-alert>

        <!-- <p class="card-title-desc"></p> -->
        <div class="row">
          <div class="col-12 col-sm-4 mt-4">
            <div>
              <i class="fas fa-bolt icono"></i>
              <div class="contenedor-textos">
                <div class="text-muted">
                  Potencia Peak [W]
                </div>
                <div>
                  {{ informacionTecnica.potencia_peak }}
                </div>
              </div>
            </div>
          </div>
          <div class="col-12 col-sm-4 mt-4">
            <div>
              <i class="fas fa-charging-station icono"></i>
              <div class="contenedor-textos">
                <div class="text-muted">
                  Potencia AC [W]
                </div>
                <div>
                  {{ informacionTecnica.potencia_ac }}
                </div>
              </div>
            </div>
          </div>
          <div class="col-12 col-sm-4 mt-4">
            <div>
              <i class="dripicons-battery-full icono"></i>
              <div class="contenedor-textos">
                <div class="text-muted">
                  Capacidad Baterías [KWh]
                </div>
                <div>
                  {{ informacionTecnica.capacidad_baterias }}
                </div>
              </div>
            </div>
          </div>
          <!-- </div> -->
          <!-- <div class="row mt-4"> -->
          <div class="col-12 col-sm-4 mt-4">
            <div>
              <i class="mdi mdi-tools icono"></i>
              <div class="contenedor-textos">
                <div class="text-muted">
                  Tipo Instalacion
                </div>
                <div>
                  {{ informacionTecnica.nombre_tipo_instalacion }}
                </div>
              </div>
            </div>
          </div>
          <div class="col-12 col-sm-4 mt-4">
            <div>
              <i class="fas fa-plug icono"></i>
              <div class="contenedor-textos">
                <div class="text-muted">
                  Fase Instalacion
                </div>
                <div>
                  {{ informacionTecnica.fase_instalacion }}
                </div>
              </div>
            </div>
          </div>
          <div class="col-12 col-sm-4 mt-4">
            <div>
              <i class="fas fa-user-cog icono"></i>
              <div class="contenedor-textos">
                <div class="text-muted">
                  Tipo Cliente
                </div>
                <div>
                  {{ informacionTecnica.nombre_tipo_cliente }}
                </div>
              </div>
            </div>
          </div>
          <!-- </div> -->
          <!-- <div class="row mt-4"> -->
          <div class="col-12 col-sm-4 mt-4">
            <div>
              <i class="fas fa-list-alt icono"></i>
              <div class="contenedor-textos">
                <div class="text-muted">
                  Normativa
                </div>
                <div>
                  {{ informacionTecnica.nombre_normativa }}
                </div>
              </div>
            </div>
          </div>
          <div class="col-12 col-sm-4 mt-4">
            <div>
              <i class="far fa-address-card icono"></i>
              <div class="contenedor-textos">
                <div class="text-muted">
                  Cliente Electrico
                </div>
                <div>
                  {{ informacionTecnica.cliente_electrico }}
                </div>
              </div>
            </div>
          </div>

          <!-- <div class="col-12 col-sm-4 mt-4">
                            <div>
                                <i class="fas fa-sun icono"></i>
                                <div class="contenedor-textos">
                                    <div class="text-muted">
                                        App. Flex energia
                                    </div>
                                    <div>
                                        {{
                                            informacionTecnica.nombre_app_flex_energetica
                                        }}
                                    </div>
                                </div>
                            </div>
                        </div> -->
          <div class="col-12 col-sm-4 mt-4">
            <div>
              <i class="fas fa-signal icono"></i>
              <div class="contenedor-textos">
                <div class="text-muted">
                  Capacidad Empalme [A]
                </div>
                <div>
                  {{ informacionTecnica.capacidad_empalme }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </v-card-text>
    </b-overlay>
    <b-modal
      v-model="mostrarModal"
      title="Actualizar información Técnica"
      hide-footer
      size="lg"
    >
      <loading-flux :value="loadingSubmit"></loading-flux>

      <form @submit.prevent="submit">
        <div class="row">
          <div class="col-lg-6">
            <div class="mb-3">
              <label for="potenciaPeak">Potencia Peak [W]</label>
              <input
                id="potenciaPeak"
                v-model="$v.form.potenciaPeak.$model"
                type="number"
                step="0.001"
                class="form-control"
                placeholder="Potencia Peak [W]"
                :class="{
                  'is-invalid': submitted && $v.form.potenciaPeak.$error,
                }"
                @keypress="validarNumero"
              />
              <div
                v-if="submitted && !$v.form.potenciaPeak.required"
                class="invalid-feedback"
              >
                El campo es obligatorio.
              </div>
            </div>
          </div>
          <div class="col-lg-6">
            <div class="mb-3">
              <label for="potenciaAC">Potencia AC [W]</label>
              <input
                id="potenciaAC"
                v-model="$v.form.potenciaAC.$model"
                type="number"
                step="0.001"
                class="form-control"
                placeholder="Potencia AC [W]"
                :class="{
                  'is-invalid': submitted && $v.form.potenciaAC.$error,
                }"
                @keypress="validarNumero"
              />
              <div
                v-if="submitted && !$v.form.potenciaAC.required"
                class="invalid-feedback"
              >
                El campo es obligatorio.
              </div>
            </div>
          </div>
          <div class="col-lg-6">
            <div class="mb-3">
              <label for="capacidadBaterias">Capacidad Baterías [KWh]</label>
              <input
                id="capacidadBaterias"
                v-model="$v.form.capacidadBaterias.$model"
                type="number"
                step="0.001"
                class="form-control"
                placeholder="Capacidad Bateria [KWh]"
                :class="{
                  'is-invalid': submitted && $v.form.capacidadBaterias.$error,
                }"
                @keypress="validarNumero"
              />
              <div
                v-if="submitted && !$v.form.capacidadBaterias.required"
                class="invalid-feedback"
              >
                El campo es obligatorio.
              </div>
            </div>
          </div>
          <div class="col-lg-6">
            <div class="mb-3">
              <label for="tipoInstalacion">Tipo Instalación</label>
              <multiselect
                v-model="$v.form.tipoInstalacion.$model"
                :options="tipoInstalaciones"
                placeholder="Seleccionar Tipo Proyecto"
                value="tipo_instalacion_id"
                label="nombre_tipo_instalacion"
                :searchable="true"
                :loading="tipoInstalacionesCargando"
                class="helo"
                :class="{
                  'is-invalid': submitted && $v.form.tipoInstalacion.$error,
                }"
              ></multiselect>
              <div
                v-if="submitted && !$v.form.tipoInstalacion.required"
                class="invalid-feedback"
              >
                El campo es obligatorio.
              </div>
            </div>
          </div>

          <div class="col-lg-6">
            <div class="mb-3">
              <label for="faseInstalacion">Fase Instalacion</label>
              <multiselect
                v-model="$v.form.faseInstalacion.$model"
                :options="fasesInstalacion"
                placeholder="Seleccionar Fase"
                value="value"
                label="label"
                :searchable="true"
                :loading="fasesInstalacionCargando"
                class="helo"
                :class="{
                  'is-invalid': submitted && $v.form.faseInstalacion.$error,
                }"
              ></multiselect>
              <div
                v-if="submitted && !$v.form.faseInstalacion.required"
                class="invalid-feedback"
              >
                El campo es obligatorio.
              </div>
            </div>
          </div>

          <div class="col-lg-6">
            <div class="mb-3">
              <label for="tipoCliente">Tipo Cliente</label>
              <multiselect
                v-model="$v.form.tipoCliente.$model"
                :options="tipoClientes"
                placeholder="Seleccionar Tipo Cliente"
                value="tipo_cliente_id"
                label="nombre_tipo_cliente"
                :searchable="true"
                :loading="tipoClientesCargando"
                class="helo"
                :class="{
                  'is-invalid': submitted && $v.form.tipoCliente.$error,
                }"
              ></multiselect>
              <div
                v-if="submitted && !$v.form.tipoCliente.required"
                class="invalid-feedback"
              >
                El campo es obligatorio.
              </div>
            </div>
          </div>

          <div class="col-lg-6">
            <div class="mb-3">
              <label for="normativa">Normativa</label>
              <multiselect
                v-model="$v.form.normativa.$model"
                :options="normativas"
                placeholder="Seleccionar Normativa"
                value="normativa_id"
                label="nombre_normativa"
                :searchable="true"
                :loading="normativasCargando"
                class="helo"
                :class="{
                  'is-invalid': submitted && $v.form.normativa.$error,
                }"
              ></multiselect>
              <div
                v-if="submitted && !$v.form.normativa.required"
                class="invalid-feedback"
              >
                El campo es obligatorio.
              </div>
            </div>
          </div>

          <div class="col-lg-6">
            <div class="mb-3">
              <label for="clienteElectrico">Cliente Electrico</label>
              <multiselect
                v-model="$v.form.clienteElectrico.$model"
                :options="clientesElectrico"
                placeholder="Seleccionar Cliente Electrico"
                value="value"
                label="label"
                :searchable="true"
                :loading="clientesElectricoCargando"
                class="helo"
                :class="{
                  'is-invalid': submitted && $v.form.clienteElectrico.$error,
                }"
              ></multiselect>
              <div
                v-if="submitted && !$v.form.clienteElectrico.required"
                class="invalid-feedback"
              >
                El campo es obligatorio.
              </div>
            </div>
          </div>

          <div class="col-lg-6" v-if="false">
            <div class="mb-3">
              <label for="appFlexEnergetica">App Flex Energetica</label>
              <multiselect
                v-model="$v.form.appFlexEnergetica.$model"
                :options="appFlexEnergeticas"
                placeholder="Seleccionar App Flex Energetica"
                value="app_flex_energetica_id"
                label="nombre_app_flex_energetica"
                :searchable="true"
                :loading="clientesElectricoCargando"
                class="helo"
                :class="{
                  'is-invalid': submitted && $v.form.appFlexEnergetica.$error,
                }"
              ></multiselect>
              <div
                v-if="submitted && !$v.form.appFlexEnergetica.required"
                class="invalid-feedback"
              >
                El campo es obligatorio.
              </div>
            </div>
          </div>
          <div class="col-lg-6">
            <div class="mb-3">
              <label for="capacidadEmpalme">Capacidad Empalme [A]</label>
              <input
                id="capacidadEmpalme"
                v-model="$v.form.capacidadEmpalme.$model"
                type="number"
                step="0.001"
                class="form-control"
                placeholder="Capacidad Empalme [A]"
                :class="{
                  'is-invalid': submitted && $v.form.capacidadEmpalme.$error,
                }"
                @keypress="validarNumero"
              />
              <div
                v-if="submitted && !$v.form.capacidadEmpalme.required"
                class="invalid-feedback"
              >
                El campo es obligatorio.
              </div>
            </div>
          </div>
        </div>
        <div class="text-end mt-3">
          <b-button variant="light" @click="mostrarModal = false"
            >Cerrar</b-button
          >
          <b-button type="submit" variant="success" class="ms-1"
            >Actualizar</b-button
          >
        </div>
      </form>
    </b-modal>
  </v-card>
</template>

<script>
import {
  informacionTecnicaMethods,
  informacionTecnicaComputed,
} from "@/state/helpers";
import { tipoClienteMethods, tipoClienteComputed } from "@/state/helpers";
import {
  tipoInstalacionMethods,
  tipoInstalacionComputed,
} from "@/state/helpers";
import { normativaMethods, normativaComputed } from "@/state/helpers";
import {
  appFlexEnergeticaMethods,
  appFlexEnergeticaComputed,
} from "@/state/helpers";
import { proyectoMethods, proyectoComputed } from "@/state/helpers";
import { required, numeric } from "vuelidate/lib/validators";
import { validationMixin } from "vuelidate";
import Multiselect from "vue-multiselect";
import Swal from "sweetalert2";
import { authMethods, authUsuarioComputed } from "@/state/helpers";
import { permiso } from "@/helpers/authservice/obtener-permisos";
export default {
  name: "LugarInformacionTecnica",
  mixins: [validationMixin],
  components: {
    Multiselect,
  },
  props: {
    writeable: {
      type: Boolean,
      default: false,
    },
    showTitle: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      loading: false,
      loadingSubmit: false,
      mensaje: { variant: "", texto: "" },
      segundos: 5,
      segundosMensajeActualizacion: 0,
      title: "Información Técnica",
      items: [
        {
          text: "Proyectos",
          href: `/proyectos/proyectos`,
        },
        {
          text: "Lugares Instalación",
          href: `/proyectos/lugaresInstalacion/${this.$route.params.codigoProyecto}`,
        },
        {
          text: "Información Técnica",
          active: true,
        },
      ],
      informacionTecnica: {},
      submitted: false,
      mostrarModal: false,
      tipoInstalaciones: [],
      fasesInstalacion: [
        { value: "MONOFASICO", label: "MONOFASICO" },
        { value: "TRIFASICO", label: "TRIFASICO" },
        { value: "NO APLICA", label: "NO APLICA" },
      ],
      tipoClientes: [],
      clientesElectrico: [
        { value: "Cliente Regulado", label: "Cliente Regulado" },
        { value: "Cliente Libre", label: "Cliente Libre" },
        {
          value: "Desconectado/Aislado",
          label: "Desconectado/Aislado",
        },
      ],

      normativas: [],
      appFlexEnergeticas: [],

      tipoInstalacionesCargando: false,
      fasesInstalacionCargando: false,
      tipoClientesCargando: false,
      clientesElectricoCargando: false,
      normativasCargando: false,
      appFlexEnergeticasCargando: false,
      form: {
        potenciaPeak: null,
        potenciaAC: null,
        capacidadBaterias: null,
        tipoInstalacion: null,
        faseInstalacion: null,
        tipoCliente: null,
        clienteElectrico: null,
        normativa: null,
        appFlexEnergetica: null,
        capacidadEmpalme: null,
      },
      rolModuloPermiso: {},
      proyectoSeleccionado: {},
    };
  },
  validations: {
    form: {
      potenciaPeak: {},
      potenciaAC: {},
      capacidadBaterias: {},
      tipoInstalacion: {},
      faseInstalacion: {},
      tipoCliente: {},
      clienteElectrico: {},
      normativa: {},
      appFlexEnergetica: {},
      capacidadEmpalme: {},
    },
  },
  mounted() {
    this.codigoProyecto = this.$route.params.codigoProyecto;
    this.codigoLugarInstalacion = this.$route.params.codigoLugarInstalacion;
    this.obtenerInformacionTecnicaProyecto();
    let permisos = permiso.obtenerPermisos(this.user, "informacionTecnica");
    this.rolModuloPermiso = permisos;
  },
  methods: {
    ...informacionTecnicaMethods,
    ...tipoClienteMethods,
    ...tipoInstalacionMethods,
    ...normativaMethods,
    ...appFlexEnergeticaMethods,
    ...proyectoMethods,
    validarNumero(event) {
      if (event.keyCode < 45 || event.keyCode > 57) {
        event.returnValue = false;
      }
      if (event.keyCode == 45) {
        event.returnValue = false;
      }
    },
    actualizarSegundosMensajeActualizacion(segundosMensajeActualizacion) {
      this.segundosMensajeActualizacion = segundosMensajeActualizacion;
    },
    abrirModalNuevo() {
      this.resetForm();
      this.esEditar = false;
      this.submitted = false;
      this.mostrarModal = true;
    },
    abrirModalEditar() {
      this.obtenerTipoInstalacionesProyecto();
      this.obtenerTipoClientesProyecto();
      this.obtenerNormativasProyecto();
      this.obtenerAppFlexEnergeticasProyecto();

      this.$v.form.potenciaPeak.$model = this.informacionTecnica.potencia_peak;
      this.$v.form.potenciaAC.$model = this.informacionTecnica.potencia_ac;
      this.$v.form.capacidadBaterias.$model = this.informacionTecnica.capacidad_baterias;
      this.$v.form.capacidadEmpalme.$model = this.informacionTecnica.capacidad_empalme;

      this.$v.form.faseInstalacion.$model = {
        value: this.informacionTecnica.fase_instalacion,
        label: this.informacionTecnica.fase_instalacion,
      };
      this.$v.form.clienteElectrico.$model = {
        value: this.informacionTecnica.cliente_electrico,
        label: this.informacionTecnica.cliente_electrico,
      };
      this.esEditar = true;
      this.submitted = false;
      this.mostrarModal = true;
    },
    obtenerInformacionTecnicaProyecto() {
      this.loading = true;
      this.obtenerInformacionTecnica({
        codigoProyecto: this.codigoProyecto,
        codigoLugarInstalacion: this.codigoLugarInstalacion,
      })
        .then((res) => {
          this.informacionTecnica = res.body || {};
          this.loading = false;
        })
        .catch((error) => {
          this.loading = false;
        });
    },
    obtenerNormativasProyecto() {
      this.normativasCargando = true;
      this.obtenerNormativas()
        .then((res) => {
          this.normativas = res.body;
          if (this.informacionTecnica.normativa_id != null) {
            this.$v.form.normativa.$model = {
              normativa_id: this.informacionTecnica.normativa_id,
              nombre_normativa: this.informacionTecnica.nombre_normativa,
            };
          }

          this.normativasCargando = false;
        })
        .catch((error) => {
          this.normativasCargando = false;
        });
    },
    obtenerTipoInstalacionesProyecto() {
      this.tipoInstalacionesCargando = true;
      this.obtenerTipoInstalaciones()
        .then((res) => {
          this.tipoInstalaciones = res.body;
          if (this.informacionTecnica.tipo_instalacion_id != null) {
            this.$v.form.tipoInstalacion.$model = {
              tipo_instalacion_id: this.informacionTecnica.tipo_instalacion_id,
              nombre_tipo_instalacion: this.informacionTecnica
                .nombre_tipo_instalacion,
            };
          }

          this.tipoInstalacionesCargando = false;
        })
        .catch((error) => {
          this.tipoInstalacionesCargando = false;
        });
    },
    obtenerTipoClientesProyecto() {
      this.tipoClientesCargando = true;
      this.obtenerTipoClientes()
        .then((res) => {
          this.tipoClientes = res.body;
          if (this.informacionTecnica.tipo_cliente_id != null) {
            this.$v.form.tipoCliente.$model = {
              tipo_cliente_id: this.informacionTecnica.tipo_cliente_id,
              nombre_tipo_cliente: this.informacionTecnica.nombre_tipo_cliente,
            };
          }

          this.tipoClientesCargando = false;
        })
        .catch((error) => {
          this.tipoClientesCargando = false;
        });
    },
    obtenerAppFlexEnergeticasProyecto() {
      this.appFlexEnergeticasCargando = true;
      this.obtenerAppFlexEnergeticas()
        .then((res) => {
          this.appFlexEnergeticas = res.body;
          if (this.informacionTecnica.app_flex_energetica_id != null) {
            this.$v.form.appFlexEnergetica.$model = {
              app_flex_energetica_id: this.informacionTecnica
                .app_flex_energetica_id,
              nombre_app_flex_energetica: this.informacionTecnica
                .nombre_app_flex_energetica,
            };
          }

          this.appFlexEnergeticasCargando = false;
        })
        .catch((error) => {
          this.appFlexEnergeticasCargando = false;
        });
    },

    abrirMenu() {
      this.$bvModal.show("modal-menu-lugar-instalacion");
    },
    submit(e) {
      this.loadingSubmit = true;
      this.submitted = true;
      this.$v.form.$touch();

      if (this.$v.form.$invalid) {
        this.loadingSubmit = false;
        return;
      }
      const codigo_lugar_instalacion = this.informacionTecnica
        .codigo_lugar_instalacion;
      const codigo_proyecto = this.informacionTecnica.codigo_proyecto;

      const potencia_peak = this.$v.form.potenciaPeak.$model;
      const potencia_ac = this.$v.form.potenciaAC.$model;
      const capacidad_baterias = this.$v.form.capacidadBaterias.$model;
      const tipo_instalacion_id =
        this.$v.form.tipoInstalacion.$model != null
          ? this.$v.form.tipoInstalacion.$model.tipo_instalacion_id
          : null;
      const nombre_tipo_instalacion =
        this.$v.form.tipoInstalacion.$model != null
          ? this.$v.form.tipoInstalacion.$model.nombre_tipo_instalacion
          : null;

      const fase_instalacion = this.$v.form.faseInstalacion.$model.value;
      const tipo_cliente_id =
        this.$v.form.tipoCliente.$model != null
          ? this.$v.form.tipoCliente.$model.tipo_cliente_id
          : null;
      const nombre_tipo_cliente =
        this.$v.form.tipoCliente.$model != null
          ? this.$v.form.tipoCliente.$model.nombre_tipo_cliente
          : null;
      const normativa_id =
        this.$v.form.normativa.$model != null
          ? this.$v.form.normativa.$model.normativa_id
          : null;
      const nombre_normativa =
        this.$v.form.normativa.$model != null
          ? this.$v.form.normativa.$model.nombre_normativa
          : null;
      const cliente_electrico = this.$v.form.clienteElectrico.$model.value;
      const app_flex_energetica_id =
        this.$v.form.appFlexEnergetica.$model != null
          ? this.$v.form.appFlexEnergetica.$model.app_flex_energetica_id
          : null;
      // const nombre_app_flex_energetica =
      //     this.$v.form.appFlexEnergetica.$model != null
      //         ? this.$v.form.appFlexEnergetica.$model
      //               .nombre_app_flex_energetica
      //         : null;
      const nombre_app_flex_energetica = null;
      const capacidad_empalme = this.$v.form.capacidadEmpalme.$model;

      if (this.esEditar) {
        this.actualizarInformacionTecnica({
          codigo_lugar_instalacion,
          codigo_proyecto,
          potencia_peak,
          potencia_ac,
          capacidad_baterias,
          tipo_instalacion_id,
          nombre_tipo_instalacion,
          fase_instalacion,
          tipo_cliente_id,
          nombre_tipo_cliente,
          normativa_id,
          nombre_normativa,
          cliente_electrico,
          app_flex_energetica_id,
          nombre_app_flex_energetica,
          capacidad_empalme,
        })
          .then((res) => {
            if (res.status == 500) {
              this.mensaje.variant = "danger";
              this.mensaje.texto =
                "Ha ocurrido un error, Contáctese con el área de Digitalización";
            }

            if (res.status == 202) {
              this.mensaje.variant = "danger";
              this.mensaje.texto = "Ha ocurrido un error, intente nuevamente";
            }

            if (res.status == 204) {
              this.mensaje.variant = "success";
              this.mensaje.texto =
                "Información Técnica actualizada correctamente!!!";
              this.mostrarModal = false;
            }
            this.segundosMensajeActualizacion = this.segundos;
            this.obtenerInformacionTecnicaProyecto();
            this.loadingSubmit = false;
          })
          .catch((error) => {
            this.mensaje.variant = "danger";
            this.mensaje.texto = "Ha ocurrido un error, intente nuevamente";
            this.mostrarModal = false;
            this.segundosMensajeActualizacion = this.segundos;
            this.loadingSubmit = false;
          });
      } else {
        this.crearInformacionTecnica({
          codigo_lugar_instalacion,
          codigo_proyecto,
          potencia_peak,
          potencia_ac,
          capacidad_baterias,
          tipo_instalacion_id,
          nombre_tipo_instalacion,
          fase_instalacion,
          tipo_cliente_id,
          nombre_tipo_cliente,
          normativa_id,
          nombre_normativa,
          cliente_electrico,
          app_flex_energetica_id,
          nombre_app_flex_energetica,
          capacidad_empalme,
        })
          .then((res) => {
            if (res.status == 201) {
              this.mensaje.variant = "success";
              this.mensaje.texto =
                "Información Técnica creada correctamente!!!";
              this.mostrarModal = false;
            }

            if (res.status == 208) {
              this.mensaje.variant = "danger";
              this.mensaje.texto = "La Información Técnica ya existe!!!";
            }

            this.segundosMensajeActualizacion = this.segundos;
            this.obtenerInformacionTecnicaProyecto();
            this.loadingSubmit = false;
          })
          .catch((error) => {
            this.mensaje.variant = "danger";
            this.mensaje.texto = "Ha ocurrido un error, intente nuevamente";

            this.segundosMensajeActualizacion = this.segundos;
            this.loadingSubmit = false;
          });
      }

      this.submitted = false;
    },
    resetForm() {
      this.form = {
        potenciaPeak: null,
        potenciaAC: null,
        capacidadBaterias: null,
        tipoInstalacion: null,
        faseInstalacion: null,
        tipoCliente: null,
        clienteElectrico: null,
        normativa: null,
        appFlexEnergetica: null,
        capacidadEmpalme: null,
      };

      this.$nextTick(() => {
        this.$v.$reset();
      });
    },
    eliminarInformacionTecnicaProyecto(item) {
      const mensajeEliminar = Swal.mixin({
        customClass: {
          title: "font-size-18",
          confirmButton: "btn btn-danger ms-2",
          cancelButton: "btn btn-gris-claro",
        },
        buttonsStyling: false,
      });
      mensajeEliminar
        .fire({
          title: "Esta seguro que desea eliminar el registro?",
          text: "",
          icon: "warning",
          showCancelButton: true,
          confirmButtonText: "Eliminar",
          cancelButtonText: "Cancelar",
          allowOutsideClick: false,
          reverseButtons: true,
        })
        .then((result) => {
          if (result.value) {
            this.eliminarInformacionTecnica(item.informacion_tecnica_id)
              .then((res) => {
                if (res.status == 202) {
                  this.mensaje.variant = "danger";
                  this.mensaje.texto =
                    "Ha ocurrido un error, intente nuevamente";
                }

                if (res.status == 204) {
                  this.mensaje.variant = "success";
                  this.mensaje.texto =
                    "Información Técnica eliminada correctamente!!!";
                  if (this.guardarCerrar) {
                    this.mostrarModal = false;
                  }
                }
                this.segundosMensajeActualizacion = this.segundos;
                this.obtenerInformacionTecnicaProyecto();
              })
              .catch((error) => {
                this.mensaje.variant = "danger";
                this.mensaje.texto =
                  "No se ha eliminado el registro, intente nuevamente";
                this.mostrarModal = false;
                this.segundosMensajeActualizacion = this.segundos;
              });
          }
        });
    },
  },
  computed: {
    ...proyectoComputed,
    ...authUsuarioComputed,
    // ...informacionTecnicaComputed,
  },
};
</script>
<style scoped>
@media (max-width: 480px) {
  .d-flex {
    flex-direction: column !important;
  }
  .col-auto {
    display: block !important;
    width: 100% !important;
  }
}
.icono {
  float: left;
  font-size: 1.2rem;
  margin-top: 10px;
  color: #555;
}
.contenedor-textos {
  margin-left: 50px;
}
</style>
