<template>
  <section>
    <v-data-table
      class="px-1 elevation-2 my-4"
      :headers="tableHeaders"
      :items="materials"
      :items-per-page="100"
      hide-default-footer
      item-key="material_id"
      no-data-text="Sin materiales"
    >
      <template v-slot:item.code="{ item }">
        <span style="font-size: 12.5px">{{ item.code }}</span>
      </template>
      <template v-slot:item.description="{ item }">
        <span style="font-size: 12.5px">{{ item.description }}</span>
      </template>
      <template v-slot:item.quantity="{ item }">
        <span style="font-size: 12.5px">{{ item.quantity }}</span>
      </template>
      <template v-slot:item.price="{ item }">
        <span style="font-size: 12.5px">{{
          `${Number(item.price).toFixed(2)} $`
        }}</span>
      </template>
    </v-data-table>
  </section>
</template>

<script>
export default {
  props: {
    materials: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      tableHeaders: [
        {
          text: "Código",
          align: "start",
          width: "5%",
          sortable: false,
          value: "code",
        },
        {
          text: "Descripción",
          align: "start",
          width: "50%",
          sortable: false,
          value: "description",
        },
        {
          text: "Cantidad solicitada",
          align: "start",
          width: "10%",
          sortable: false,
          value: "quantity",
        },
        {
          text: "Precio",
          align: "start",
          width: "20%",
          sortable: false,
          value: "price",
        },
      ],
    };
  },
};
</script>

<style></style>
