var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',[_c('v-dialog',{attrs:{"max-width":"1400px","scrollable":""},on:{"keydown":_vm.executeCloseModalListMaterialsRequest,"click:outside":_vm.executeCloseModalListMaterialsRequest},model:{value:(_vm.modalAction),callback:function ($$v) {_vm.modalAction=$$v},expression:"modalAction"}},[_c('v-card',[_c('div',{staticClass:"p-4 pb-0"},[_c('h5',[_vm._v("Detalles de la Solicitud")])]),_c('hr'),_c('v-spacer'),_c('v-card-text',[_c('v-container',{staticClass:"px-1"},[(_vm.currentRequestMaterials)?_c('v-row',{attrs:{"align-content":"center"}},[_c('v-col',{attrs:{"cols":"12","md":"4","sm":"12"}},[_c('v-card-subtitle',{staticClass:"pb-0 font-weight-bold"},[_vm._v(" CeCo / Proyecto ")]),_c('v-card-text',{staticClass:"text--primary mt-1"},[_c('span',[_vm._v(" "+_vm._s(_vm.currentRequestMaterials.project ? _vm.currentRequestMaterials.project.code : _vm.currentRequestMaterials.cost_center.sap_code)+" ")])])],1),_c('v-col',{attrs:{"cols":"12","md":"5","sm":"12"}},[_c('v-card-subtitle',{staticClass:"pb-0 font-weight-bold"},[_vm._v(" Usuario que Solicita ")]),_c('v-card-text',{staticClass:"text--primary mt-1"},[_c('span',{domProps:{"innerHTML":_vm._s(
                    _vm.getUserFromEmail(_vm.currentRequestMaterials.created_by)
                  )}})])],1),_c('v-col',{attrs:{"cols":"12","md":"3","sm":"12"}},[_c('v-card-subtitle',{staticClass:"pb-0 pt-3 ml-1 font-weight-bold"},[_vm._v(" Estado de la Solicitud ")]),_c('v-card-text',{staticClass:"text--primary"},[_c('v-chip',{staticClass:"text-white mt-1",attrs:{"color":_vm.getColorStatus(_vm.currentRequestMaterials),"small":""}},[_vm._v(_vm._s(_vm.currentRequestMaterials.cancelled ? "Anulada" : _vm.currentRequestMaterials.rejected ? "Rechazada" : _vm.currentRequestMaterials.sent_to_erp ? "Aprobada" : "Pendiente"))])],1)],1),(_vm.currentRequestMaterials.sap_stage)?_c('v-col',{attrs:{"cols":"12","md":"4","sm":"12"}},[_c('v-card-subtitle',{staticClass:"pb-0 font-weight-bold"},[_vm._v(" Etapa del Proyecto ")]),_c('v-card-text',{staticClass:"text--primary mt-1"},[_c('span',[_vm._v(" "+_vm._s(_vm.currentRequestMaterials.sap_stage)+" ")])])],1):_vm._e()],1):_vm._e(),_c('v-tabs',{staticClass:"mt-4",attrs:{"fixed-tabs":""},model:{value:(_vm.tab),callback:function ($$v) {_vm.tab=$$v},expression:"tab"}},[_c('v-tab',{key:"materials"},[_vm._v(" "+_vm._s(_vm.currentRequestMaterials && _vm.currentRequestMaterials.is_service ? "Servicios" : "Materiales")+" ")]),(
                _vm.currentRequestMaterials && !_vm.currentRequestMaterials.rejected
              )?_c('v-tab',{key:"approval",attrs:{"id":"approvalList"}},[_vm._v(" Aprobación de la Solicitud ")]):_vm._e(),(
                _vm.currentRequestMaterials &&
                  !_vm.currentRequestMaterials.rejected &&
                  _vm.currentRequestMaterials.restriction_approvers.length
              )?_c('v-tab',{key:"approval-restrictions"},[_vm._v(" Aprobaciones de restricción por limitación de SMU ")]):_vm._e(),(
                _vm.currentRequestMaterials && _vm.currentRequestMaterials.rejected
              )?_c('v-tab',{key:"reason-reject"},[_vm._v(" Motivo de Rechazo de la Solicitud ")]):_vm._e()],1),(_vm.currentRequestMaterials)?_c('v-tabs-items',{model:{value:(_vm.tab),callback:function ($$v) {_vm.tab=$$v},expression:"tab"}},[_c('v-tab-item',{key:"materials",staticClass:"pt-3 my-5"},[(_vm.permissionToApprove)?_c('div',{staticClass:"text-danger mb-5 text-center border w-50 mx-auto pt-3 pb-1 border-danger border-2"},[_c('b',[_vm._v("IMPORTANTE")]),(!_vm.ifCurrentRequestOfService)?_c('ul',{staticClass:"text-center mt-3"},[_c('span',{staticClass:"w-50 mx-auto"},[_vm._v(" Recuerda que puedes "),_c('b',[_vm._v("MODIFICAR")]),_vm._v(" la solicitud ")]),_c('br'),_c('span',{staticClass:"w-50 mx-auto"},[_vm._v(" Si realizó cambios a la solicitud, al finalizar presione el botón "),_c('b',[_vm._v("ACTUALIZAR SOLICITUD")])])]):_c('ul',{staticClass:"text-center mt-3"},[_c('li',{staticClass:"mx-8 mb-2"},[_vm._v(" Recuerda que puedes eliminar los servicios de la solicitud ")]),_c('li',{staticClass:"mx-8"},[_vm._v(" Si cotizaste en una divisa distinta al peso chileno, recuerda que el monto total puede variar según el día en que se apruebe la solicitud ")])])]):_vm._e(),_c('v-row',{attrs:{"align-content":"center"}},[(!_vm.ifCurrentRequestOfService)?_c('v-col',{staticClass:"ml-5 pb-0",attrs:{"cols":"12","md":"10","sm":"12"}},[_c('label',{attrs:{"for":"dispatch"}},[_vm._v(" Tipo de Solicitud de Materiales ")]),_c('v-select',{staticClass:"pt-0 w-50",attrs:{"disabled":!_vm.permissionToApprove,"clearable":"","items":_vm.typeRequests.map(function (item) { return item.name; }),"value":_vm.currentRequestMaterials
                        ? _vm.currentRequestMaterials.request_type_original
                        : null},on:{"change":function($event){return _vm.updateValueCurrentRequest($event, 'request_type')}}})],1):_vm._e(),_c('v-col',{attrs:{"cols":"12","md":"4","sm":"12"}},[_c('v-text-field',{staticClass:"ml-5",attrs:{"label":"Buscar","clearable":"","placeholder":"Buscar material"},model:{value:(_vm.filters.filter),callback:function ($$v) {_vm.$set(_vm.filters, "filter", $$v)},expression:"filters.filter"}})],1),_c('v-col',{attrs:{"cols":"12","md":"4","sm":"12"}}),_c('v-col',{attrs:{"cols":"12","md":"4","sm":"12"}},[(_vm.permissionToApprove && !_vm.ifCurrentRequestOfService)?_c('v-btn',{staticClass:"float-right mt-5",attrs:{"small":"","outlined":"","color":"primary text-white"},on:{"click":_vm.openModalTableAddMaterials}},[_vm._v(" Agregar Materiales ")]):_vm._e()],1)],1),_c('v-data-table',{staticClass:"px-2 elevation-4 py-3",attrs:{"headers":_vm.wrappedTableHeaders,"items":_vm.filteredMaterials,"items-per-page":_vm.filters.items !== 'Todos' ? _vm.filters.items : 100,"page":_vm.filters.page,"hide-default-footer":"","loading":_vm.loading,"loading-text":"Cargando Materiales","item-key":"id","no-data-text":"Sin materiales"},on:{"update:page":function($event){return _vm.$set(_vm.filters, "page", $event)},"page-count":function($event){_vm.pageCount = $event}},scopedSlots:_vm._u([{key:"item.code",fn:function(ref){
                        var item = ref.item;
return [_c('p',{staticClass:"my-0 text-left"},[_vm._v(" "+_vm._s(item.code)+" ")])]}},{key:"item.description",fn:function(ref){
                        var item = ref.item;
return [_c('p',{staticClass:"my-0 text-left"},[_vm._v(" "+_vm._s(item.description ? item.description : "Cargando Material..")+" ")])]}},{key:"item.price",fn:function(ref){
                        var item = ref.item;
return [_c('span',[_vm._v(" "+_vm._s("$ " + item.price.toLocaleString("es", _vm.optionsFormatting))+" "+_vm._s((item.option != "Precio Real" && !_vm.currentRequestMaterials.is_service ) ? "*" : "")+" ")])]}},{key:"item.subtotal",fn:function(ref){
                        var item = ref.item;
return [_c('span',[_vm._v(" "+_vm._s("$ " + _vm.getSubtotalPrice(item).toLocaleString( "es", _vm.optionsFormatting ))+" ")])]}},{key:"item.quantity",fn:function(ref){
                        var item = ref.item;
return [(
                      _vm.permissionToApprove &&
                        !_vm.currentRequestHaveApproversWithApproved &&
                        (!_vm.ifCurrentRequestOfService ||
                          _vm.codeProductIsActive(item.code))
                    )?_c('v-text-field',{staticClass:"pt-0 mt-0",attrs:{"type":"number","min":1,"value":item.quantity,"hide-details":""},on:{"input":function($event){return _vm.updateValueMaterialRequestApprove(
                        $event,
                        item,
                        'quantity'
                      )}}}):_c('p',{staticClass:"my-0 text-right"},[_vm._v(" "+_vm._s(item.quantity)+" ")])]}},{key:"item.actions",fn:function(ref){
                      var item = ref.item;
return [_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                      var on = ref.on;
                      var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","small":"","color":"red"},on:{"click":function($event){return _vm.deleteMaterial(item)}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v(" mdi-delete-outline ")])],1)]}}],null,true)},[_c('span',[_vm._v("Eliminar "+_vm._s(!_vm.ifCurrentRequestOfService ? "Material" : "Servicio"))])]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                      var on = ref.on;
                      var attrs = ref.attrs;
return [(_vm.ifCurrentRequestOfService)?_c('v-btn',_vm._g(_vm._b({staticClass:"mx-1",attrs:{"icon":"","small":""},on:{"click":function($event){return _vm.openModalFilesUploadService(item)}}},'v-btn',attrs,false),on),[_c('v-badge',{attrs:{"bordered":"","color":item.documents.length ? 'green' : 'red',"content":item.documents.length
                              ? item.documents.length
                              : '0',"overlap":""}},[_c('v-icon',[_vm._v(" mdi-file-multiple-outline ")])],1)],1):_vm._e()]}}],null,true)},[_c('span',[_vm._v("Documentos del Servicio")])]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                              var on = ref.on;
                              var attrs = ref.attrs;
return [(_vm.ifCurrentRequestOfService)?_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","small":"","color":"primary"},on:{"click":function($event){return _vm.openModalCommentsService(item)}}},'v-btn',attrs,false),on),[_c('v-badge',{attrs:{"content":item.comments && item.comments.length
                              ? item.comments.length
                              : '0',"color":item.comments && item.comments.length
                              ? 'success'
                              : 'red',"overlap":""}},[_c('v-icon',[_vm._v(" mdi-comment-outline ")])],1)],1):_vm._e()]}}],null,true)},[_c('span',[_vm._v("Comentarios del Servicio")])])]}}],null,false,581470752)}),(!_vm.currentRequestMaterials.is_service)?_c('span',{staticClass:"float-left mt-3 mr-2 text-body-1 text-danger"},[_vm._v(" Los precios con * son de referencia ")]):_vm._e(),_c('span',{staticClass:"float-right mt-3 mr-14 text-body-1"},[_c('p',{staticClass:"ma-0"},[_vm._v(" Total: ")]),_c('p',{staticClass:"ma-0"},[_c('span',{staticClass:"font-weight-bold"},[_vm._v(" $ "+_vm._s(_vm.getTotalCurrentRequestMaterials)+" ")])])]),(!_vm.ifCurrentRequestOfService)?_c('v-col',{staticClass:"mt-5 pt-5",attrs:{"cols":"12","md":"12","sm":"12"}},[_c('label',{staticClass:"mt-4",attrs:{"for":"comments"}},[_vm._v(" Comentarios de la Solicitud ")]),_c('v-textarea',{attrs:{"disabled":!_vm.permissionToApprove,"rows":"4","counter":"","outlined":"","value":_vm.currentRequestMaterials
                      ? _vm.currentRequestMaterials.description
                      : null},on:{"input":function($event){return _vm.updateValueCurrentRequest($event, 'description')}}})],1):_vm._e()],1),(
                _vm.currentRequestMaterials && !_vm.currentRequestMaterials.rejected
              )?_c('v-tab-item',{key:"approval"},[_c('approvers',{attrs:{"currentRequestMaterials":_vm.currentRequestMaterials}})],1):_vm._e(),(
                _vm.currentRequestMaterials &&
                  !_vm.currentRequestMaterials.rejected &&
                  _vm.currentRequestMaterials.restriction_approvers.length
              )?_c('v-tab-item',{key:"approval-restrictions"},[_c('approvers',{attrs:{"currentRequestMaterials":_vm.currentRequestMaterials,"showRestrictionsApprovers":true}})],1):_vm._e(),(
                _vm.currentRequestMaterials && _vm.currentRequestMaterials.rejected
              )?_c('v-tab-item',{key:"reason-reject"},[_c('reason-reject',{attrs:{"reasonReject":_vm.currentRequestMaterials.rejected_comment,"dateRejected":_vm.currentRequestMaterials.rejected_at,"rejectedBy":_vm.currentRequestMaterials.rejected_by}})],1):_vm._e()],1):_vm._e()],1)],1),_c('v-card-actions',[_c('v-spacer'),(_vm.permissionToApprove)?_c('v-btn',{staticClass:"text-white",attrs:{"disabled":_vm.loading,"color":"warning text-white"},on:{"click":_vm.updateCurrentRequestApprove}},[_c('small',[_vm._v(" Actualizar Solicitud ")])]):_vm._e(),_c('v-btn',{attrs:{"disabled":_vm.loading,"color":"red darken-1","outlined":""},on:{"click":_vm.executeCloseModalListMaterialsRequest}},[_c('small',[_vm._v(" Cerrar ")])])],1)],1)],1),_c('table-add-materials',{ref:"tableAddMaterials",attrs:{"currentRequestMaterials":_vm.currentRequestMaterials,"modalAction":_vm.dialogTableAddMaterials,"closeModalTableAddMaterials":_vm.closeModalTableAddMaterials},on:{"addMaterialsSelected":_vm.addMaterialsSelected}}),_c('modal-files-service',{attrs:{"modalAction":_vm.dialogFilesUploadService,"closeModalFilesService":_vm.closeModalFilesUploadService,"currentService":_vm.currentFilesUploadService,"uploadFiles":false,"userCreateRequestService":false}}),_c('modal-comments-service',{attrs:{"modalAction":_vm.dialogCommmentsService,"closeModalComments":_vm.closeModalCommentsService,"currentService":_vm.currentServiceComments,"addComments":false}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }