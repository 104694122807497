var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',[_c('loading-flux',{attrs:{"value":_vm.loading}}),_c('v-dialog',{attrs:{"max-width":"1000px"},on:{"keydown":_vm.executeCloseModalDetailsConsumptionMaterials,"click:outside":_vm.executeCloseModalDetailsConsumptionMaterials},model:{value:(_vm.modalAction),callback:function ($$v) {_vm.modalAction=$$v},expression:"modalAction"}},[_c('v-card',[_c('v-card-title',[_c('span',{staticClass:"text-h6"},[_vm._v(" "+_vm._s(_vm.currentRequestConsumption ? _vm.currentRequestConsumption.description : "Cargando...")+" ")])]),_c('v-card-text',[_c('v-container',[_c('v-data-table',{staticClass:"px-1 elevation-1",attrs:{"headers":_vm.wrappedTableHeaders,"items":_vm.requestMaterials.consumed_materials
                ? _vm.requestMaterials.consumed_materials
                : [],"items-per-page":_vm.filters.items !== 'Todos' ? _vm.filters.items : 100,"page":_vm.filters.page,"hide-default-footer":"","loading":_vm.loadingMaterialsRequest,"loading-text":"Cargando materiales","item-key":"id","no-data-text":"Sin materiales"},on:{"update:page":function($event){return _vm.$set(_vm.filters, "page", $event)},"page-count":function($event){_vm.pageCount = $event}},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{staticClass:"pt-5",attrs:{"flat":""}},[_c('v-text-field',{staticClass:"w-25 mx-4",attrs:{"label":"Busqueda","clearable":"","placeholder":"Buscar material","append-icon":"mdi-magnify"},on:{"input":function($event){return _vm.getMaterialsFromRequestConsumptionData(1)}},model:{value:(_vm.filters.filter),callback:function ($$v) {_vm.$set(_vm.filters, "filter", $$v)},expression:"filters.filter"}}),_c('v-autocomplete',{staticClass:"w-25",attrs:{"items":[5, 10, 15, 20, 25, 'Todos'],"label":"Items por página"},on:{"change":function () { return _vm.getMaterialsFromRequestConsumptionData(1); }},model:{value:(_vm.filters.items),callback:function ($$v) {_vm.$set(_vm.filters, "items", $$v)},expression:"filters.items"}}),_c('v-divider',{staticClass:"mx-5",attrs:{"inset":"","vertical":""}}),_c('v-spacer'),_c('div',{staticClass:"d-flex align-items-center"},[_c('p',{staticClass:"mx-3 text-h6"},[_c('small',{staticClass:"pull-right"},[_vm._v(" Seleccionados: "+_vm._s(_vm.materialsSelected.length)+" ")])])])],1)]},proxy:true},{key:"item.code",fn:function(ref){
                var item = ref.item;
return [_c('span',{staticStyle:{"font-size":"12.5px"}},[_vm._v(_vm._s(item.material_project.material ? item.material_project.material.code : null))])]}},{key:"item.description",fn:function(ref){
                var item = ref.item;
return [_c('span',{staticStyle:{"font-size":"12.5px"}},[_vm._v(_vm._s(item.material_project.material.description))])]}},{key:"item.units",fn:function(ref){
                var item = ref.item;
return [_c('span',{staticStyle:{"font-size":"12.5px"}},[_vm._v(_vm._s(item.material_project.material.measurement.units))])]}},{key:"item.requested",fn:function(ref){
                var item = ref.item;
return [_c('span',{staticStyle:{"font-size":"12.5px"}},[_vm._v(_vm._s(item.consumed_quantity))])]}}])}),_c('div',{staticClass:"text-center pt-2"},[_c('v-pagination',{attrs:{"length":_vm.requestMaterials.number_of_pages
                  ? _vm.requestMaterials.number_of_pages
                  : 1,"total-visible":8},model:{value:(_vm.filters.page),callback:function ($$v) {_vm.$set(_vm.filters, "page", $$v)},expression:"filters.page"}})],1)],1)],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"disabled":_vm.loading,"color":"red darken-1","outlined":"","text":""},on:{"click":_vm.executeCloseModalDetailsConsumptionMaterials}},[_c('small',[_vm._v(" Cerrar ")])])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }