<template>
    <v-card>
        <v-card-title class="d-block">
            <h4>{{ comentario.nombre_usuario }} <small style="font-size: 8pt;" class="ps-1">({{ comentario.nombre_rol }})</small></h4> 
            <h6>
                {{ formatDate(comentario.fecha_creacion) }}  {{ formatTime(comentario.fecha_creacion) }}
            </h6>
        </v-card-title>
        <v-card-text class="white text--primary">
          <p>{{ comentario.comentario }}</p>
        </v-card-text>
      </v-card>

</template>

<script>
import moment from "moment";
moment.locale("es");

export default {
    props: {
        comentario: Object,
    },
    data() {
        return {};
    },
    mounted() {},
    methods: {
        formatDate(value) {
            const duration = moment(value).format("DD/MM/yyyy");
            return `${duration}`;
        },
        formatTime(value) {
            const duration = moment(value).format("H:m");
            return `${duration}`;
        },
    },
    computed: {},
};
</script>

<style scoped>
.fecha-comentario {
    position: absolute;
    right: 10px;
    top: 0;
    color: #ccc;
}

.comentario {
    box-shadow: none !important;
}

@media (max-width: 300px) {
    .avatar-comentario {
        display: none;
    }
    .comentario-comentario {
        /* margin-bottom: 10px; */
    }
    .fecha-comentario {
        position: relative;
        margin-left: 10px;
    }
}
@media (max-width: 500px) {
    .fecha-comentario {
        position: relative;
        margin-left: 10px;
        margin-bottom: 40px;
    }
}
</style>
