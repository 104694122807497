<template>
  <section>
    <loading-flux :value="loading"></loading-flux>
    <v-dialog
      v-model="modalAction"
      max-width="1200px"
      @keydown="executeCloseModalDetailsReturnMaterials"
      @click:outside="executeCloseModalDetailsReturnMaterials"
    >
      <v-card>
        <v-card-title>
          <span class="text-h6">
            {{
              currentRequestReturn
                ? currentRequestReturn.description
                : "Cargando..."
            }}
          </span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-data-table
              class="px-1 elevation-1"
              v-model="materialsSelected"
              :headers="wrappedTableHeaders"
              :items="
                requestMaterials.materials ? requestMaterials.materials : []
              "
              :items-per-page="filters.items !== 'Todos' ? filters.items : 100"
              :page.sync="filters.page"
              hide-default-footer
              :loading="loadingMaterialsRequest"
              loading-text="Cargando materiales"
              :show-select="currentRequestReturnIsTransfer"
              item-key="id"
              selectable-key="selectable"
              no-data-text="Sin materiales"
              @page-count="pageCount = $event"
              @toggle-select-all="updateQuantityRowSelected"
              @item-selected="updateQuantityRowSelected"
            >
              <template v-slot:top>
                <v-toolbar flat class="pt-5">
                  <v-text-field
                    label="Busqueda"
                    v-model="filters.filter"
                    @input="getMaterialsFromRequestReturnData(1)"
                    class="w-25 mx-4"
                    clearable
                    placeholder="Buscar material"
                    append-icon="mdi-magnify"
                  ></v-text-field>
                  <v-autocomplete
                    class="w-25"
                    @change="() => getMaterialsFromRequestReturnData(1)"
                    v-model="filters.items"
                    :items="[5, 10, 15, 20, 25, 'Todos']"
                    label="Items por página"
                  >
                  </v-autocomplete>
                  <v-divider class="mx-5" inset vertical></v-divider>
                  <v-spacer></v-spacer>
                  <div
                    class="d-flex align-items-center"
                    v-if="currentRequestReturnIsTransfer"
                  >
                    <p class="mx-3 text-h6">
                      <small class="pull-right">
                        Seleccionados: {{ materialsSelected.length }}
                      </small>
                    </p>
                  </div>
                </v-toolbar>
              </template>
              <template v-slot:item.erp_status="{ item }">
                <span
                  :class="`${getTextColorFromErpStatus(item.erp_status)}`"
                  style="font-size: 12px"
                  >{{ item.erp_status ? item.erp_status : null }}</span
                >
              </template>
              <template v-slot:item.code="{ item }">
                <span style="font-size: 12px">{{
                  item.material_project.material
                    ? item.material_project.material.code
                    : null
                }}</span>
              </template>
              <template v-slot:item.description="{ item }">
                <p class="text-left my-0" style="font-size: 12px">
                  {{ item.material_project.material.description }}
                </p>
              </template>
              <template v-slot:item.units="{ item }">
                <span style="font-size: 12px">{{
                  item.material_project.material.measurement.units
                }}</span>
              </template>
              <template v-slot:item.requested="{ item }">
                <p class="my-0 text-right" style="font-size: 12px">{{
                  item.returned_quantity
                }}</p class="my-0 text-right">
              </template>
              <template v-slot:item.quantity="{ item }">
                <v-text-field
                  v-if="materialIsSelected(item)"
                  type="number"
                  :min="1"
                  :max="item.returned_quantity"
                  class="pt-0 mt-0"
                  @input="setMaterialRequestValue($event, item)"
                  :error="
                    materialIsSelected(item)
                      ? materialIsSelected(item).quantity >
                        item.returned_quantity
                      : false
                  "
                  :value="
                    materialIsSelected(item) &&
                    materialIsSelected(item).quantity !== undefined
                      ? materialIsSelected(item).quantity
                      : item.returned_quantity
                  "
                  hide-details
                ></v-text-field>
              </template>
            </v-data-table>
            <div class="text-center pt-2">
              <v-pagination
                v-model="filters.page"
                :length="
                  requestMaterials.number_of_pages
                    ? requestMaterials.number_of_pages
                    : 1
                "
                :total-visible="8"
              ></v-pagination>
            </div>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            v-if="currentRequestReturnIsTransfer"
            :disabled="loading || !materialsSelected.length"
            color="primary darken-1"
            @click="
              validateMaterialsSelectedAndOpenConfirm(
                actionMaterialsSelected.approve
              )
            "
          >
            <small>
              Aceptar materiales seleccionados
            </small> </v-btn
          ><v-btn
            v-if="currentRequestReturnIsTransfer"
            class="text-white"
            :disabled="loading || !materialsSelected.length"
            color="red darken-1"
            @click="
              validateMaterialsSelectedAndOpenConfirm(
                actionMaterialsSelected.rejected
              )
            "
          >
            <small>
              Rechazar materiales seleccionados
            </small>
          </v-btn>
          <v-btn
            :disabled="loading"
            color="red darken-1"
            outlined
            text
            @click="executeCloseModalDetailsReturnMaterials"
          >
            <small>
              Cerrar
            </small>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </section>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { authUsuarioComputed } from "@/state/helpers";
import { preventCloseIfNotKeyEventEsc } from "@/helpers/common";
import { MATERIAL_REQUEST_RETURN_STATUS_LIST } from "@/constants/requestMaterial";
export default {
  props: {
    modalAction: {
      type: Boolean,
      default: false,
    },
    closeModalDetailsRequestReturnMaterials: {
      type: Function,
      default: () => {},
    },
    currentRequestReturn: {
      default: null,
    },
  },
  data() {
    return {
      MATERIAL_REQUEST_RETURN_STATUS_LIST,
      loading: false,
      debounce: null,
      debounceTime: 500,
      filters: {
        page: 1,
        items: 10,
        filters: "",
      },
      actionMaterialsSelected: {
        approve: true,
        rejected: false,
      },
      materialsSelected: [],
      tableHeaders: [
        {
          text: "Código",
          align: "start",
          width: "10%",
          sortable: false,
          value: "code",
        },
        {
          text: "Descripción",
          align: "start",
          width: "70%",
          sortable: false,
          value: "description",
        },
        {
          text: "Unidad",
          align: "start",
          width: "5%",
          sortable: false,
          value: "units",
        },
        {
          text: "Devuelto",
          align: "end",
          width: "10%",
          sortable: false,
          value: "requested",
        },
      ],
      codeProject: this.$route.query.sap_project
        ? this.$route.query.sap_project
        : null,
    };
  },
  methods: {
    ...mapActions({
      getMaterialsFromRequestReturn:
        "returnMaterial/getMaterialsFromRequestReturn",
      cleanRequestMaterials: "returnMaterial/cleanRequestMaterials",
      approveOrRejectReturnRequestMaterials:
        "returnMaterial/approveOrRejectReturnRequestMaterials",
    }),
    getTextColorFromErpStatus(erp_status) {
      switch (erp_status) {
        case MATERIAL_REQUEST_RETURN_STATUS_LIST.pending:
          return "text-secondary";
        case MATERIAL_REQUEST_RETURN_STATUS_LIST.approved:
          return "text-success";
        case MATERIAL_REQUEST_RETURN_STATUS_LIST.rejected:
          return "text-danger";
        default:
          break;
      }
    },
    executeCloseModalDetailsReturnMaterials($event) {
      let validationEvent = preventCloseIfNotKeyEventEsc($event);
      if (validationEvent) {
        this.materialsSelected = [];
        this.filters.filter = "";
        this.cleanRequestMaterials();
        this.closeModalDetailsRequestReturnMaterials($event);
      }
    },
    async getMaterialsFromRequestReturnData(page = 1) {
      clearTimeout(this.debounce);
      let self = this;
      this.debounce = setTimeout(async function() {
        self.filters.page = page;
        await self.getMaterialsFromRequestReturn({
          page: self.filters.page,
          filters: self.filters.filter ? self.filters.filter : undefined,
          items:
            self.filters.items !== "Todos" ? self.filters.items : undefined,
          return_id: self.currentRequestReturn.id,
          show_all: self.filters.items === "Todos" ? true : undefined,
        });
      }, self.debounceTime);
    },
    materialIsSelected(material) {
      return this.materialsSelected.find((item) => item.id === material.id);
    },
    updateQuantityRowSelected({ item, items, value }) {
      if (value) {
        let materials = items ? items : [item];
        materials.forEach((material) => {
          if (material.selectable) {
            this.materialsSelected.push(material);
            this.setMaterialRequestValue(material.returned_quantity, material);
          }
        });
      }
    },
    setMaterialRequestValue(value = null, material) {
      this.materialsSelected.forEach((item) => {
        if (item.id === material.id) {
          item.quantity = value;
        }
      });
      this.materialsSelected = [...this.materialsSelected];
    },
    validateMaterialsSelectedAndOpenConfirm(action) {
      let validMaterials = true;
      this.materialsSelected.forEach((material) => {
        if (
          isNaN(material.quantity) ||
          Number(material.quantity) > material.returned_quantity
        ) {
          validMaterials = false;
        }
      });
      if (!validMaterials) {
        return this.$swal({
          icon: "error",
          title: "Error",
          text:
            "Las cantidades deben ser menores a las cantidades disponibles de material",
          position: "top-end",
          showConfirmButton: false,
          toast: true,
          timer: 5000,
          timerProgressBar: true,
        });
      }
      this.acceptOrRejectMaterials(action);
    },
    async acceptOrRejectMaterials(action) {
      let self = this;
      let actionText =
        action === this.actionMaterialsSelected.approve
          ? "Aprobar"
          : "Rechazar";
      this.$swal
        .fire({
          icon: "warning",
          title: `¿Estás seguro de ${actionText} los materiales seleccionados?`,
          showCancelButton: true,
          showConfirmButton: true,
          confirmButtonText: actionText,
          confirmButtonColor:
            action === self.actionMaterialsSelected.approve
              ? "#007bff"
              : "#dc3545",
          cancelButtonText: `Cancelar`,
        })
        .then(async (result) => {
          if (result.isConfirmed) {
            this.loading = true;
            const resp = await this.approveOrRejectReturnRequestMaterials({
              user: self.user.email,
              action,
              material_list: self.materialsSelected.map((material) => ({
                return_material_id: material.id,
                quantity: Number(material.quantity),
              })),
            });
            if (resp.status === 500) {
              self.$swal.fire({
                icon: "error",
                title: "Error",
                text:
                  "Ha ocurrido un error en el servidor, por favor inténtalo nuevamente",
                position: "top-end",
                showConfirmButton: false,
                toast: true,
                timer: 5000,
                timerProgressBar: true,
              });
            }
            if (resp.status === 200) {
              self.$swal({
                icon: "success",
                title: "Excelente",
                text: `Materiales ${
                  action === self.actionMaterialsSelected.approve
                    ? "aprobados"
                    : "rechazados"
                } correctamente`,
                position: "top-end",
                showConfirmButton: false,
                toast: true,
                timer: 3000,
                timerProgressBar: true,
              });
              self.materialsSelected = [];
              self.getMaterialsFromRequestReturnData(1);
            }
            self.loading = false;
          }
        });
    },
  },
  computed: {
    ...authUsuarioComputed,
    ...mapGetters({
      loadingMaterialsRequest: "returnMaterial/loadingMaterialsRequest",
      requestMaterials: "returnMaterial/requestMaterials",
    }),
    currentRequestReturnIsTransfer() {
      return (
        this.currentRequestReturn &&
        this.currentRequestReturn.destination_code === this.codeProject
      );
    },
    wrappedTableHeaders() {
      if (
        this.currentRequestReturnIsTransfer ||
        (this.currentRequestReturn &&
          this.currentRequestReturn.destination_code !== null &&
          !this.$route.query.sap_project)
      ) {
        return [
          // {
          //   text: "Estatus",
          //   align: "start",
          //   width: "25%",
          //   sortable: false,
          //   value: "erp_status",
          // },
          ...this.tableHeaders,
          // {
          //   text: "Cantidad",
          //   align: "center",
          //   width: "15%",
          //   sortable: false,
          //   value: "quantity",
          // },
        ];
      }
      return this.tableHeaders;
    },
  },
  watch: {
    "filters.page": function(page) {
      this.getMaterialsFromRequestReturnData(page);
    },
  },
};
</script>

<style></style>
