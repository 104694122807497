var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',[_c('v-data-table',{staticClass:"px-1 elevation-2 my-4",attrs:{"headers":_vm.tableHeaders,"items":_vm.materials,"items-per-page":100,"hide-default-footer":"","item-key":"material_id","no-data-text":"Sin materiales"},scopedSlots:_vm._u([{key:"item.code",fn:function(ref){
var item = ref.item;
return [_c('span',{staticStyle:{"font-size":"12.5px"}},[_vm._v(_vm._s(item.code))])]}},{key:"item.description",fn:function(ref){
var item = ref.item;
return [_c('span',{staticStyle:{"font-size":"12.5px"}},[_vm._v(_vm._s(item.description))])]}},{key:"item.quantity",fn:function(ref){
var item = ref.item;
return [_c('span',{staticStyle:{"font-size":"12.5px"}},[_vm._v(_vm._s(item.quantity))])]}},{key:"item.price",fn:function(ref){
var item = ref.item;
return [_c('span',{staticStyle:{"font-size":"12.5px"}},[_vm._v(_vm._s(((Number(item.price).toFixed(2)) + " $")))])]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }