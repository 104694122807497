<template>
  <main class="px-3">
    <v-row align-content="center" align-md="center" class="mt-3">
      <v-col cols="12" md="3" sm="12" class="py-1">
        <v-text-field
          label="Busqueda"
          v-model="filters.filter"
          @input="getRequestsConsumptionMaterialsData(1)"
          clearable
          append-icon="mdi-magnify"
        ></v-text-field>
      </v-col>
      <v-col cols="12" md="3" sm="12" class="mb-2">
        <v-autocomplete
          v-model="filters.items"
          class="mt-3 ml-5"
          :items="[5, 10, 15, 20, 25, 'Todos']"
          @change="($event) => getRequestsConsumptionMaterialsData(1)"
          label="Items por página"
        >
        </v-autocomplete>
      </v-col>
    </v-row>
    <v-data-table
      :headers="tableHeaders"
      :items="
        requestsConsumptionMaterials.consume_requests
          ? requestsConsumptionMaterials.consume_requests
          : []
      "
      :page.sync="filters.page"
      :items-per-page="filters.items !== 'Todos' ? filters.items : 100"
      item-key="id"
      :show-select="false"
      :loading="loading"
      hide-default-footer
      no-data-text="Sin solicitudes actualmente"
    >
      <template v-slot:top>
        <v-toolbar flat>
          <v-divider class="mx-4" inset vertical></v-divider>
          <v-spacer></v-spacer>
          <v-btn
            color="blue darken-3"
            @click="openModalAddRequestConsumptionMaterials"
            :dark="false"
            small
          >
            <span class="text-white">
              Crear solicitud de consumo
            </span>
          </v-btn>
        </v-toolbar>
      </template>
      <template v-slot:item.actions="{ item }">
        <div class="d-flex align-items-center justify-content-center">
          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                icon
                color="primary"
                v-bind="attrs"
                v-on="on"
                @click="openModalDetailsRequestConsumptionMaterials(item)"
              >
                <v-icon small>
                  mdi-eye
                </v-icon>
              </v-btn>
            </template>
            <span>Ver detalles</span>
          </v-tooltip>
        </div>
      </template>
      <template v-slot:item.created_at="{ item }">
        <p class="mt-2">
          {{ new Date(item.created_at).toLocaleDateString() }}
        </p>
      </template>
      <template v-slot:item.status="{ item }">
        <p class="mt-2">
          {{ "Aceptada" }}
        </p>
      </template>
    </v-data-table>
    <div class="text-center pt-2">
      <v-pagination
        total-visible="8"
        v-model="filters.page"
        :length="
          requestsConsumptionMaterials.number_of_pages
            ? requestsConsumptionMaterials.number_of_pages
            : 1
        "
      ></v-pagination>
    </div>
    <create-request-consumption-material
      @requestConsumptionMaterialCreated="
        getRequestsConsumptionMaterialsData(1)
      "
      :modalAction="dialogRequestConsumptionMaterials"
      :closeModalRequestConsumptionMaterials="
        closeModalRequestConsumptionMaterials
      "
    ></create-request-consumption-material>
    <details-request-consumption-materials
      ref="detailsConsumptionMaterials"
      :currentRequestConsumption="currentRequestConsumption"
      :modalAction="dialogDetailsRequestConsumptionMaterials"
      :closeModalDetailsRequestConsumptionMaterials="
        closeModalDetailsRequestConsumptionMaterials
      "
    ></details-request-consumption-materials>
  </main>
</template>

<script>
import { authMethods, authUsuarioComputed } from "@/state/helpers";
import { permiso } from "@/helpers/authservice/obtener-permisos";
import { mapActions, mapGetters } from "vuex";
import { preventCloseIfNotKeyEventEsc } from "@/helpers/common";
import CreateRequestConsumptionMaterial from "./CreateRequestConsumptionMaterial.vue";
import DetailsRequestConsumptionMaterials from "./DetailsRequestConsumptionMaterials.vue";
export default {
  components: {
    CreateRequestConsumptionMaterial,
    DetailsRequestConsumptionMaterials,
  },
  data() {
    return {
      dialogRequestConsumptionMaterials: false,
      dialogDetailsRequestConsumptionMaterials: false,
      currentRequestConsumption: null,
      debounce: null,
      debounceTime: 500,
      filters: {
        page: 1,
        items: 10,
        filter: "",
      },
      tableHeaders: [
        {
          text: "Descripción",
          align: "center",
          sortable: false,
          value: "description",
        },
        {
          text: "Fecha de la solicitud",
          align: "center",
          sortable: false,
          value: "created_at",
        },
        {
          text: "Estatus",
          align: "center",
          sortable: false,
          value: "status",
        },
        {
          text: "Acciones",
          align: "center",
          value: "actions",
          sortable: false,
        },
      ],
      codeProject: this.$route.params.codigoProyecto,
      codePlace: this.$route.params.codigoLugarInstalacion,
      permissions: {},
    };
  },
  async mounted() {
    let permisos = permiso.obtenerPermisos(
      this.user,
      this.$router.currentRoute.name
    );
    this.permissions = permisos;
    this.getRequestsConsumptionMaterialsData(1);
  },
  methods: {
    ...authMethods,
    ...mapActions({
      getRequestsConsumptionMaterialsFromProject:
        "consumptionMaterial/getRequestsConsumptionMaterialsFromProject",
    }),
    async getRequestsConsumptionMaterialsData(page = 1) {
      clearTimeout(this.debounce);
      let self = this;
      this.debounce = setTimeout(async function() {
        self.filters.page = page;
        self.getRequestsConsumptionMaterialsFromProject({
          page: self.filters.page,
          project_code: self.codeProject,
          filters: self.filters.filter,
          items:
            self.filters.items !== "Todos" ? self.filters.items : undefined,
          show_all: self.filters.items === "Todos" ? true : undefined,
        });
      }, self.debounceTime);
    },
    openModalAddRequestConsumptionMaterials() {
      this.dialogRequestConsumptionMaterials = true;
    },
    closeModalRequestConsumptionMaterials($event) {
      let validationEvent = preventCloseIfNotKeyEventEsc($event);
      if (validationEvent) {
        this.dialogRequestConsumptionMaterials = false;
      }
    },
    openModalDetailsRequestConsumptionMaterials(consumptionRequest) {
      this.currentRequestConsumption = consumptionRequest;
      this.$refs.detailsConsumptionMaterials.getMaterialsFromRequestConsumptionData(
        1
      );
      this.dialogDetailsRequestConsumptionMaterials = true;
    },
    closeModalDetailsRequestConsumptionMaterials() {
      this.currentRequestConsumption = null;
      this.dialogDetailsRequestConsumptionMaterials = false;
    },
  },
  computed: {
    ...authUsuarioComputed,
    ...mapGetters({
      loading: "consumptionMaterial/loadingRequests",
      requestsConsumptionMaterials:
        "consumptionMaterial/requestsConsumptionMaterials",
    }),
  },
  watch: {
    "filters.page": function(page) {
      this.getRequestsReturnMaterialsData(page);
    },
  },
};
</script>

<style></style>
