<template>
  <section class="mt-5 pt-3" v-if="currentRequestMaterials">
    <v-row align-content="center" justify="center">
      <v-col cols="12" md="6" sm="12">
        <p class="text-center font-weight-bold">
          {{
            showRestrictionsApprovers
              ? "Aprobador de Renstriccíón Actual"
              : "Aprobador Actual"
          }}
        </p>
        <section
          v-if="
            getWrappedApprovers.length && !currentRequestMaterials.sent_to_erp
          "
        >
          <div class="mb-4 mt-5 pt-1" v-for="item in getWrappedApprovers">
            <div class="d-flex justify-content-center align-items-center">
              <div class="text-center d-flex flex-column">
                {{ item.approver.full_name }}
                ({{ item.approver.email }})
                <span
                  class="text-danger mt-2"
                  v-if="item.approver.substituting_for.length"
                >
                  <b>Suplente:</b>
                  {{ getSubstituteFromApprover(item.approver).full_name }} ({{
                    getSubstituteFromApprover(item.approver).email
                  }})
                </span>
                <span class="mt-2 text-primary" v-if="item.approved">
                  Aprobado el
                  {{
                    moment(item.approved_at).format("DD/MM/YYYY HH:mm [hrs]")
                  }}
                </span>
              </div>
              <v-chip
                :color="item.approved ? 'blue darken-2' : 'yellow darken-4'"
                class="text-white mx-5"
                >{{ item.approved ? "Aprobado" : "Pendiente" }}</v-chip
              >
            </div>
          </div>
        </section>
        <h5 class="text-center mt-5" v-else>
          {{
            currentRequestMaterials.sent_to_erp
              ? "Solicitud aprobada"
              : "Sin aprobadores actuales"
          }}
        </h5>
      </v-col>
      <v-col cols="12" md="6" sm="12">
        <section v-if="currentRequestMaterials.request_approvers.length">
          <p class="text-center font-weight-bold">
            Todos los Aprobadores
          </p>
          <div
            class="mb-4 mt-5 pt-1"
            v-for="item in currentRequestMaterials.request_approvers"
          >
            <div class="d-flex justify-content-center align-items-center">
              <div class="text-center d-flex flex-column">
                {{ item.approver.full_name }}
                ({{ item.approver.email }})
                <span
                  class="text-danger mt-2"
                  v-if="item.approver.substituting_for.length"
                >
                  <b>Suplente:</b>
                  {{ getSubstituteFromApprover(item.approver).full_name }} ({{
                    getSubstituteFromApprover(item.approver).email
                  }})
                </span>
                <span class="mt-2 text-primary" v-if="item.approved">
                  Aprobado el
                  {{
                    moment(item.approved_at).format("DD/MM/YYYY HH:mm [hrs]")
                  }}
                </span>
              </div>
              <v-chip
                :color="item.approved ? 'blue darken-2' : 'yellow darken-4'"
                class="text-white mx-5"
                >{{ item.approved ? "Aprobado" : "Pendiente" }}</v-chip
              >
            </div>
          </div>
        </section>
      </v-col>
    </v-row>
  </section>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { authUsuarioComputed } from "@/state/helpers";
import moment from "moment";

export default {
  props: {
    currentRequestMaterials: {
      default: null,
    },
    showRestrictionsApprovers: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      moment,
    };
  },
  methods: {
    getSubstituteFromApprover(approver) {
      return approver.substituting_for[0].substitute;
    },
  },
  computed: {
    ...authUsuarioComputed,
    ...mapGetters({
      usersActiveContract: "user/users",
    }),
    getWrappedApprovers() {
      return this.showRestrictionsApprovers
        ? this.currentRequestMaterials.restriction_approvers
        : this.currentRequestMaterials.request_approvers.filter((approver) => {
            return !this.currentRequestMaterials.approved_index
              ? approver.order == 1
              : approver.order + 1 ==
                  this.currentRequestMaterials.approved_index ||
                  Math.abs(
                    approver.order - this.currentRequestMaterials.approved_index
                  ) == 1;
          });
    },
  },
};
</script>

<style></style>
