<template>
  <v-data-table
    class="px-1 elevation-1"
    :headers="tableHeaders"
    :items="materials"
    :items-per-page="100"
    hide-default-footer
    item-key="material_id"
    no-data-text="Sin materiales"
  >
    <template v-slot:item.code="{ item }">
      <span style="font-size: 12.5px">{{ item.code }}</span>
    </template>
    <template v-slot:item.description="{ item }">
      <span class="text-left" style="font-size: 12.5px">{{
        item.description
      }}</span>
    </template>
    <template v-slot:item.units="{ item }">
      <span style="font-size: 12.5px">{{ item.units }}</span>
    </template>
  </v-data-table>
</template>

<script>
export default {
  props: {
    materials: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      tableHeaders: [
        {
          text: "Código",
          align: "start",
          width: "5%",
          sortable: false,
          value: "code",
        },
        {
          text: "Descripción",
          align: "start",
          width: "70%",
          sortable: false,
          value: "description",
        },
        {
          text: "Unidad",
          align: "start",
          width: "5%",
          sortable: false,
          value: "units",
        },
        {
          text: "Cantidad",
          align: "start",
          width: "5%",
          sortable: false,
          value: "quantity",
        },
      ],
    };
  },
};
</script>

<style></style>
