<template>
  <section>
    <v-dialog
      v-model="modalAction"
      max-width="1300px"
      @keydown="executeCloseTableAddMaterials"
      @click:outside="executeCloseTableAddMaterials"
    >
      <v-card>
        <div class="p-4 pb-0">
          <h5>Buscar Materiales</h5>
        </div>
        <hr />
        <v-card-text>
          <div class="card-body p-2 pb-0">
            <div class="row align-items-center">
              <div class="col-sm-12 col-md-6 pt-0">
                <v-text-field
                  v-model="filters.filter"
                  @input="getMaterials(1)"
                  append-icon="mdi-magnify"
                  label="Buscar por Código Producto o Descripción"
                  single-line
                  hide-details
                ></v-text-field>
              </div>
              <div class="col-sm-12 col-md-6 pt-0">
                <div
                  class="d-flex align-items-center justify-content-end v-application"
                >
                  <v-checkbox
                    v-model="filters.standard_materials"
                    @click="getMaterials(1)"
                    class="mx-5 mt-4"
                  >
                    <template v-slot:label>
                      <h5 class="mt-3 mx-2 mb-0">Materiales Estandar</h5>
                    </template>
                  </v-checkbox>
                  <h5 class="mb-0 mx-3">
                    Seleccionados: {{ selected.length }}
                  </h5>
                </div>
              </div>
            </div>
            <v-data-table
              v-model="selected"
              :headers="headers"
              :items="wrappedPaginatedMaterials"
              :single-select="false"
              item-key="code"
              show-select
              class="elevation-1 table-list-materiales mt-3"
              @input="selectProduct"
              :items-per-page="10"
              :page.sync="filters.page"
              hide-default-footer
              :loading="loadingAllMaterials"
              loading-text="Cargando Materiales... Espere Por favor"
              @page-count="pageCount = $event"
            >
              <template v-slot:item.favorite="{ item }">
                <section class="v-application">
                  <v-icon color="primary" v-if="isFavorite(item)">
                    mdi-check-circle
                  </v-icon>
                  <span v-else></span>
                </section>
              </template>
              <template v-slot:item.code="{ item }">
                <p class="my-0">
                  {{ item.code }}
                </p>
              </template>
              <template v-slot:item.name="{ item }">
                <p class="my-0 text-left">
                  {{ item.name }}
                </p>
              </template>
              <template v-slot:item.measurement_unit="{ item }">
                <p class="text-left ml-3 my-0">
                  {{ item.measurement_unit.name }}
                </p>
              </template>
              <template v-slot:item.price="{ item }">
                <p class="text-right my-0">
                  {{
                    `$ ${new Intl.NumberFormat("de-DE").format(
                      parseInt(item.price)
                    )}`
                  }}
                </p>
              </template>
            </v-data-table>
            <div class="text-center pt-2">
              <v-pagination
                v-model="filters.page"
                class="v-application"
                :length="
                  paginatedMaterials.total_pages
                    ? paginatedMaterials.total_pages
                    : 1
                "
                :total-visible="8"
              ></v-pagination>
            </div>
            <div class="v-application">
              <v-spacer></v-spacer>
              <v-btn
                color="red"
                outlined
                class="mx-3 mt-4"
                small
                @click="executeCloseTableAddMaterials"
                >Cancelar</v-btn
              >
              <v-btn
                :disabled="!checkedLength"
                type="submit"
                color="primary"
                class="ms-1 mt-4"
                small
                @click="addMaterialsSelected"
                >Agregar Seleccionados</v-btn
              >
            </div>
          </div>
        </v-card-text>
      </v-card>
    </v-dialog>
  </section>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { preventCloseIfNotKeyEventEsc } from "@/helpers/common";
import {
  CODE_WAREHOUSE_CENTRAL_FLUXSOLAR,
  TYPE_DESTINY_PROJECT_ID,
  TYPE_DESTINY_CECO_ID,
} from "@/constants/requestMaterial";

export default {
  props: {
    modalAction: {
      type: Boolean,
      default: false,
    },
    closeModalTableAddMaterials: {
      type: Function,
      default: () => {},
    },
  },
  data() {
    return {
      CODE_WAREHOUSE_CENTRAL_FLUXSOLAR,
      debounce: null,
      debounceTime: 500,
      selected: [],
      filters: {
        page: 1,
        filter: "",
        standard_materials: true,
      },
      typeDestiny: null,
      checkedLength: false,
      headers: [
        {
          text: "STD",
          align: "center",
          width: "5%",
          sortable: false,
          value: "favorite",
        },
        {
          text: "Stock",
          value: "stock",
          width: "10%",
          sortable: true,
        },
        {
          text: "Código",
          value: "code",
          width: "10%",
          sortable: true,
        },
        {
          text: "Descripción",
          width: "50%",
          value: "name",
          sortable: true,
        },
        {
          text: "Unidad",
          value: "measurement_unit",
          sortable: true,
        },
        {
          text: "Monto unitario",
          width: "10%",
          align: "right",
          sortable: false,
          value: "price",
        },
      ],
      materialsSelected: [],
      maxProducts: 200,
    };
  },
  methods: {
    ...mapActions({
      getAllMaterials: "requestMaterialsSap/getAllMaterials",
      getAllMaterialsCostCenter:
        "requestMaterialsSap/getAllMaterialsCostCenter",
      clearMaterials: "requestMaterialsSap/clearMaterials",
    }),
    updateTypeDestiny(typeDestiny) {
      this.typeDestiny = typeDestiny;
    },
    async getMaterials(page = 1) {
      clearTimeout(this.debounce);
      let self = this;
      this.debounce = setTimeout(async function () {
        self.filters.page = page;
        if (self.typeDestiny) {
          if (self.typeDestiny == TYPE_DESTINY_CECO_ID) {
            await self.getAllMaterialsCostCenter({
              ...self.filters,
              filter: self.filters.filter ? self.filters.filter : undefined,
            });
          } else {
            await self.getAllMaterials({
              ...self.filters,
              business_unit: self.infoProject
                ? self.infoProject.business_unit.sap_code
                : undefined,
            });
          }
        } else {
          self.clearMaterials();
        }
      }, self.debounceTime);
    },
    addMaterialsSelected() {
      if (this.materialsSelected.length > this.maxProducts) {
        this.$swal.fire({
          icon: "error",
          title: "Error",
          text: `Solo puede seleccionar hasta ${this.maxProducts} productos`,
          position: "top-end",
          showConfirmButton: false,
          toast: true,
          timer: 3000,
          timerProgressBar: true,
        });
        return;
      }
      this.$emit("addMaterialsSelected", this.materialsSelected);
      this.materialsSelected = [];
      this.selected = [];
      this.closeModalTableAddMaterials();
    },
    selectProduct() {
      if (this.selected.length > this.maxProducts) {
        this.$swal.fire({
          icon: "error",
          title: "Error",
          text: `Solo puede seleccionar hasta ${this.maxProducts} productos`,
          position: "top-end",
          showConfirmButton: false,
          toast: true,
          timer: 3000,
          timerProgressBar: true,
        });
      }
      this.selected.forEach((element) => {
        element.quantity = null;
        element.unit = element.measurement_unit.name;
        element.description = element.name;
      });
      this.materialsSelected = this.selected;
      if (this.selected.length > 0) {
        this.checkedLength = true;
      } else {
        this.checkedLength = false;
      }
    },
    executeCloseTableAddMaterials($event) {
      let validationEvent = preventCloseIfNotKeyEventEsc($event);
      if (validationEvent) {
        this.filters.filter = "";
        this.closeModalTableAddMaterials($event);
      }
    },
    resetFilter() {
      this.filters.filter = "";
      this.filters.favorite = false;
      this.getMaterials(1);
    },
    isFavorite(item) {
      return (
        item.favorite ||
        item.favorite_ci ||
        item.favorite_otros ||
        item.favorite_pg ||
        item.favorite_re
      );
    },
  },
  computed: {
    ...mapGetters({
      paginatedMaterials: "requestMaterialsSap/allMaterials",
      loadingAllMaterials: "requestMaterialsSap/loadingMaterials",
      infoProject: "project/projectInfo",
    }),
    wrappedPaginatedMaterials() {
      return this.paginatedMaterials.items ? this.paginatedMaterials.items : [];
    },
    selectedRows() {
      return this.items.filter((item) => item.selected);
    },
  },
  watch: {
    "filters.page": function (page) {
      this.getMaterials(page);
    },
  },
};
</script>

<style></style>
