<template>
    <v-card>
        <div class="row">
            <div class="col-12">
                <div class="card">
                    <div class="card-body">
                        <div class="d-flex" v-if="showTitle">
                            <div class="card-title titulo-ubicacion titulo-tarjetas">
                                Lugar de Ubicación
                            </div>
                        </div>
                        
                        <!-- Map with markers -->
                        <gmap-map
                            :center="center"
                            :zoom="zoom"
                            style="height: 400px"
                        >
                            <gmap-marker
                                v-for="(m, index) in markers"
                                :key="index"
                                :position="m.position"
                                :clickable="true"
                                :draggable="true"
                                @click="center = m.position"
                            ></gmap-marker>
                        </gmap-map>
                    </div>
                </div>
            </div>
        </div>
    </v-card>
</template>

<script>
import {
    lugarInstalacionMethods,
    lugarInstalacionComputed,
} from "@/state/helpers";
import {proyectoMethods,proyectoComputed } from "@/state/helpers";
import { marker } from "leaflet";
export default {
    name:'LugarUbicacion',
    components: {},
    props: {
        writeable: {
            type: Boolean,
            default: false,
        },
        showTitle: {
            type: Boolean,
            default: true,
        }
    },
    data() {
        return {
            lugarInstalacion: {},
            lugarInstalacionSeleccionada: {
                codigo_lugar_instalacion: this.$route.params
                    .codigoLugarInstalacion,
                codigo_proyecto: this.$route.params.codigoProyecto,
            },
            markers: [],
            zoom: 5,
            center: { lat: -35.675147, lng: -71.542969 },
            proyectoSeleccionado:{}
        };
    },
    mounted() {
        if(this.proyectoUsuario == null){
             this.obtenerProyectoUsuario()
        }else{
            this.proyectoSeleccionado = this.proyectoUsuario
        }
        this.obtenerLugarInstalacionProyecto();
    },
    methods: {
        ...lugarInstalacionMethods,
        ...proyectoMethods,
        obtenerProyectoUsuario() {
            this.obtenerProyecto(this.$route.params.codigoProyecto)
                .then((res) => {
                    this.proyectoSeleccionado = res.body;
                })
                .catch((error) => {
                });
        },
        obtenerLugarInstalacionProyecto() {
            this.obtenerLugarInstalacion({
                codigoProyecto: this.$route.params.codigoProyecto,
                codigoLugarInstalacion: this.$route.params
                    .codigoLugarInstalacion,
            })
                .then((res) => {
                    this.lugarInstalacion = res.body;

                    this.markers.push({
                        position: {
                            lat: this.lugarInstalacion.latitud,
                            lng: this.lugarInstalacion.longitud,
                        },
                    });
                    if (
                        this.lugarInstalacion.latitud != 0 &&
                        this.lugarInstalacion.longitud != 0
                    ) {
                        this.center.lat = this.lugarInstalacion.latitud;
                        this.center.lng = this.lugarInstalacion.longitud;
                        this.zoom = 17;
                    }
                })
                .catch((error) => {});
        },
    },
    computed: {...proyectoComputed },
};
</script>

<style>
@media (max-width: 800px) {
    .titulo-ubicacion  {
        margin-top: 50px;
    }     
}
</style>
