<template>
    <v-card>
        <b-overlay :show="loading" variant="white" rounded="sm">
            <div class="card">
                <div class="card-body">
                    <b-alert
                        :show="segundosMensajeActualizacion"
                        dismissible
                        :variant="mensaje.variant"
                        @dismissed="segundosMensajeActualizacion = 0"
                        @dismiss-count-down="actualizarSegundosMensajeActualizacion"
                    >
                        {{ mensaje.texto }}
                    </b-alert>
                    <div class="d-flex" v-if="showTitle">
                        <div class="card-title titulo-tarjetas">Contactos</div>
                    </div>
                    
                    <div
                        class="d-flex flex-row-reverse"
                        v-if="rolModuloPermiso.escritura"
                    >
                        <button
                            class="btn btn-success"
                            @click="mostrarModalUsuarios = true"
                        >
                            <i class="mdi mdi-plus me-1"></i>
                            Agregar Contacto
                        </button>
                    </div>
                    <div class="row">
                        <div
                            v-for="(contacto, i) in contactos"
                            :key="i"
                            class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-2 mt-5"
                        >
                            <Contacto
                                :usuario="contacto"
                                :lugarInstalacion="lugarInstalacion"
                                @contactoEliminado="contactoEliminado"
                            />
                        </div>
                    </div>
                </div>

                
                <b-modal
                    v-model="mostrarModalUsuarios"
                    title="Usuarios"
                    hide-footer
                    hide-header
                    size="xl"
                >
                    <form @submit.prevent="submit">
                        <Usuarios
                            :lugarInstalacion="lugarInstalacion"
                            @contactoCreado="contactoCreado"
                        />
                        <div class="text-end mt-3">
                            <b-button
                                variant="light"
                                @click="mostrarModalUsuarios = false"
                                >Cerrar</b-button
                            >
                        </div>
                    </form>
                </b-modal>
                
            </div>

            
        </b-overlay>
    </v-card>
</template>

<script>
import { required } from "vuelidate/lib/validators";
import Multiselect from "vue-multiselect";
import Contacto from "@/components/widgets/contacto/contacto";
import { contactoMethods, contactoComputed } from "@/state/helpers";
import Usuarios from "@/components/widgets/usuario/usuarios";
import { authMethods, authUsuarioComputed } from "@/state/helpers";
import { proyectoMethods, proyectoComputed } from "@/state/helpers";
import { permiso } from "@/helpers/authservice/obtener-permisos";
export default {
    components: { 
        Contacto, 
        Usuarios 
    },
    props: {
        writeable: {
            type: Boolean,
            default: false,
        },
        showTitle: {
            type: Boolean,
            default: true,
        }
    },
    data() {
        return {
            loading: false,
            mostrarModalUsuarios: false,
            mensaje: { variant: "", texto: "" },
            lugarInstalacion: {
                codigo_lugar_instalacion:
                    this.$route.params.codigoLugarInstalacion,
                codigo_proyecto: this.$route.params.codigoProyecto,
            },
            codigoProyecto: null,
            codigoLugarInstalacion: null,
            contactos: [],
            showModal: false,
            submitted: false,
            form: {
                name: "",
                designation: "",
                email: "",
            },
            segundos: 5,
            segundosMensajeActualizacion: 0,
            rolModuloPermiso: {},
            proyectoSeleccionado:{}
        };
    },
    validations: {
        form: {
            name: { required },
            designation: { required },
            email: { required },
        },
    },
    mounted() {
        if(this.proyectoUsuario == null){
             this.obtenerProyectoUsuario()
        }else{
            this.proyectoSeleccionado = this.proyectoUsuario
        }
        this.codigoLugarInstalacion = this.$route.params.codigoLugarInstalacion;
        this.codigoProyecto = this.$route.params.codigoProyecto;
        this.obtenerContactosProyecto();
        let permisos = permiso.obtenerPermisos(
            this.user,
            this.$router.currentRoute.name
        );
        this.rolModuloPermiso = permisos;
    },
    methods: {
        ...contactoMethods,
        ...proyectoMethods,
        obtenerProyectoUsuario() {
            this.obtenerProyecto(this.$route.params.codigoProyecto)
                .then((res) => {
                    this.proyectoSeleccionado = res.body;
                })
                .catch((error) => {
                });
        },
        actualizarSegundosMensajeActualizacion(segundosMensajeActualizacion) {
            this.segundosMensajeActualizacion = segundosMensajeActualizacion;
        },
        obtenerContactosProyecto() {
            this.loading = true;
            this.obtenerContactos({
                codigoLugarInstalacion: this.codigoLugarInstalacion,
                codigoProyecto: this.codigoProyecto,
            })
                .then((res) => {
                    this.contactos = res.body;
                    this.loading = false;
                })
                .catch((error) => {
                    this.loading = false;
                });
        },
        contactoCreado() {
            this.obtenerContactosProyecto();
            this.mostrarModalUsuarios = false;
        },
        contactoEliminado() {
            this.obtenerContactosProyecto();
        },
        abrirModalNuevo() {},
        submit(e) {},
    },
    computed: {
        ...authUsuarioComputed,
        ...proyectoComputed
    },
};
</script>
