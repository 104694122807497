<template>
  <v-container class="">
    <datatable-approve-requests
      ref="datatable-approve-requests"
      title="Solicitudes de Aprobación"
    ></datatable-approve-requests>
  </v-container>
</template>

<script>
import { mapActions } from "vuex";
import DatatableApproveRequests from "@/components/proyectos/lugarInstalacion/lugarSolicitudMaterial/approveRequests/DatatableApproveRequests.vue";
import { authMethods, authUsuarioComputed } from "@/state/helpers";
export default {
  components: { DatatableApproveRequests },
  methods: {
    ...authMethods,
    ...mapActions({
      getAllUsersActiveContract: "user/getAllUsersActiveContract",
      cleanRequestsMaterialsToApprove:
        "requestMaterial/cleanRequestsMaterialsToApprove",
    }),
  },
  mounted() {
    this.getAllUsersActiveContract({
      date: new Date().toISOString().split("T")[0],
    });
  },
  destroyed() {
    this.cleanRequestsMaterialsToApprove();
  },
  computed: {
    ...authUsuarioComputed,
  },
};
</script>

<style></style>
